import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import {
  Typography,
  ListItem,
  FormControl,
  FormLabel,
  FormHelperText
} from "@mui/material";
import { enterSelectedTerm, deleteTerm } from "../../actions";
import { customFilter, customStyles } from "../../utils";

const getOptionsFromList = (optionList, subsection) =>
  optionList.map(o => ({
    value: `${subsection}--${o.name}`,
    label: _.startCase(o.name)
  }));
const getSelectedValue = selected =>
  selected
    ? {
        value: `${selected.subsection}--${selected.name}`,
        label: _.startCase(selected.name)
      }
    : "no-value";

const filterHierarcy = (hierarchyList, selected) => {
  const option = hierarchyList.find(i => i.name === selected.split("--")[1]);
  return option.isFilter
    ? hierarchyList
        .filter(i => i.name !== option.name)
        .filter(i => i.ranking <= option.ranking)
    : hierarchyList.filter(i => i.name !== option.name);
};

const Hierarchy = props => {
  const {
    hierarchyList,
    selectedFirstHierarchy,
    selectedSecondHierarchy,
    hierarchyError,
    onSelect,
    story
  } = props;
  const [hierarchyTwoList, setHierarchyTwoList] = useState([]);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            Result Hierarchy
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" gutterBottom sx={{ mx: 1, mb: 2 }}>
          How would you like to see the results?
        </Typography>
        <Typography variant="body2" sx={{ ml: 1 }}>
          First split by <span className="required">required</span>
        </Typography>
        <ListItem sx={{ px: 1, pt: 0.5, mb: 2 }}>
          <Select
            styles={customStyles}
            className="smallSelect"
            options={getOptionsFromList(hierarchyList, "hier1")}
            value={getSelectedValue(selectedFirstHierarchy)}
            placeholder="Select a hierarchy"
            onChange={e => {
              onSelect(
                e.value,
                getSelectedValue(selectedFirstHierarchy),
                story
              );
              setHierarchyTwoList(
                getOptionsFromList(
                  filterHierarcy(hierarchyList, e.value),
                  "hier2"
                )
              );
            }}
            backspaceRemovesValue={false}
            filterOption={customFilter}
          />
          {!selectedFirstHierarchy && (
            <FormHelperText error>{hierarchyError}</FormHelperText>
          )}
        </ListItem>
        <Typography variant="body2" sx={{ ml: 1 }}>
          Then split by
        </Typography>
        <ListItem sx={{ px: 1, pt: 0.5 }}>
          <Select
            styles={customStyles}
            noOptionsMessage={() =>
              getSelectedValue(selectedFirstHierarchy) === "no-value"
                ? "Please choose first hierarchy before second hierarchy"
                : "No options"
            }
            className="smallSelect"
            options={hierarchyTwoList}
            value={getSelectedValue(selectedSecondHierarchy)}
            placeholder="Select a second hierarchy"
            onChange={(e, i) => {
              if (i.action === "clear") {
                // remove from search terms
                onSelect(
                  "no-value",
                  getSelectedValue(selectedSecondHierarchy),
                  story
                );
              } else {
                onSelect(
                  e.value,
                  getSelectedValue(selectedSecondHierarchy),
                  story
                );
              }
            }}
            isClearable
            filterOption={customFilter}
          />
        </ListItem>
      </FormControl>
    </ListItem>
  );
};

Hierarchy.propTypes = {
  hierarchyList: PropTypes.arrayOf(PropTypes.shape()),
  selectedFirstHierarchy: PropTypes.objectOf(PropTypes.string),
  selectedSecondHierarchy: PropTypes.objectOf(PropTypes.string),
  onSelect: PropTypes.func,
  hierarchyError: PropTypes.string,
  story: PropTypes.string
};

Hierarchy.defaultProps = {
  hierarchyList: [],
  selectedFirstHierarchy: undefined,
  selectedSecondHierarchy: undefined,
  onSelect: () => {},
  hierarchyError: "",
  story: ""
};

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story) => {
    const value = selectedValue.split("--");
    if (oldValue !== "no-value") {
      const old = oldValue.value.split("--");
      dispatch(
        deleteTerm(
          {
            name: old[1],
            subsection: old[0],
            story
          },
          story
        )
      );
    }
    if (selectedValue !== "no-value") {
      dispatch(
        enterSelectedTerm(
          {
            name: value[1],
            subsection: value[0],
            story
          },
          story
        )
      );
    }
  }
});

export default connect(null, mapDispatchToProps)(Hierarchy);

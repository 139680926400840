import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Typography
} from "@mui/material";
import { getDateLabel } from "../../utils";
import Brands from "./Brands";

const Review = props => {
  const { searchTerms } = props;
  const productA = searchTerms.find(s => s.subsection.startsWith("product_a"));
  const productB = searchTerms.find(s => s.subsection.startsWith("product_b"));
  const contextArray = searchTerms.filter(
    s => s.table === "what" && !s.subsection.startsWith("product_")
  );
  const where = searchTerms.find(s => s.table === "where");
  const when = searchTerms.find(s => s.table === "when");
  const measure = searchTerms.find(s => s.table === "measure");
  const dateString = when.name.period
    ? `${when.name.period} ${when.name.date}`
    : getDateLabel(when.name);
  return (
    <Fragment>
      <Brands productA={productA} productB={productB} />
      <ListItem>
        <FormControl
          component="fieldset"
          className="fieldset"
          sx={{ p: 2, mb: 2 }}
          fullWidth
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Context
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {contextArray.map((i, k) => (
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      fontWeight="bold"
                      key={k.toString()}
                    >
                      {i.name}
                    </Typography>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Where
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontWeight="bold"
                  >
                    {where.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" fontWeight="bold">
                    When
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontWeight="bold"
                  >
                    {dateString}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Measure
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontWeight="bold"
                  >
                    {measure.name}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </FormControl>
      </ListItem>
    </Fragment>
  );
};

Review.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape())
};

Review.defaultProps = {
  searchTerms: []
};

export default Review;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Button
} from "@mui/material";

const styles = {
  avatar: {
    height: 30,
    width: 30,
    bgcolor: "white",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)"
  }
};

const ReportType = props => {
  const { type, icon, title, intro, buttons, buttonColor } = props;
  return (
    <Card elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title={<Link to={`/reports-reviews/${type}`}>{title}</Link>}
        avatar={<Avatar sx={styles.avatar}>{icon}</Avatar>}
      />
      <CardContent sx={{ pt: 0 }}>
        <Typography variant="body2" fontSize={12} gutterBottom>
          {intro}
        </Typography>
        <Grid container spacing={2} sx={{ py: 1 }}>
          {buttons.map(i => (
            <Grid item md={12} key={i.text}>
              <Link to={i.link}>
                <Button
                  sx={{ fontSize: 12 }}
                  variant="contained"
                  color={buttonColor}
                  disableElevation
                  fullWidth
                >
                  {i.text}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

ReportType.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.shape(),
  title: PropTypes.string,
  intro: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape()),
  buttonColor: PropTypes.string
};

ReportType.defaultProps = {
  type: "",
  icon: {},
  title: "",
  intro: "",
  buttons: [],
  buttonColor: ""
};

export default ReportType;

import { generate } from "shortid";
import {
  GET_ENVIRONMENTS_URL,
  GET_PRODUCTS_URL,
  GET_RANKINGS_URL,
  GET_WEIGHTINGS_URL,
  EDIT_STERLING_URL,
  GET_SHEETS_URL,
  API_KEY,
  CHANNELS
} from "../constants";
import {
  GET_ENVIRONMENTS_REQUEST,
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_ERROR,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_RANKINGS_REQUEST,
  GET_RANKINGS_SUCCESS,
  GET_RANKINGS_ERROR,
  GET_WEIGHTINGS_REQUEST,
  GET_WEIGHTINGS_SUCCESS,
  GET_WEIGHTINGS_ERROR,
  EDIT_STERLING_REQUEST,
  EDIT_STERLING_SUCCESS,
  EDIT_STERLING_ERROR,
  GET_SHEETS_REQUEST,
  GET_SHEETS_SUCCESS,
  GET_SHEETS_ERROR,
  CLEAR_STERLING_DATA
} from "../constants/actionConstants";
import { addNotification } from "./notification";

function getEnvironmentsRequest() {
  return {
    type: GET_ENVIRONMENTS_REQUEST
  };
}

function getEnvironmentsSuccess(retailers) {
  return {
    type: GET_ENVIRONMENTS_SUCCESS,
    retailers
  };
}

function getEnvironmentsError(error) {
  return {
    type: GET_ENVIRONMENTS_ERROR,
    error
  };
}

export const getEnvironments = () => async dispatch => {
  dispatch(getEnvironmentsRequest());
  try {
    const res = await fetch(GET_ENVIRONMENTS_URL, {
      headers: { "X-API-KEY": API_KEY }
    });
    if (!res.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = await res.json();
    const retailers = [];
    CHANNELS.forEach(channel =>
      data[channel].forEach(i => {
        retailers.push({ name: i, channel });
      })
    );
    dispatch(getEnvironmentsSuccess(retailers));
  } catch (err) {
    dispatch(getEnvironmentsError(err.message));
  }
};

function getProductsRequest() {
  return {
    type: GET_PRODUCTS_REQUEST
  };
}

function getProductsSuccess(products) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    products
  };
}

function getProductsError(error) {
  return {
    type: GET_PRODUCTS_ERROR,
    error
  };
}

export const getProducts =
  (channel, environments, type, rankType, consumptionType) =>
  async dispatch => {
    dispatch(getProductsRequest());
    try {
      const res = await fetch(GET_PRODUCTS_URL, {
        method: "post",
        body: JSON.stringify({
          env_type: channel,
          environments,
          type,
          result_type: rankType,
          consumption_type: consumptionType
        }),
        headers: { "X-API-KEY": API_KEY }
      });
      if (!res.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await res.json();
      if (String(data).startsWith("ERROR")) {
        throw new Error(String(data));
      }
      return dispatch(
        getProductsSuccess(data.map(i => ({ ...i, id: generate() })))
      ); // TODO: remove temp fix
    } catch (err) {
      return dispatch(getProductsError(err.message));
    }
  };

function getRankingsRequest() {
  return {
    type: GET_RANKINGS_REQUEST
  };
}

function getRankingsSuccess(rankings) {
  return {
    type: GET_RANKINGS_SUCCESS,
    rankings
  };
}

function getRankingsError(error) {
  return {
    type: GET_RANKINGS_ERROR,
    error
  };
}

export const getRankings = (channel, environments) => async dispatch => {
  dispatch(getRankingsRequest());
  try {
    const res = await fetch(GET_RANKINGS_URL, {
      method: "post",
      body: JSON.stringify({ env_type: channel, environments }),
      headers: { "X-API-KEY": API_KEY }
    });
    if (!res.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = await res.json();
    if (String(data).startsWith("ERROR")) {
      throw new Error(String(data));
    }
    return dispatch(getRankingsSuccess(data));
  } catch (err) {
    return dispatch(getRankingsError(err.message));
  }
};

function getWeightingsRequest() {
  return {
    type: GET_WEIGHTINGS_REQUEST
  };
}

function getWeightingsSuccess(weightings) {
  return {
    type: GET_WEIGHTINGS_SUCCESS,
    weightings
  };
}

function getWeightingsError(error) {
  return {
    type: GET_WEIGHTINGS_ERROR,
    error
  };
}

export const getWeightings = () => async dispatch => {
  dispatch(getWeightingsRequest());
  try {
    const res = await fetch(GET_WEIGHTINGS_URL, {
      headers: { "X-API-KEY": API_KEY }
    });
    if (!res.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = await res.json();
    if (String(data).startsWith("ERROR")) {
      throw new Error(String(data));
    }
    return dispatch(getWeightingsSuccess(data));
  } catch (err) {
    return dispatch(getWeightingsError(err.message));
  }
};

function editSterlingRequest() {
  return {
    type: EDIT_STERLING_REQUEST
  };
}

function editSterlingSuccess(data, option) {
  return {
    type: EDIT_STERLING_SUCCESS,
    data,
    option
  };
}

function editSterlingError(error) {
  return {
    type: EDIT_STERLING_ERROR,
    error
  };
}

const getOptionFromType = type => {
  switch (type) {
    case "sku":
      return "products";
    case "rankings":
      return "rankings";
    case "weightings":
      return "weightings";
    default:
      return type;
  }
};

export const editSterling =
  (
    type,
    data,
    updatedData,
    channel,
    environments,
    onSuccess = () => {},
    name, // sku only
    skus // sku only
  ) =>
  async dispatch => {
    dispatch(editSterlingRequest());
    try {
      const res = await fetch(EDIT_STERLING_URL, {
        method: "post",
        body: JSON.stringify({
          env_type: channel,
          environments,
          type,
          data,
          skus,
          name
        }),
        headers: { "X-API-KEY": API_KEY }
      });
      if (!res.ok) {
        throw new Error("Network response was not ok.");
      }
      const response = await res.json();
      if (String(response).startsWith("ERROR")) {
        throw new Error(String(response));
      }
      if (response === "SUCCESS") {
        onSuccess();
        dispatch(addNotification(`Successfully edited ${type}`, "success"));
        return dispatch(
          editSterlingSuccess(updatedData, getOptionFromType(type))
        );
      }
      return dispatch(editSterlingError(response));
    } catch (err) {
      return dispatch(editSterlingError(err.message));
    }
  };

function getSheetsRequest() {
  return {
    type: GET_SHEETS_REQUEST
  };
}

function getSheetsSuccess(sheets) {
  return {
    type: GET_SHEETS_SUCCESS,
    sheets
  };
}

function getSheetsError(error) {
  return {
    type: GET_SHEETS_ERROR,
    error
  };
}

export const getSheets = () => async dispatch => {
  dispatch(getSheetsRequest());
  try {
    const res = await fetch(GET_SHEETS_URL, {
      headers: { "X-API-KEY": API_KEY }
    });
    if (!res.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = await res.json();
    dispatch(getSheetsSuccess(data));
  } catch (err) {
    dispatch(getSheetsError(err.message));
  }
};

export const clearSterlingData = () => ({
  type: CLEAR_STERLING_DATA
});

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { WhereSection, WhenSection, MeasureSection } from "../SharedComponents";

const Constraints = props => {
  const {
    subsections,
    searchTerms,
    story,
    whereError,
    whenError,
    measureError,
    clearErrors
  } = props;
  return (
    <Fragment>
      <WhereSection
        selected={searchTerms.find(s => s.table === "where")}
        options={subsections
          .filter(s => s.table === "where")
          .filter(i => i.name !== "prr_retailer")}
        hint={whereError}
        story={story}
        clearErrors={clearErrors}
      />
      <WhenSection
        selected={searchTerms.find(s => s.table === "when")}
        options={subsections.filter(s => s.table === "when")}
        hint={whenError}
        story={story}
        clearErrors={clearErrors}
      />
      <MeasureSection
        selected={searchTerms.find(s => s.table === "measure")}
        options={subsections.filter(s => s.table === "measure")}
        hint={measureError}
        story={story}
        clearErrors={clearErrors}
      />
    </Fragment>
  );
};

Constraints.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  whereError: PropTypes.string,
  whenError: PropTypes.string,
  measureError: PropTypes.string,
  clearErrors: PropTypes.func
};

Constraints.defaultProps = {
  subsections: [],
  searchTerms: [],
  story: "",
  whereError: "",
  whenError: "",
  measureError: "",
  clearErrors: () => {}
};

export default Constraints;

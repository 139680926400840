import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Favicon from "react-favicon";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  Link,
  Box
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { StarBorderRounded } from "@mui/icons-material";
import { DownloadIcon, ReportsReviewsIcon } from "../Icons";
import {
  fetchReport,
  fetchLocalReport,
  addToFavourites,
  goToSlide,
  downloadPowerPoint
} from "../../actions";
import { loading } from "../../images";
import { getStoryConfig } from "../../config";
import Presentation from "./Presentation";
import PresentationNavLeft from "./PresentationNavLeft";
import PresentationNavRight from "./PresentationNavRight";
import "./Presentation.css";
import PresentationError from "./PresentationError";
import PresentationNav from "./PresentationNav";

function loadWidget() {
  window.HubSpotConversations.widget.load();
}

function refreshWidget() {
  window.HubSpotConversations.widget.refresh();
}

export class ViewReport extends Component {
  componentDidMount() {
    const {
      getReport,
      goToFirstSlide,
      slideIndex,
      userId,
      date,
      location,
      client
    } = this.props;
    const queryString = location.search.slice(1);
    const urlParams = new URLSearchParams(queryString);
    const story =
      urlParams.get("story") && urlParams.get("story").toLowerCase();
    const { dataSet } = getStoryConfig(story);
    getReport(queryString, userId, date[dataSet], client);
    if (slideIndex > 0) {
      goToFirstSlide();
    }
    if (window.HubSpotConversations) {
      loadWidget();
    } else {
      window.hsConversationsOnReady = [loadWidget];
    }
  }

  componentWillUnmount() {
    if (window.HubSpotConversations) {
      refreshWidget();
    } else {
      window.hsConversationsOnReady = [refreshWidget];
    }
  }

  render() {
    const {
      reportIsLoading,
      reportError,
      report,
      userId,
      addFavourite,
      download,
      location,
      slideIndex,
      reportTitle,
      reportQuery,
      reportId,
      client,
      reportStory,
      isDownloading,
      reportClient
    } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const story =
      urlParams.get("story") && urlParams.get("story").toLowerCase();
    const storyConfig = getStoryConfig(story);
    if (reportIsLoading) {
      return (
        <Fragment>
          <Favicon url={["favicon0.ico", "favicon1.ico", "favicon2.ico"]} />
          <Grid container justifyContent="center">
            <Grid item>
              <Box textAlign="center" sx={{ mt: 3 }}>
                <img style={{ width: "50%" }} alt="Interrodata" src={loading} />
              </Box>
            </Grid>
            <Grid item>
              <Typography align="center" gutterBottom>
                You can run{" "}
                <Link
                  href={`/reports-reviews/new-${storyConfig.newLink}`}
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                >
                  another report
                </Link>{" "}
                or view{" "}
                <Link
                  href={`/reports-reviews/${storyConfig.link}`}
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                >
                  your recent reports
                </Link>{" "}
                while you are waiting
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
    if (reportError.message) {
      return (
        <Grid container justifyContent="center">
          <Grid item md={10} sm={12}>
            <Favicon url="favicon.ico" />
            <PresentationError
              errorMessage={reportError.message}
              story={story}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Fragment>
        <Favicon url="favicon.ico" />
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item md={7} xs={12}>
              <Paper elevation={3} sx={{ height: 1 }}>
                <Tooltip title={reportTitle} id="titleTooltip">
                  <Typography
                    variant="subtitle2"
                    color="secondary.dark"
                    noWrap
                    sx={{ py: 2 }}
                  >
                    <ReportsReviewsIcon
                      sx={{ mx: 1, verticalAlign: "middle" }}
                    />
                    {reportTitle}
                  </Typography>
                </Tooltip>
              </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1}>
                <Grid item md={2} xs={1}>
                  <Paper elevation={3} sx={{ height: 1 }}>
                    <Tooltip title="Add to favourites">
                      <IconButton
                        sx={{ "&:hover": { color: "yellow.main" } }}
                        onClick={() =>
                          addFavourite(userId, { query: reportQuery })
                        }
                      >
                        <StarBorderRounded sx={{ fontSize: 32 }} />
                      </IconButton>
                    </Tooltip>
                  </Paper>
                </Grid>
                <Grid item md={3} xs={5}>
                  <Paper elevation={3} sx={{ height: 1 }}>
                    <PresentationNav
                      slideIndex={slideIndex}
                      reportLength={report.length}
                    />
                  </Paper>
                </Grid>
                <Grid item md={7} xs={6}>
                  <Paper elevation={3} sx={{ height: 1 }}>
                    <LoadingButton
                      color="orange"
                      sx={{
                        width: 1,
                        height: 1,
                        textTransform: "none"
                      }}
                      startIcon={<DownloadIcon />}
                      onClick={() => {
                        const slideKey = report[slideIndex].slidekey;
                        download(
                          reportId,
                          reportStory,
                          userId,
                          client,
                          slideIndex,
                          slideKey
                        );
                      }}
                      loading={isDownloading}
                      loadingPosition="start"
                      loadingIndicator={
                        <CircularProgress
                          color="inherit"
                          size={20}
                          sx={{ ml: 2 }}
                        />
                      }
                      id="download" // for load-runner
                    >
                      Download Report
                    </LoadingButton>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={1}>
              <PresentationNavLeft
                activeSlideIndex={slideIndex}
                report={report}
                reportId={reportId}
                userId={userId}
                story={reportStory}
                client={client}
              />
            </Grid>
            <Grid item md={10}>
              <Presentation
                activeSlideIndex={slideIndex}
                report={report}
                client={reportClient}
                story={reportStory}
              />
            </Grid>
            <Grid item md={1}>
              <PresentationNavRight
                activeSlideIndex={slideIndex}
                report={report}
                reportId={reportId}
                userId={userId}
                story={reportStory}
                client={client}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

ViewReport.propTypes = {
  report: PropTypes.arrayOf(PropTypes.shape()),
  reportIsLoading: PropTypes.bool,
  reportError: PropTypes.shape(),
  getReport: PropTypes.func,
  slideIndex: PropTypes.number,
  goToFirstSlide: PropTypes.func,
  userId: PropTypes.string,
  addFavourite: PropTypes.func,
  date: PropTypes.shape(),
  download: PropTypes.func,
  location: PropTypes.shape(),
  reportTitle: PropTypes.string,
  reportQuery: PropTypes.string,
  reportId: PropTypes.string,
  client: PropTypes.string,
  reportStory: PropTypes.string,
  isDownloading: PropTypes.bool,
  reportClient: PropTypes.string
};

ViewReport.defaultProps = {
  report: [],
  reportIsLoading: true,
  reportError: {},
  getReport: () => {},
  slideIndex: 0,
  goToFirstSlide: () => {},
  userId: "",
  addFavourite: () => {},
  date: {},
  download: () => {},
  location: {},
  reportTitle: "",
  reportQuery: "",
  reportId: "",
  client: "",
  reportStory: "",
  isDownloading: false,
  reportClient: ""
};

const mapStateToProps = state => ({
  report: state.report.reportContent,
  reportIsLoading: state.report.isLoading,
  reportError: state.report.error,
  reportTitle: state.report.reportTitle,
  reportQuery: state.report.reportQuery,
  reportId: state.report.reportId,
  reportClient: state.report.reportClient,
  reportStory: state.report.reportStory,
  slideIndex: state.presentation.activeSlideIndex,
  userId: state.user.user.id,
  client: state.user.user.client,
  date: state.data.date,
  isDownloading: state.presentation.isLoading
});

const mapDispatchToProps = dispatch => ({
  getReport: (queryString, userId, date, client) => {
    if (process.env.REACT_APP_DEV_ENV === "local") {
      dispatch(fetchLocalReport());
    } else {
      dispatch(fetchReport(queryString, userId, date, client));
    }
  },
  goToFirstSlide: () => dispatch(goToSlide(0)),
  addFavourite: (userId, report) => dispatch(addToFavourites(userId, report)),
  download: (id, story, userId, client, slideIndex, slideKey) => {
    dispatch(
      downloadPowerPoint(id, story, userId, client, slideIndex, slideKey)
    );
  }
});

export { ViewReport as ViewReportComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewReport));

const getServerURL = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "local":
      return {
        backend: "",
        user: "http://localhost:7070",
        ppt: "http://localhost:6060",
        staticData: "",
        dataModel: "",
        subscription: "",
        sterling: ""
      };
    case "dev":
      return {
        backend: "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
        user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
        ppt: "https://2jpybn3b7c.execute-api.eu-west-2.amazonaws.com/dev",
        staticData:
          "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev",
        dataModel: "https://bn2j8mx040.execute-api.eu-west-2.amazonaws.com/dev",
        subscription:
          "https://rdxo77jwp5.execute-api.eu-west-2.amazonaws.com/dev",
        sterling: "https://ozhxjei58l.execute-api.eu-west-2.amazonaws.com/dev"
      };
    case "qa":
      return {
        backend: "https://afni9qu3ba.execute-api.eu-west-2.amazonaws.com/qa",
        user: "https://jbbwutlv1h.execute-api.eu-west-2.amazonaws.com/qa",
        ppt: "https://k6azys798d.execute-api.eu-west-2.amazonaws.com/qa",
        staticData: "https://abjm4cit0a.execute-api.eu-west-2.amazonaws.com/qa",
        dataModel: "https://uy8ssrra8f.execute-api.eu-west-2.amazonaws.com/qa",
        subscription:
          "https://2d912htz5i.execute-api.eu-west-2.amazonaws.com/qa",
        sterling: "https://3al6bupcul.execute-api.eu-west-2.amazonaws.com/qa"
      };
    case "stg":
      return {
        backend: "https://8k9ekgnf8f.execute-api.eu-west-2.amazonaws.com/stg",
        user: "https://xv2jct1qoh.execute-api.eu-west-2.amazonaws.com/stg",
        ppt: "https://4xer44oco5.execute-api.eu-west-2.amazonaws.com/stg",
        staticData:
          "https://wkcsnbsbl4.execute-api.eu-west-2.amazonaws.com/stg",
        dataModel: "https://aeirbxytx3.execute-api.eu-west-2.amazonaws.com/stg",
        subscription:
          "https://36hc69h94d.execute-api.eu-west-2.amazonaws.com/stg",
        sterling: "https://9ok6eq80s2.execute-api.eu-west-2.amazonaws.com/stg"
      };
    case "prod":
      return {
        backend: "https://hptcaf5pkk.execute-api.eu-west-2.amazonaws.com/prod",
        user: "https://tmy6i3f8z1.execute-api.eu-west-2.amazonaws.com/prod",
        ppt: "https://uado7o8w62.execute-api.eu-west-2.amazonaws.com/prod",
        staticData:
          "https://nwyyc6ypml.execute-api.eu-west-2.amazonaws.com/prod",
        dataModel:
          "https://3f80384819.execute-api.eu-west-2.amazonaws.com/prod",
        subscription:
          "https://4xajdtzdk7.execute-api.eu-west-2.amazonaws.com/prod",
        sterling: "https://498bz055ai.execute-api.eu-west-2.amazonaws.com/prod"
      };
    default:
      return {
        backend: "https://9ks97eqm3j.execute-api.eu-west-2.amazonaws.com/dev",
        user: "https://wqn3hrrpsc.execute-api.eu-west-2.amazonaws.com/dev",
        ppt: "https://2jpybn3b7c.execute-api.eu-west-2.amazonaws.com/dev",
        staticData:
          "https://uxhremzpp6.execute-api.eu-west-2.amazonaws.com/dev",
        dataModel: "https://bn2j8mx040.execute-api.eu-west-2.amazonaws.com/dev",
        subscription:
          "https://rdxo77jwp5.execute-api.eu-west-2.amazonaws.com/dev",
        sterling: "https://ozhxjei58l.execute-api.eu-west-2.amazonaws.com/dev"
      };
  }
};

export const getStoryConfig = story => {
  switch (story.toLowerCase()) {
    case "ida":
      return {
        link: "performance",
        newLink: "ih-performance",
        dataSet: "nielsen"
      };
    case "cga":
      return {
        link: "performance",
        newLink: "ooh-performance",
        dataSet: "cga"
      };
    case "prr":
      return {
        link: "rangereview",
        newLink: "rangereview",
        dataSet: "nielsen"
      };
    case "bb":
      return {
        link: "brandbattles",
        newLink: "brandbattles",
        dataSet: "nielsen_secondary"
      };
    default:
      return {
        link: "performance",
        newLink: "ih-performance",
        dataSet: "nielsen"
      };
  }
};

export const getChannelConfig = channel => {
  switch (channel) {
    case "ih":
      return { dataSet: "nielsen_secondary", tab: 0, isMonth: false };
    case "ooh":
      return { dataSet: "cga", tab: 1, isMonth: true };
    default:
      return { dataSet: "nielsen_secondary", tab: 0, isMonth: false };
  }
};

export const getClientURL = client => {
  switch (client) {
    case "ccep":
      return "ccepgb.interrodata.com";
    case "snappy":
      return "snappy.interrodata.com";
    default:
      return "";
  }
};

const config = {
  serverURL: {
    ...getServerURL()
  },
  app: {
    timeout: 28800, // 8 hrs in seconds
    firstPollTimer: 90000,
    generalPollTimer: 30000,
    totalPolls: 26,
    numberSuggestions: 30,
    numberPreferences: 3
  },
  hierarchList: [
    {
      name: "category",
      ranking: 4,
      isFilter: false
    },
    {
      name: "sector",
      ranking: 0,
      isFilter: true
    },
    {
      name: "distributor_id",
      ranking: 4,
      isFilter: false
    },
    {
      name: "major_brand",
      ranking: 3,
      isFilter: true
    },
    {
      name: "sub_brand",
      ranking: 2,
      isFilter: true
    },
    {
      name: "sugar_content",
      ranking: 1,
      isFilter: false
    },
    {
      name: "flavour",
      ranking: 1,
      isFilter: false
    },
    {
      name: "pack_type",
      ranking: 1,
      isFilter: false
    }
  ],
  groupings: ["product", "packaging", "package detail"],
  bbProducts: ["distributor_id", "major_brand", "sub_brand"],
  dataSets: ["nielsen", "cga", "nielsen_secondary"],
  channels: ["ih", "ooh"],
  restatementYears: [2020, 2026, 2032, 2037, 2043, 2048],
  isRestatement: false,
  sterlingHighlightColumns: [
    "value_sales",
    "numeric_distribution",
    "ros",
    "financial_score",
    "retained_value",
    "sku_growth"
  ]
};

export default config;

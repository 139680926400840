import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { connect } from "react-redux";
import { getYear } from "date-fns";
import IdleTimer from "react-idle-timer";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  Container
} from "@mui/material";
import theme from "./theme";
import { TIMEOUT } from "./constants";
import "./App.css";
import {
  logout,
  clearStaticDataCache,
  fetchStaticData,
  clearAllSearchData,
  clearLookoutData,
  clearSterlingData
} from "./actions";
import Notification from "./Notification";
import Home from "./components/Home";
import Login, { ForgotPasswordReset } from "./components/Login";
import Settings from "./components/Settings";
import ViewReport from "./components/ViewReport";
import About from "./components/About";
import Support from "./components/Support";
import MenuBar from "./components/MenuBar";
import ReportsReviews from "./components/ReportsReviews";
import Lookout, { LookoutPreferences } from "./components/Lookout";
import Sterling from "./components/Sterling";
import SKUEditing, { NewPrioritisation } from "./components/SKUEditing";
import RankingCustomisation from "./components/RankingCustomisation";
import Weightings from "./components/Weightings";
import SKUPrioritisation from "./components/SKUPrioritisation";

class App extends Component {
  componentDidMount() {
    const { isUser, clearData, isStaticData } = this.props;
    // clear static data and logout when refresh after cookie expires
    if (!isUser && isStaticData) {
      clearData();
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "https://js.hs-scripts.com/4913136.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  componentDidUpdate() {
    const { isUser, isStaticData, getData, client } = this.props;
    // get static data when user logs in
    if (isUser && !isStaticData) {
      getData(client);
    }
  }

  render() {
    const { isUser, clearData, isSterling } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <IdleTimer
            element={document}
            onIdle={clearData}
            timeout={TIMEOUT * 1000}
          >
            <Router>
              <div className="App">
                {isUser && <MenuBar />}
                <Notification />
                <Container className="container">
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <Home />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/reports"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <ViewReport />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/reports-reviews/:type"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <ReportsReviews />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/lookout::tabValue"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <Lookout />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/lookout-preferences::channelValue"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <LookoutPreferences />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/sterling-options"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          (isSterling && <Sterling />) || (
                            <Redirect
                              to="/sku-prioritisation"
                              component={SKUPrioritisation}
                            />
                          )
                        )
                      }
                    />
                    <Route
                      exact
                      path="/sku-editing"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          (isSterling && <SKUEditing />) || (
                            <Redirect
                              to="/sku-prioritisation"
                              component={SKUPrioritisation}
                            />
                          )
                        )
                      }
                    />
                    <Route
                      exact
                      path="/new-prioritisation"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          (isSterling && <NewPrioritisation />) || (
                            <Redirect
                              to="/sku-prioritisation"
                              component={SKUPrioritisation}
                            />
                          )
                        )
                      }
                    />
                    <Route
                      exact
                      path="/ranking-customisation"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          (isSterling && <RankingCustomisation />) || (
                            <Redirect
                              to="/sku-prioritisation"
                              component={SKUPrioritisation}
                            />
                          )
                        )
                      }
                    />
                    <Route
                      exact
                      path="/weightings"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          (isSterling && <Weightings />) || (
                            <Redirect
                              to="/sku-prioritisation"
                              component={SKUPrioritisation}
                            />
                          )
                        )
                      }
                    />
                    <Route
                      exact
                      path="/sku-prioritisation"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <SKUPrioritisation />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/about"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <About />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/support"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <Support />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/settings"
                      render={props =>
                        !isUser ? (
                          <Redirect
                            to={{
                              pathname: "/login",
                              state: { from: props.location }
                            }}
                            component={Login}
                          />
                        ) : (
                          <Settings />
                        )
                      }
                    />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/logout" component={Login} />
                    <Route
                      exact
                      path="/reset"
                      component={ForgotPasswordReset}
                    />
                    <Redirect from="/*" to="/" />
                  </Switch>
                </Container>
                <footer id="footer">
                  &copy; {getYear(new Date())} Interrodata
                </footer>
              </div>
            </Router>
          </IdleTimer>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

App.propTypes = {
  isUser: PropTypes.bool,
  clearData: PropTypes.func,
  isStaticData: PropTypes.bool,
  getData: PropTypes.func,
  client: PropTypes.string,
  isSterling: PropTypes.bool
};

App.defaultProps = {
  isUser: false,
  clearData: () => {},
  isStaticData: false,
  getData: () => {},
  client: "",
  isSterling: false
};

const mapStateToProps = state => ({
  isUser: state.user.isUser,
  isStaticData: state.data.isStaticData,
  client: state.user.user.client,
  isSterling: state.user.user.sterling
});

const mapDispatchToProps = dispatch => ({
  clearData: () => {
    dispatch(logout());
    dispatch(clearStaticDataCache());
    dispatch(clearAllSearchData());
    dispatch(clearLookoutData());
    dispatch(clearSterlingData());
  },
  getData: client => {
    dispatch(fetchStaticData(client));
  }
});

export { App as AppComponent };
export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from "react";
import PropTypes from "prop-types";
import { Typography, ListItem, FormControl, FormLabel } from "@mui/material";
import CustomDate from "../CustomDate";

const Period = props => {
  const { dataDate, selectedPeriod, periodError, story } = props;
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, pb: 3, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            The Periods
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
          Please choose the period of the Post Range Review{" "}
          <span className="required">required</span>
        </Typography>
        <CustomDate
          story={story}
          periodError={periodError}
          selectedPeriod={selectedPeriod}
          dataDate={dataDate}
        />
      </FormControl>
    </ListItem>
  );
};

Period.propTypes = {
  dataDate: PropTypes.string,
  selectedPeriod: PropTypes.objectOf(PropTypes.string),
  periodError: PropTypes.string,
  story: PropTypes.string
};

Period.defaultProps = {
  dataDate: "",
  selectedPeriod: undefined,
  periodError: "",
  story: ""
};

export default Period;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Grid,
  Typography,
  Stack,
  TextField,
  IconButton,
  Button
} from "@mui/material";
import { Search } from "@mui/icons-material";
import TransferList from "./TransferList";
import { initSearch, fuseSearch } from "../../utils";

const AddSKU = props => {
  const { type, options, rows, setRows, onClose } = props;
  const [right, setRight] = useState(rows);
  const [left, setLeft] = useState([]);
  const [products, setProducts] = useState(options);
  const [searchEngine, setSearchEngine] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearchEngine(
      initSearch(_.difference(options, right), ["product_name"], true)
    );
  }, []);

  useEffect(() => {
    setSearchEngine(initSearch(options, ["product_name"], true));
  }, [options]);

  const handleChange = e => {
    setSearch(e.target.value);
    const results = fuseSearch(searchEngine, e.target.value);
    setProducts(results.map(i => i.item));
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item md={12}>
        <Typography variant="subtitle2">
          {type === "sku"
            ? "SKU Number"
            : "Which product are you interested in?"}
        </Typography>
      </Grid>
      <Grid
        item
        md={12}
        container
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item md={5.5}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 1 }}
            spacing={0.5}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder={
                type === "sku" ? "e.g. 049000067316" : "e.g. Coca Cola Zero"
              }
              sx={{ width: 1 }}
              onChange={handleChange}
            />
            <IconButton
              color="primary"
              sx={{ bgcolor: "primary.main", borderRadius: 1 }}
            >
              <Search htmlColor="white" />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item md={5.5}>
          <Typography variant="subtitle2" gutterBottom>
            Selected Products
          </Typography>
        </Grid>
      </Grid>
      <TransferList
        options={search.length > 0 ? products : options}
        right={right}
        setRight={setRight}
        left={left}
        setLeft={setLeft}
      />
      <Grid item md={12} container justifyContent="flex-end" sx={{ pt: 2 }}>
        <Button
          variant="contained"
          disableElevation
          disabled={right.length === 0}
          onClick={() => {
            setRows(right);
            onClose();
          }}
        >
          Add to table
        </Button>
      </Grid>
    </Grid>
  );
};

AddSKU.propTypes = {
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  setRows: PropTypes.func,
  onClose: PropTypes.func
};

AddSKU.defaultProps = {
  type: "",
  options: [],
  rows: [],
  setRows: () => {},
  onClose: () => {}
};

export default AddSKU;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Grid,
  Card,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  Stack
} from "@mui/material";
import { IHIcon, OOHIcon } from "../Icons";
import { lookout } from "../../images";
import { getLookout, getPreferences } from "../../actions";
import UpdateDate from "./UpdateDate";
import NoInsights from "./NoInsights";
import Insights from "./Insights";
import { getChannelConfig } from "../../config";

const Lookout = props => {
  const {
    preferences,
    dataDate,
    lookoutData,
    isLookoutData,
    getLookoutData,
    userId,
    getPreferenceList,
    client
  } = props;
  const { tabValue } = useParams();
  const history = useHistory();
  const [value, setValue] = useState(Number(tabValue));
  const [tab, setTab] = useState(0);
  const [channel, setChannel] = useState("ih");
  const [dataSet, setDataSet] = useState("nielsen_secondary");
  const isPreference = preferences[channel] && preferences[channel].length > 0;
  useEffect(() => {
    if (!isPreference) {
      getPreferenceList(userId);
    }
    if (!isLookoutData) {
      getLookoutData(userId, dataDate, client);
    }
  }, []);
  useEffect(() => {
    if (!isLookoutData) {
      getLookoutData(userId, dataDate, client);
    }
  }, [dataDate]);

  const handleChange = (e, val) => {
    setValue(val);
    history.push(`/lookout:${val}`);
  };

  return (
    <Card sx={{ p: 2.5, width: 1 }}>
      <Grid container justifyContent="space-between" sx={{ p: 2 }}>
        <Grid item md={6}>
          <Typography variant="h2" gutterBottom color="primary.dark">
            Welcome to
            <img
              alt="lookout"
              src={lookout}
              style={{ width: 150, margin: 3, marginBottom: -3 }}
            />
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            What you need to know without even asking a question!
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Stack alignItems="flex-end" spacing={2}>
            <UpdateDate dataDate={dataDate} />
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={`/lookout-preferences:${channel}`}
            >
              Your Preferences
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid item md={12} container sx={{ p: 2, mt: -10 }}>
        <Grid item md={12} container sx={{ mb: 0.5 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: 0.5 }}>
            <Tabs
              value={tab}
              onChange={(e, val) => {
                setTab(val);
                setChannel(e.target.name);
                setDataSet(getChannelConfig(e.target.name).dataSet);
                // reset to first tab
                handleChange(null, 0);
              }}
            >
              <Tab
                label="In Home"
                name="ih"
                icon={<IHIcon fontSize="small" />}
                iconPosition="start"
              />
              <Tab
                label="Out of Home"
                name="ooh"
                icon={<OOHIcon fontSize="small" />}
                iconPosition="start"
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item md={12} container>
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: 1 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label="Your Top Insights" />
              <Tab label="Total CCEP insights" />
            </Tabs>
          </Box>
        </Grid>
        <Grid item md={12} container sx={{ p: 2, my: 2 }}>
          {value === 0 &&
            (isPreference &&
            isLookoutData &&
            lookoutData[channel].user &&
            lookoutData[channel].user.insight &&
            lookoutData[channel].user.insight.length > 0 ? (
              <Insights
                dataDate={dataDate[dataSet]}
                data={lookoutData[channel].user.insight}
                client={client}
                dataSet={dataSet}
              />
            ) : (
              <NoInsights
                isPreference={isPreference}
                dataDate={dataDate}
                channel={channel}
              />
            ))}
          {value === 1 && (
            <Insights
              dataDate={dataDate[dataSet]}
              data={
                lookoutData[channel] && lookoutData[channel].totalClient
                  ? lookoutData[channel].totalClient.insight
                  : []
              }
              client={client}
              isTotalClient
              dataSet={dataSet}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

Lookout.propTypes = {
  preferences: PropTypes.shape(),
  dataDate: PropTypes.shape(),
  lookoutData: PropTypes.shape(),
  isLookoutData: PropTypes.bool,
  getLookoutData: PropTypes.func,
  userId: PropTypes.string,
  getPreferenceList: PropTypes.func,
  client: PropTypes.string
};

Lookout.defaultProps = {
  preferences: {},
  dataDate: {},
  lookoutData: {},
  isLookoutData: false,
  getLookoutData: () => {},
  userId: "",
  getPreferenceList: () => {},
  client: ""
};

const mapStateToProps = state => ({
  preferences: state.lookout.preferences,
  dataDate: state.data.date,
  lookoutData: state.lookout.lookout,
  isLookoutData: state.lookout.isLookout,
  userId: state.user.user.id,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  getLookoutData: (userId, dataDate, client) =>
    dispatch(getLookout(userId, dataDate, client)),
  getPreferenceList: userId => dispatch(getPreferences(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Lookout);

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  Chip,
  CardActions,
  Button
} from "@mui/material";

const styles = {
  type: {
    mr: -1,
    px: 2,
    py: 0.5,
    bgcolor: "#f7f3f2",
    borderRadius: "12px 0 0 12px"
  },
  actionButton: {
    borderRadius: 0,
    textTransform: "none",
    p: 1.5,
    fontWeight: "normal",
    borderTop: "1px solid #009dbc"
  }
};

const Sheets = props => {
  const { sheets } = props;
  return (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      {sheets.map((i, k) => (
        <Grid item md={3} key={k.toString()} id={k.toString()}>
          <Card raised>
            <CardHeader
              title={i.name}
              titleTypographyProps={{ variant: "subtitle1" }}
              action={
                <Typography
                  variant="subtitle3"
                  color="secondary"
                  sx={styles.type}
                >
                  {i.env_type === "ooh" ? "Out of Home" : "In Home"}
                </Typography>
              }
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="h7" fontSize={14} gutterBottom>
                Number of SKUs&nbsp;&nbsp;
                <Typography
                  variant="subtitle3"
                  fontSize={14}
                  component="span"
                  gutterBottom
                >
                  {i.skus.length}
                </Typography>
              </Typography>
              <Box sx={{ mx: -0.5, mt: 1.5 }}>
                {i.environments.map(j => (
                  <Chip key={j} sx={{ m: 0.5 }} label={j} />
                ))}
              </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ p: 0 }}>
              <Button
                variant="text"
                fullWidth
                disableElevation
                component={Link}
                to={{
                  pathname: "/new-prioritisation",
                  state: {
                    channel: i.env_type,
                    environments: i.environments,
                    name: i.name,
                    skus: i.skus,
                    step: 1
                  }
                }}
                size="large"
                sx={styles.actionButton}
              >
                View Sheet
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

Sheets.propTypes = {
  sheets: PropTypes.arrayOf(PropTypes.shape())
};

Sheets.defaultProps = {
  sheets: []
};

export default Sheets;

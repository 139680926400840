import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Typography,
  ListItem,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@mui/material";
import {
  enterSelectedTerm,
  deleteTerm,
  filterStaticData,
  removeFilterTerm
} from "../../actions";
import {
  AmbientIcon,
  ChilledIcon,
  AmbientChilledIcon,
  ICIcon,
  FCIcon,
  ICFCIcon
} from "../Icons";

const styles = {
  label: selected => ({
    width: 380,
    borderRadius: 1,
    border: selected ? "1px solid primary.main" : "1px solid #cac5c4",
    bgcolor: selected && "primary.main"
  }),
  icon: selected => ({
    mr: 1,
    bgcolor: !selected && "#f7f3f2",
    borderRadius: !selected && "4px 0 0 4px",
    borderRight: !selected && "1px solid #cac5c4"
  })
};

const Scope = props => {
  const {
    selectedTemperature,
    selectedConsumption,
    temperatureError,
    consumptionError,
    onSelect,
    filterTerms,
    story,
    client
  } = props;
  const [temperature, setTemperature] = useState(null);
  const [consumption, setConsumption] = useState(null);
  useEffect(() => {
    setTemperature(selectedTemperature ? selectedTemperature.name : null);
    setConsumption(selectedConsumption ? selectedConsumption.name : null);
  }, []);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            The Scope
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
          Include Ambient and/or Chilled{" "}
          <span className="required">required</span>
        </Typography>
        <RadioGroup sx={{ mb: 2 }}>
          <ListItem>
            <FormControlLabel
              sx={styles.label(temperature === "AMBIENT")}
              control={
                <Radio
                  icon={<AmbientIcon color="primary" />}
                  checkedIcon={<AmbientIcon htmlColor="white" />}
                  sx={styles.icon(temperature === "AMBIENT")}
                  checked={temperature === "AMBIENT"}
                  onChange={e => {
                    setTemperature(e.target.value);
                    onSelect(
                      e.target.value,
                      "temperature",
                      selectedTemperature,
                      story,
                      filterTerms,
                      client
                    );
                  }}
                  value="AMBIENT"
                />
              }
              label={
                <Typography
                  variant="body2"
                  color={temperature === "AMBIENT" && "white"}
                >
                  Ambient
                </Typography>
              }
            />
            {!temperature && (
              <FormHelperText error>{temperatureError}</FormHelperText>
            )}
          </ListItem>
          <ListItem>
            <FormControlLabel
              sx={styles.label(temperature === "CHILLED")}
              control={
                <Radio
                  icon={<ChilledIcon color="primary" />}
                  checkedIcon={<ChilledIcon htmlColor="white" />}
                  sx={styles.icon(temperature === "CHILLED")}
                  checked={temperature === "CHILLED"}
                  onChange={e => {
                    setTemperature(e.target.value);
                    onSelect(
                      e.target.value,
                      "temperature",
                      selectedTemperature,
                      story,
                      filterTerms,
                      client
                    );
                  }}
                  value="CHILLED"
                />
              }
              label={
                <Typography
                  variant="body2"
                  color={temperature === "CHILLED" && "white"}
                >
                  Chilled
                </Typography>
              }
            />
            {!temperature && (
              <FormHelperText error>{temperatureError}</FormHelperText>
            )}
          </ListItem>
          <ListItem>
            <FormControlLabel
              sx={styles.label(temperature === "all")}
              control={
                <Radio
                  icon={<AmbientChilledIcon color="primary" />}
                  checkedIcon={<AmbientChilledIcon htmlColor="white" />}
                  sx={styles.icon(temperature === "all")}
                  checked={temperature === "all"}
                  onChange={e => {
                    setTemperature(e.target.value);
                    onSelect(
                      e.target.value,
                      "temperature",
                      selectedTemperature,
                      story,
                      filterTerms,
                      client
                    );
                  }}
                  value="all"
                />
              }
              label={
                <Typography
                  variant="body2"
                  color={temperature === "all" && "white"}
                >
                  Ambient & Chilled
                </Typography>
              }
            />
            {!temperature && (
              <FormHelperText error>{temperatureError}</FormHelperText>
            )}
          </ListItem>
        </RadioGroup>
        <Typography variant="subtitle2" gutterBottom sx={{ ml: 1 }}>
          Decide on the main area of products{" "}
          <span className="required">required</span>
        </Typography>
        <RadioGroup>
          <ListItem>
            <FormControlLabel
              sx={styles.label(consumption === "FUTURE CONSUMPTION")}
              control={
                <Radio
                  icon={<FCIcon color="primary" />}
                  checkedIcon={<FCIcon htmlColor="white" />}
                  sx={styles.icon(consumption === "FUTURE CONSUMPTION")}
                  checked={consumption === "FUTURE CONSUMPTION"}
                  onChange={e => {
                    setConsumption(e.target.value);
                    onSelect(
                      e.target.value,
                      "consumption_type",
                      selectedConsumption,
                      story,
                      client
                    );
                  }}
                  value="FUTURE CONSUMPTION"
                />
              }
              label={
                <Typography
                  variant="body2"
                  color={consumption === "FUTURE CONSUMPTION" && "white"}
                >
                  FC (Future Consumption / Back of Store)
                </Typography>
              }
            />
            {!consumption && (
              <FormHelperText error>{consumptionError}</FormHelperText>
            )}
          </ListItem>
          <ListItem>
            <FormControlLabel
              sx={styles.label(consumption === "IMMEDIATE CONSUMPTION")}
              control={
                <Radio
                  icon={<ICIcon color="primary" />}
                  checkedIcon={<ICIcon htmlColor="white" />}
                  sx={styles.icon(consumption === "IMMEDIATE CONSUMPTION")}
                  checked={consumption === "IMMEDIATE CONSUMPTION"}
                  onChange={e => {
                    setConsumption(e.target.value);
                    onSelect(
                      e.target.value,
                      "consumption_type",
                      selectedConsumption,
                      story,
                      client
                    );
                  }}
                  value="IMMEDIATE CONSUMPTION"
                />
              }
              label={
                <Typography
                  variant="body2"
                  color={consumption === "IMMEDIATE CONSUMPTION" && "white"}
                >
                  IC (Immediate Consumption / Front of Store)
                </Typography>
              }
            />
            {!consumption && (
              <FormHelperText error>{consumptionError}</FormHelperText>
            )}
          </ListItem>
          <ListItem>
            <FormControlLabel
              sx={styles.label(consumption === "all")}
              control={
                <Radio
                  icon={<ICFCIcon color="primary" />}
                  checkedIcon={<ICFCIcon htmlColor="white" />}
                  sx={styles.icon(consumption === "all")}
                  checked={consumption === "all"}
                  onChange={e => {
                    setConsumption(e.target.value);
                    onSelect(
                      e.target.value,
                      "consumption_type",
                      selectedConsumption,
                      story,
                      client
                    );
                  }}
                  value="all"
                />
              }
              label={
                <Typography
                  variant="body2"
                  color={consumption === "all" && "white"}
                >
                  FC & IC
                </Typography>
              }
            />
            {!consumption && (
              <FormHelperText error>{consumptionError}</FormHelperText>
            )}
          </ListItem>
        </RadioGroup>
      </FormControl>
    </ListItem>
  );
};

Scope.propTypes = {
  onSelect: PropTypes.func,
  temperatureError: PropTypes.string,
  consumptionError: PropTypes.string,
  selectedTemperature: PropTypes.objectOf(PropTypes.string),
  selectedConsumption: PropTypes.objectOf(PropTypes.string),
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  client: PropTypes.string
};

Scope.defaultProps = {
  onSelect: () => {},
  temperatureError: "",
  consumptionError: "",
  selectedTemperature: undefined,
  selectedConsumption: undefined,
  filterTerms: [],
  story: "",
  client: ""
};

const mapDispatchToProps = dispatch => ({
  onSelect: (value, subsection, term, story, filterTerms, client) => {
    if (term) {
      dispatch(deleteTerm(term, story));
      if (subsection === "temperature" && term.name !== "all") {
        dispatch(
          removeFilterTerm(
            {
              name: term.name,
              subsection,
              table: "what",
              story
            },
            filterTerms,
            story,
            client
          )
        );
      }
    }
    dispatch(
      enterSelectedTerm(
        {
          name: value,
          subsection,
          story
        },
        story
      )
    );
    if (subsection === "temperature" && value !== "all") {
      dispatch(
        filterStaticData(
          {
            name: value,
            subsection,
            table: "what",
            story
          },
          filterTerms.filter(i => i.subsection !== "temperature"),
          story,
          client
        )
      );
    }
  }
});

export default connect(null, mapDispatchToProps)(Scope);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isSunday } from "date-fns";
import { Typography, ListItem, FormControl, FormLabel } from "@mui/material";
import DatePicker from "react-datepicker";
import { CustomDateInput } from "../CustomDate";
import { enterSelectedTerm, deleteTerm } from "../../actions";
import {
  getWeekStart,
  getWeekEnd,
  locale,
  getImplementationPeriod,
  formatWeekNumber
} from "../../utils";

const ImplementationDate = props => {
  const { onSelect, selectedImplementationDate, selectedPeriod, story } = props;
  const selectedDates = selectedPeriod
    ? selectedPeriod.name
        .split(",")
        .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)))
    : null;
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            Implementation Date
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
          Please choose the date of implementation{" "}
          <Typography
            variant="subtitle2"
            color="primary"
            gutterBottom
            component="span"
          >
            (optional)
          </Typography>
        </Typography>
        <ListItem sx={{ px: 0 }}>
          <DatePicker
            customInput={<CustomDateInput label="Implementation Date" />}
            dateFormat="dd/MM/yyyy"
            locale={locale}
            selected={
              selectedImplementationDate
                ? getWeekStart(selectedImplementationDate.name)
                : null
            }
            showWeekNumbers
            formatWeekNumber={date => formatWeekNumber(date)}
            onChange={date => {
              onSelect(date, selectedImplementationDate, story);
            }}
            filterDate={isSunday}
            minDate={selectedPeriod ? selectedDates[0] : null} // compare start date
            maxDate={selectedPeriod ? selectedDates[3] : null} // ppr end date
            openToDate={
              selectedImplementationDate
                ? getWeekStart(selectedImplementationDate.name)
                : (selectedPeriod && selectedDates[2]) || null
            } // implementation date or prr start date
            showMonthDropdown
            dropdownMode="select"
            popperPlacement="bottom-end"
            disabled={!selectedPeriod}
          />
        </ListItem>
      </FormControl>
    </ListItem>
  );
};

ImplementationDate.propTypes = {
  onSelect: PropTypes.func,
  selectedImplementationDate: PropTypes.objectOf(PropTypes.string),
  selectedPeriod: PropTypes.objectOf(PropTypes.string),
  story: PropTypes.string
};

ImplementationDate.defaultProps = {
  onSelect: () => {},
  selectedImplementationDate: undefined,
  selectedPeriod: undefined,
  story: ""
};

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story) => {
    if (oldValue !== undefined) {
      dispatch(deleteTerm(oldValue, story));
    }
    dispatch(
      enterSelectedTerm(
        {
          name: getImplementationPeriod(selectedValue),
          subsection: "implementation_date",
          story
        },
        story
      )
    );
  }
});

export default connect(null, mapDispatchToProps)(ImplementationDate);

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid, Card, CardHeader, CardContent } from "@mui/material";
import { NoInsights } from "../Lookout";
import Insights from "./Insights";

const Lookout = props => {
  const { date, preferences, lookoutData, isLookoutData, channel } = props;
  return (
    <Fragment>
      <Card elevation={0}>
        <CardHeader
          titleTypographyProps={{
            variant: "h4",
            color: "primary.dark"
          }}
          title={
            <Link to="/lookout:0">
              Your Top Insights ({channel === "ih" ? "In Home" : "Out of Home"})
            </Link>
          }
        />
        <CardContent sx={{ pt: 0 }}>
          {preferences.length > 0 &&
          isLookoutData &&
          lookoutData.user &&
          lookoutData.user.insight &&
          lookoutData.user.insight.length > 0 ? (
            <Grid container spacing={2}>
              {lookoutData.user.insight.map((i, k) => (
                <Grid item md={12} key={k.toString()}>
                  <Insights data={{ ...i, k }} value={0} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <NoInsights
              isPreference={preferences.length > 0}
              dataDate={date}
              channel={channel}
            />
          )}
        </CardContent>
      </Card>
      <Card elevation={0}>
        <CardHeader
          titleTypographyProps={{
            variant: "h4",
            color: "primary.dark"
          }}
          title={
            <Link to="/lookout:1">
              Top CCEP Insights ({channel === "ih" ? "In Home" : "Out of Home"})
            </Link>
          }
        />
        <CardContent sx={{ pt: 0 }}>
          {isLookoutData &&
            lookoutData.totalClient &&
            lookoutData.totalClient.insight && (
              <Grid container spacing={2}>
                {lookoutData.totalClient.insight.map((i, k) => (
                  <Grid item md={12} key={k.toString()}>
                    <Insights data={{ ...i, k }} value={1} />
                  </Grid>
                ))}
              </Grid>
            )}
        </CardContent>
      </Card>
    </Fragment>
  );
};

Lookout.propTypes = {
  date: PropTypes.shape(),
  preferences: PropTypes.arrayOf(PropTypes.shape()),
  lookoutData: PropTypes.shape(),
  isLookoutData: PropTypes.bool,
  channel: PropTypes.string
};

Lookout.defaultProps = {
  date: {},
  preferences: [],
  lookoutData: {},
  isLookoutData: false,
  channel: ""
};

export default Lookout;

import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Typography,
  Link,
  FormControl,
  FormLabel,
  Box
} from "@mui/material";
import { helpdesk, feedback } from "../images";

const Support = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "6129b982-3858-40f4-8388-5f1f67a1683f",
          target: "#hubspotSupportForm"
        });
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "f8b0917e-5f0c-44b1-a928-d4d7419ff1f2",
          target: "#hubspotFeedbackForm"
        });
      }
    });
  }, []);
  return (
    <Card className="card">
      <CardHeader
        titleTypographyProps={{
          variant: "h1",
          align: "center"
        }}
        title="Support"
      />
      <Typography variant="body2" gutterBottom align="center" sx={{ px: 15 }}>
        We aim to make Interrodata as intuitive to use as possible - but if you
        are experiencing any problems or just want to find out more, please
        don&apos;t hesitate to get in touch with our team!
      </Typography>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item md={5}>
          <Grid item md={12} container alignItems="center">
            <Box sx={{ mr: 2, mb: 1 }}>
              <img alt="helpdesk" src={helpdesk} justify="center" />
            </Box>
            <Typography variant="h3" gutterBottom>
              Helpdesk
            </Typography>
          </Grid>
          <Typography variant="body2" gutterBottom>
            For all support requests, please fill in the form below or email{" "}
            <Link
              href="mailto:helpdesk@interrodata.com"
              color="primary.dark"
              fontWeight="bold"
            >
              helpdesk@interrodata.com
            </Link>{" "}
            letting us know as much information as possible about what you are
            trying to do and what problem you are experiencing.
          </Typography>
        </Grid>
        <Grid item md={5}>
          <Grid item md={12} container alignItems="center">
            <Box sx={{ mr: 2, mb: 1 }}>
              <img alt="feedback" src={feedback} justify="center" />
            </Box>
            <Typography variant="h3" gutterBottom>
              Feedback
            </Typography>
          </Grid>
          <Typography variant="body2" gutterBottom>
            If you&apos;d like to see give us some feedback or would like us to
            add a new feature, please fill in the form below or email{" "}
            <Link
              href="mailto:features@interrodata.com"
              color="primary.dark"
              fontWeight="bold"
            >
              features@interrodata.com
            </Link>{" "}
            and we will get those things prioritised in discussion with your
            leadership team.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-evenly" spacing={3}>
        <Grid item md={5}>
          <FormControl
            component="fieldset"
            className="fieldset"
            id="hubspotSupportForm"
            sx={{ p: 4, my: 1 }}
            fullWidth
          >
            <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
              <Typography color="textPrimary" fontWeight="bold">
                Ask the Helpdesk
              </Typography>
            </FormLabel>
          </FormControl>
        </Grid>
        <Grid item md={5}>
          <FormControl
            component="fieldset"
            className="fieldset"
            id="hubspotFeedbackForm"
            sx={{ p: 4, my: 1 }}
            fullWidth
          >
            <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
              <Typography color="textPrimary" fontWeight="bold">
                Feedback and Feature Request
              </Typography>
            </FormLabel>
          </FormControl>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        gutterBottom
        align="center"
        color="text.secondary"
        sx={{ px: 10, my: 2 }}
      >
        Interrodata needs the contact information you provide to us to contact
        you about our products and services. You may unsubscribe from these
        communications at any time. For information on how to unsubscribe, as
        well as our privacy practices and commitment to protecting your privacy,
        please review our Privacy Policy.
      </Typography>
    </Card>
  );
};

export default Support;

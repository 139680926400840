import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton, Typography, Stack } from "@mui/material";
import { goToSlide } from "../../actions";
import { PreviousIcon } from "../Icons";

const PresentationNavLeft = props => {
  const { activeSlideIndex, toSlide, report } = props;
  const isFirstSlide = activeSlideIndex <= 0;
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      className="PresentationNavLeft"
    >
      <IconButton
        id="presentation-prev"
        sx={{ "&:hover": { color: "primary.dark" } }}
        onClick={() =>
          !isFirstSlide && toSlide(activeSlideIndex - 1, report, report.length)
        }
        disabled={isFirstSlide}
        size="large"
      >
        <PreviousIcon />
      </IconButton>
      <Typography variant="caption" color="text.secondary">
        Previous Page
      </Typography>
    </Stack>
  );
};

PresentationNavLeft.propTypes = {
  activeSlideIndex: PropTypes.number,
  toSlide: PropTypes.func,
  report: PropTypes.arrayOf(PropTypes.shape())
};

PresentationNavLeft.defaultProps = {
  activeSlideIndex: 0,
  toSlide: () => {},
  report: []
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toSlide: (slideIndex, report, slideCount) => {
    const { reportId, userId, story, client } = ownProps;
    dispatch(
      goToSlide(
        slideIndex,
        report,
        "prev",
        slideCount,
        reportId,
        userId,
        story,
        client
      )
    );
  }
});

export { PresentationNavLeft as PresentationNavLeftComponent };
export default connect(null, mapDispatchToProps)(PresentationNavLeft);

import React from "react";
import { SvgIcon } from "@mui/material";

export const AmbientIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-7.883 -2.528)">
      <g transform="translate(7.883 2.528)">
        <path
          d="M14.4,2.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.3-0.2-0.6-0.2c-0.3,0.1-0.5,0.3-0.4,0.6l0.1,0.2
					c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0.1,0.2,0.1,0.3c0,0.2,0,0.5,0,0.7c-0.1,0.2-0.1,0.5-0.2,0.6l-0.4,0.9c-0.2,0.3-0.2,0.6-0.3,1
					c-0.1,0.4-0.1,0.8-0.1,1.2c0.1,0.8,0.4,1.5,0.9,2.1c0.2,0.2,0.6,0.2,0.8,0.1c0.2-0.2,0.2-0.4,0.2-0.6l0,0c-0.2-0.5-0.3-1-0.2-1.5
					C14,7,14.1,6.4,14.3,6c0.1-0.2,0.2-0.6,0.3-0.9c0.2-0.4,0.2-0.8,0.2-1.2s-0.1-0.8-0.2-1.2C14.5,2.4,14.5,2.2,14.4,2.1z"
        />
        <path
          d="M18.3,2.1c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.3-0.2,0.5L17,2.3
					c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0.1,0.2,0.1,0.3c0,0.2,0,0.5,0,0.7c-0.1,0.2-0.1,0.5-0.2,0.6l-0.4,0.9c-0.2,0.3-0.2,0.6-0.3,1
					S16.1,7,16.1,7.4C16.2,8.2,16.5,9,17,9.5c0.2,0.2,0.6,0.2,0.8,0.1C18,9.4,18.1,9.2,18,9l0,0c-0.2-0.5-0.3-1-0.2-1.5
					c0.1-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.6,0.3-0.9c0.2-0.4,0.2-0.8,0.2-1.2s-0.1-0.8-0.2-1.2C18.5,2.5,18.5,2.2,18.3,2.1L18.3,2.1z"
        />
        <path
          d="M22.5,2.6c-0.1-0.2-0.2-0.3-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.3-0.2-0.6-0.2
					c-0.3,0.1-0.5,0.3-0.4,0.6L21,2.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0.1,0.2,0.1,0.3c0,0.2,0,0.5,0,0.7c-0.1,0.2-0.1,0.5-0.2,0.6
					l-0.4,0.9c-0.2,0.3-0.2,0.6-0.3,1S20.1,7,20.1,7.4C20.2,8.2,20.5,9,21,9.5c0.2,0.2,0.6,0.2,0.8,0.1C22,9.4,22.1,9.2,22,9l0,0
					c-0.2-0.5-0.3-1-0.2-1.5c0.1-0.5,0.2-1,0.4-1.4c0-0.4,0.2-0.7,0.2-1c0.2-0.4,0.2-0.8,0.2-1.2C22.7,3.4,22.6,3,22.5,2.6z"
        />
        <path
          d="M9.8,15V3.4c0-1.6-1.1-3-2.6-3.4c-1.8-0.4-3.6,0.8-4,2.6C3.1,2.9,3.1,3.1,3.1,3.4V15
					c0,0.1-0.1,0.2-0.2,0.2c-1.5,1.4-2,3.7-1.2,5.7c0.6,1.4,1.8,2.6,3.4,3c2.7,0.7,5.5-0.9,6.2-3.6c0.1-0.4,0.2-0.9,0.2-1.3
					c0-1.4-0.6-2.7-1.6-3.7C9.8,15.2,9.8,15,9.8,15z M5.3,22.6c-1-0.3-1.8-1-2.3-1.9C2.1,19,2.6,17,4.1,15.8c0.1-0.1,0.2-0.2,0.2-0.2
					V3.5c0-1.1,0.8-2.1,1.9-2.2C7.4,1.1,8.5,2,8.5,3.2c0,0,0,0.1,0,0.2v12.2c0,0.1,0.1,0.2,0.2,0.2c1.7,1.3,2.1,3.7,0.8,5.4
					C8.5,22.5,6.9,23.1,5.3,22.6L5.3,22.6z"
        />
        <path
          d="M7.3,16.5V5.8c0-0.5-0.4-0.9-1-0.9c-0.5,0-0.9,0.4-0.9,0.9v10.7c-1.4,0.5-2.1,2-1.5,3.4
					c0.6,1.4,2,2.1,3.4,1.5C8.4,20.9,9,20,9,18.9C9,17.8,8.4,16.9,7.3,16.5z"
        />
      </g>
    </g>
  </SvgIcon>
);

export const ChilledIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-34.221 -76.868)">
      <path
        d="M56.6,93.9l-2.5-1.4l2.3-0.6c0.3-0.1,0.4-0.3,0.3-0.6l-0.2-1c-0.1-0.3-0.3-0.4-0.6-0.3l-4.2,1l-3.5-2.1
        l3.5-2.1l4.2,1.1c0.2,0.1,0.5,0,0.6-0.2c0-0.1,0-0.1,0-0.2l0.2-1c0.1-0.2,0-0.5-0.2-0.6c-0.1,0-0.1,0-0.2,0L54,85.4l2.5-1.4
        c0.2-0.1,0.3-0.3,0.2-0.6c0-0.1,0-0.1-0.1-0.1l-0.4-1c-0.1-0.2-0.3-0.3-0.6-0.2c-0.1,0-0.1,0-0.2,0.1l-2.4,1.4l0.6-2.2
        c0.1-0.2,0-0.5-0.2-0.6c-0.1,0-0.1,0-0.2,0l-1-0.2c-0.2-0.1-0.5,0-0.6,0.2c0,0.1,0,0.1,0,0.2l-1,4.1l-3.5,2.1v-4.1l3.1-3.1
        c0.2-0.2,0.2-0.5,0-0.7l-0.7-0.7c-0.2-0.2-0.5-0.2-0.7,0l0,0l-1.5,1.6v-2.8c0-0.2-0.2-0.5-0.4-0.5h-0.1h-1c-0.2,0-0.5,0.2-0.5,0.4
        v0.1v2.8l-1.7-1.7c-0.2-0.2-0.5-0.2-0.7,0l0,0l-0.7,0.7c-0.2,0.2-0.2,0.5,0,0.7l3.1,3.1v4.1L41.6,85l-1-4.2
        c-0.1-0.3-0.3-0.4-0.6-0.3l-1,0.2c-0.3,0.1-0.4,0.3-0.3,0.6l0.6,2.3l-2.3-1.4c-0.2-0.2-0.6-0.1-0.7,0.2l0,0l-0.6,0.9
        c-0.2,0.2-0.1,0.6,0.2,0.7l2.4,1.4l-2.3,0.6c-0.3,0.1-0.4,0.3-0.3,0.6l0.2,1c0.1,0.3,0.3,0.4,0.6,0.3l4.2-1.1l3.5,2.1l-3.6,2.2
        l-4.1-1c-0.2-0.1-0.5,0-0.6,0.2c0,0.1,0,0.1,0,0.2l-0.2,1c-0.1,0.2,0,0.5,0.2,0.6c0.1,0,0.1,0,0.2,0l2.2,0.6l-2.4,1.4
        c-0.2,0.1-0.3,0.3-0.2,0.6c0,0.1,0,0.1,0.1,0.1l0.6,0.7c0.1,0.2,0.3,0.3,0.6,0.2c0.1,0,0.1,0,0.2-0.1l2.5-1.5l-0.6,2.3
        c-0.1,0.2,0,0.5,0.2,0.6c0.1,0,0.1,0,0.2,0l1,0.2c0.2,0.1,0.5,0,0.6-0.2c0-0.1,0-0.1,0-0.2l1-4.3l3.5-2.1v4.2l-3.1,3.1
        c-0.2,0.2-0.2,0.5,0,0.7l0.7,0.7c0.2,0.2,0.5,0.2,0.7,0l0,0l1.7-1.7v2.8c0,0.2,0.2,0.5,0.4,0.5h0.1h1c0.2,0,0.5-0.2,0.5-0.4v-0.1
        v-2.8l1.7,1.7c0.2,0.2,0.5,0.2,0.7,0l0,0l0.7-0.7c0.2-0.2,0.2-0.5,0-0.7l-3.1-3.1v-4.2l3.5,2.1l1.1,4.2c0.1,0.3,0.3,0.4,0.6,0.3
        l1-0.2c0.3-0.1,0.4-0.3,0.3-0.6L53.3,94l2.5,1.4c0.2,0.2,0.6,0.1,0.7-0.2l0.5-0.9C57,94.4,56.9,94.1,56.6,93.9L56.6,93.9L56.6,93.9
        z"
      />
    </g>
  </SvgIcon>
);

export const AmbientChilledIcon = props => (
  <SvgIcon {...props}>
    <g id="Ambient" transform="translate(-7.883 -2.528)">
      <g transform="translate(7.883 2.528)">
        <path
          d="M9.1,2.3C9.1,2.2,9,2.1,8.9,2C8.8,1.9,8.7,1.9,8.5,1.9C8.3,2,8.2,2.1,8.3,2.3l0.1,0.1
					c0.1,0,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.2,0,0.3,0,0.4C8.4,3.5,8.4,3.6,8.3,3.7L8,4.3C7.9,4.5,7.9,4.7,7.8,4.9
					s0,0.5,0,0.8C7.8,6.2,8,6.6,8.3,7c0.2,0.1,0.4,0.1,0.5,0c0.1-0.1,0.1-0.2,0.1-0.4l0,0C8.8,6.3,8.7,6,8.8,5.7C8.9,5.4,8.9,5,9,4.8
					c0.1-0.2,0.1-0.3,0.2-0.6C9.3,4,9.3,3.7,9.3,3.4S9.2,2.9,9.2,2.7S9.1,2.4,9.1,2.3z"
        />
        <path
          d="M11.5,2.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.1-0.1,0.2-0.1,0.3l0.1,0.1
					c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.4c0,0.2,0,0.3-0.1,0.4l-0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.7s-0.1,0.5-0.1,0.8
					c0.1,0.5,0.2,0.9,0.6,1.3c0.1,0.1,0.4,0.1,0.5,0.1c0.1-0.1,0.1-0.2,0.1-0.4l0,0c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.1-0.7,0.2-0.9
					c0.1-0.2,0.1-0.3,0.2-0.6c0.1-0.4,0.2-0.7,0.2-0.9S11.7,3,11.7,2.8C11.7,2.6,11.6,2.4,11.5,2.3L11.5,2.3z"
        />
        <path
          d="M14.1,2.7C14,2.6,14,2.5,14,2.4c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.1
					c-0.2,0.1-0.3,0.2-0.2,0.4l0.1,0.1c0.1,0,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.2,0,0.3,0,0.4c-0.1,0.2-0.1,0.3-0.1,0.4
					l-0.2,0.6c-0.4,0.3-0.4,0.5-0.5,0.7s-0.1,0.5-0.1,0.8c0.1,0.5,0.2,0.9,0.6,1.3c0.1,0.1,0.4,0.1,0.5,0.1c0.1-0.2,0.2-0.3,0.1-0.5
					l0,0c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.1-0.7,0.2-0.9c0-0.2,0.1-0.5,0.1-0.6c0.1-0.2,0.1-0.5,0.1-0.8
					C14.3,3.2,14.2,2.9,14.1,2.7z"
        />
        <path
          d="M6.2,10.4V3.2c0-1-0.7-1.9-1.7-2.1C3.4,0.8,2.3,1.6,2.1,2.7c0,0.1,0,0.2,0,0.4v7.3L2,10.5
					c-0.9,0.9-1.2,2.3-0.8,3.6c0.4,0.9,1.2,1.6,2.1,1.8c1.7,0.5,3.5-0.5,3.9-2.2c0.1-0.2,0.1-0.6,0.1-0.8c0-0.9-0.4-1.7-1-2.3
					C6.2,10.5,6.2,10.4,6.2,10.4z M3.4,15.2C2.8,15,2.2,14.6,1.9,14c-0.5-1.1-0.2-2.4,0.8-3.1l0.1-0.1V3.2c0-0.7,0.5-1.3,1.2-1.4
					S5.4,2.3,5.4,3c0,0,0,0,0,0.1v7.6c0,0.1,0.1,0.1,0.1,0.1c1,0.8,1.3,2.3,0.5,3.4C5.4,15.1,4.4,15.5,3.4,15.2L3.4,15.2z"
        />
        <path
          d="M4.7,11.3V4.6C4.7,4.3,4.5,4,4.1,4C3.8,4.1,3.5,4.3,3.5,4.6v6.7c-0.8,0.3-1.3,1.2-1,2.1s1.3,1.3,2.1,0.9
					c0.7-0.2,1.1-0.9,1.1-1.6C5.7,12.2,5.3,11.6,4.7,11.3z"
        />
      </g>
    </g>
    <g id="Chilled" transform="translate(-34.221 -76.868)">
      <path
        d="M56.9,95.7l-1.5-0.9l1.4-0.4C57,94.3,57,94.2,57,94l-0.2-0.6c0-0.2-0.2-0.2-0.3-0.2l-2.6,0.7l-2.2-1.3
				l2.2-1.3l2.6,0.7c0.1,0.1,0.3,0,0.3-0.2c0,0,0,0,0-0.1l0.2-0.6c0-0.2,0-0.3-0.2-0.3c0,0,0,0-0.1,0l-1.5-0.3l1.5-0.9
				c0.1,0,0.2-0.2,0.1-0.3l0,0l-0.2-0.7c0-0.2-0.2-0.2-0.3-0.2c0,0,0,0-0.1,0l-1.5,0.9l0.4-1.3c0-0.2,0-0.3-0.2-0.3c0,0,0,0-0.1,0
				l-0.6-0.2c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.1,0,0.1l-0.7,2.6L51,91.7v-2.5l1.9-1.9c0.1-0.1,0.1-0.3,0-0.5l-0.5-0.4
				c-0.1-0.1-0.3-0.1-0.5,0l0,0l-0.9,1v-1.8c0-0.2-0.1-0.3-0.2-0.3l0,0h-0.7c-0.1,0-0.3,0.1-0.3,0.2l0,0v1.8l-1-1
				c-0.1-0.1-0.3-0.1-0.5,0l0,0l-0.4,0.3c-0.1,0.1-0.1,0.3,0,0.5l1.9,1.9v2.5l-2.2-1.3l-0.7-2.6c0-0.2-0.2-0.2-0.3-0.2L46,87.6
				c-0.2,0-0.2,0.2-0.2,0.4l0.4,1.4l-1.5-0.8c-0.2-0.1-0.4-0.1-0.5,0.1l0,0l-0.3,0.4c-0.1,0.2,0,0.3,0.1,0.5l1.5,0.9L44,90.9
				c-0.2,0-0.2,0.2-0.2,0.4l0.1,0.6c0,0.2,0.2,0.2,0.4,0.2l2.6-0.7l2.2,1.3L46.9,94l-2.5-0.7c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.1,0,0.1
				l-0.2,0.6c0,0.2,0,0.3,0.2,0.3c0,0,0,0,0.1,0l1.4,0.3l-1.5,0.9c-0.2,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0,0.1l0.4,0.4
				c0,0.2,0.2,0.2,0.4,0.2c0,0,0,0,0.1,0l1.5-1L46,97.2c0,0.2,0,0.3,0.1,0.3c0,0,0,0,0.1,0l0.6,0.2c0.2,0,0.3,0,0.4-0.2
				c0-0.1,0-0.1,0-0.1l0.6-2.7l2.2-1.3V96l-1.9,2c-0.1,0.1-0.1,0.3,0,0.4l0.5,0.5c0.1,0.1,0.3,0.1,0.5,0l0,0l1-1.1v1.8
				c0,0.2,0.1,0.3,0.2,0.3l0,0h0.6c0.2,0,0.3-0.1,0.3-0.2v-0.1v-1.8l1,1.1c0.1,0.1,0.3,0.1,0.5,0l0,0l0.5-0.5c0.1-0.1,0.1-0.3,0-0.4
				l-1.9-1.9v-2.6l2.1,1.3l0.7,2.6c0,0.2,0.2,0.2,0.4,0.2l0.6-0.2c0.2-0.1,0.2-0.2,0.2-0.4l-0.4-1.2l1.5,0.9c0.1,0.1,0.3,0,0.5-0.1
				l0.3-0.6C57.2,96,57.1,95.8,56.9,95.7L56.9,95.7L56.9,95.7z"
      />
    </g>
  </SvgIcon>
);

export const ICIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(5.846 3.221)">
      <path
        d="M5.7-3.2c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3S5.4-2.8,5.4-2.9C5.4-3.1,5.5-3.2,5.7-3.2L5.7-3.2z
				M6.4-2.5c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1l0,0C5.4-2,5.9-2.5,6.4-2.5z M6.7-1.9c-0.1,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3
				c0.1,0,0.3-0.1,0.3-0.3S6.8-1.9,6.7-1.9L6.7-1.9z M7.5,0.4c0.2,0,0.4,0.1,0.4,0.4c0,0.1-0.1,0.3-0.2,0.4C7.7,1.2,8,1.4,8,1.6
				S7.7,1.9,7.7,2.1l0,0H4.6l0,0c0-0.3-0.3-0.3-0.3-0.5s0.2-0.4,0.3-0.5C4.5,1,4.3,0.9,4.3,0.7c0.1-0.2,0.2-0.4,0.6-0.4H7.5
				C7.4,0.4,7.5,0.4,7.5,0.4L7.5,0.4z M7.7,2.7c0.1,2,1.1,4.7,1.5,6.5v0.4H3.1V9.2c0.5-1.9,1.4-4.6,1.5-6.5H7.7z M6.4,3.9
				C6.2,3.9,6.1,4,6.1,4.2s0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3C6.7,4,6.5,3.9,6.4,3.9L6.4,3.9z M5.9,5C5.6,5,5.4,5.2,5.4,5.5
				C5.4,5.8,5.6,6,5.9,6c0.3,0,0.5-0.2,0.5-0.5l0,0C6.4,5.2,6.2,5,5.9,5L5.9,5z M9.2,10.1c0,1.4,0,2.7-0.1,4.1H3.4
				c-0.1-1.4-0.3-2.7-0.3-4.1H9.2z M9,14.7c-0.1,0.5-0.1,1.1-0.3,1.9c0,0.5,0.8,1.5,0.8,2.5c0,0.6-0.4,1.2-1,1.5
				c-1.5,0.3-3.1,0.3-4.6,0c-0.6-0.3-1-0.9-1-1.5c0-1,0.8-2,0.8-2.5c-0.1-0.8-0.1-1.4-0.1-1.9H9z"
      />
    </g>
  </SvgIcon>
);

export const FCIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-2.048 -12)">
      <path
        d="M23,36H5.2c-1.1,0-2-0.9-2-2v-6.7c0-0.4,0.4-0.8,0.8-0.8h20.3c0.2,0,0.4,0.1,0.6,0.3
				c0.1,0.1,0.2,0.4,0.2,0.6V34C25.1,35.1,24.1,36,23,36z M10.8,28.8c-0.4,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h6.6
				c0.4,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H10.8z"
      />
      <path
        d="M7.1,12c0.1,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3S6.9,12,7.1,12L7.1,12z M7.8,12.8
				c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1s-1-0.4-1-1l0,0C6.8,13.2,7.3,12.8,7.8,12.8z M8.1,13.3c-0.1,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3
				c0.1,0,0.3-0.1,0.3-0.3S8.3,13.3,8.1,13.3L8.1,13.3z M9,15.5c0.2,0,0.4,0.1,0.4,0.4c0,0.1-0.1,0.3-0.2,0.4c0.1,0.1,0.3,0.2,0.3,0.5
				S9.1,17,9.1,17.2l0,0h-3l0,0c0-0.3-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5C6,16.2,5.8,16,5.8,15.9c0-0.2,0.1-0.4,0.5-0.4h2.5
				C8.8,15.5,8.9,15.5,9,15.5L9,15.5z M9.1,17.8c0.1,2,1.1,4.7,1.5,6.4v0.4H4.5v-0.4C5,22.4,5.9,19.7,6,17.8H9.1z M7.8,19.1
				c-0.1,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3C8,19.1,8,19.1,7.8,19.1L7.8,19.1z M7.3,20c-0.3,0-0.5,0.2-0.5,0.5
				c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5l0,0C7.8,20.3,7.6,20,7.3,20L7.3,20z"
      />
      <path
        d="M14.1,12c0.1,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3c-0.1,0-0.3-0.1-0.3-0.3S14,12,14.1,12L14.1,12z
				M14.9,12.8c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1l0,0C13.9,13.2,14.3,12.8,14.9,12.8z M15.2,13.3
				c-0.1,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.3-0.3S15.3,13.3,15.2,13.3L15.2,13.3z M16,15.5c0.2,0,0.4,0.1,0.4,0.4
				c0,0.1-0.1,0.3-0.2,0.4c0.1,0.1,0.3,0.2,0.3,0.5s-0.3,0.3-0.3,0.5l0,0h-3l0,0c0-0.3-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5
				c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.2,0.1-0.4,0.5-0.4h2.5C15.9,15.5,16,15.5,16,15.5L16,15.5z M16.2,17.8c0.1,2,1.1,4.7,1.5,6.4v0.4h-6
				v-0.4c0.5-1.8,1.3-4.5,1.5-6.4H16.2z M14.9,19.1c-0.1,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3
				C15.1,19.1,15,19.1,14.9,19.1L14.9,19.1z M14.4,20c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5l0,0
				C14.9,20.3,14.7,20,14.4,20L14.4,20z"
      />
      <path
        d="M20.5,12c0.1,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3S20.3,12,20.5,12L20.5,12z M21.2,12.8
				c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1l0,0C20.3,13.2,20.7,12.8,21.2,12.8z M21.5,13.3c-0.1,0-0.3,0.1-0.3,0.3
				s0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3S21.7,13.3,21.5,13.3L21.5,13.3z M22.4,15.5c0.2,0,0.4,0.1,0.4,0.4c0,0.1-0.1,0.3-0.2,0.4
				c0.1,0.1,0.3,0.2,0.3,0.5s-0.3,0.3-0.3,0.5l0,0h-3l0,0c0-0.3-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5c-0.1-0.1-0.3-0.2-0.3-0.4
				c0-0.2,0.1-0.4,0.5-0.4h2.5C22.2,15.5,22.3,15.5,22.4,15.5L22.4,15.5z M22.5,17.8c0.1,2,1.1,4.7,1.5,6.4v0.4h-6v-0.4
				c0.5-1.8,1.3-4.5,1.5-6.4H22.5z M21.2,19.1c-0.1,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3C21.5,19.1,21.4,19.1,21.2,19.1
				L21.2,19.1z M20.8,20c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5l0,0C21.2,20.3,21,20,20.8,20L20.8,20z"
      />
    </g>
  </SvgIcon>
);

export const ICFCIcon = props => (
  <SvgIcon {...props}>
    <g id="IC" transform="translate(5.846 3.221)">
      <path
        d="M14.5,3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2S14.4,3,14.5,3L14.5,3z M15,3.5
				c0.4,0,0.7,0.3,0.7,0.7S15.4,4.9,15,4.9s-0.7-0.3-0.7-0.7l0,0C14.3,3.8,14.6,3.5,15,3.5z M15.2,3.9C15.1,3.9,15,4,15,4.1
				c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2C15.4,4,15.3,3.9,15.2,3.9L15.2,3.9z M15.8,5.5c0.2,0,0.3,0.1,0.3,0.3
				c0,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.4s-0.2,0.2-0.2,0.4l0,0h-2.2l0,0c0-0.2-0.2-0.2-0.2-0.4s0.2-0.3,0.2-0.4
				c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.2,0.2-0.3,0.4-0.3H15.8C15.7,5.5,15.8,5.5,15.8,5.5L15.8,5.5z M15.9,7.1c0.1,1.4,0.8,3.3,1.1,4.6
				V12h-4.3v-0.3c0.4-1.3,1-3.2,1.1-4.6H15.9z M15,7.9c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2S15.1,7.9,15,7.9
				L15,7.9z M14.6,8.7c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4l0,0C15,8.8,14.8,8.7,14.6,8.7L14.6,8.7z
				M16.9,12.2c0,1,0,1.9-0.1,2.9h-4c-0.1-1-0.2-1.9-0.2-2.9H16.9z M16.8,15.4c-0.1,0.4-0.1,0.8-0.2,1.3c0,0.4,0.6,1.1,0.6,1.8
				c0,0.5-0.3,0.9-0.7,1.1c-1.1,0.2-2.2,0.2-3.2,0c-0.4-0.2-0.7-0.6-0.7-1.1c0-0.7,0.6-1.4,0.6-1.8c-0.1-0.6-0.1-1-0.1-1.3H16.8z"
      />
    </g>
    <g id="FC" transform="translate(-2.048 -12)">
      <path
        d="M17.1,30H4.5c-0.8,0-1.4-0.6-1.4-1.5v-4.8c-0.1-0.2,0.2-0.5,0.5-0.5H18c0.1,0,0.3,0,0.4,0.2
				c0.1,0.1,0.1,0.2,0.1,0.4v4.8C18.6,29.4,17.9,30,17.1,30z M8.5,24.9c-0.2,0-0.4,0.2-0.4,0.5s0.2,0.5,0.4,0.5h4.7
				c0.2,0,0.4-0.2,0.4-0.5s-0.2-0.5-0.4-0.5H8.5z"
      />
      <path
        d="M5.8,13C5.9,13,6,13.1,6,13.2s-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2S5.7,13,5.8,13L5.8,13z M6.4,13.6
				c0.4,0,0.7,0.3,0.7,0.7S6.8,15,6.4,15s-0.7-0.3-0.7-0.7l0,0C5.7,13.9,6,13.6,6.4,13.6z M6.6,13.9c-0.1,0-0.2,0.1-0.2,0.2
				c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2C6.8,14,6.7,13.9,6.6,13.9L6.6,13.9z M7.2,15.5c0.2,0,0.2,0.1,0.2,0.2s-0.1,0.2-0.1,0.2
				C7.4,16,7.5,16,7.5,16.3s-0.2,0.2-0.2,0.4l0,0H5.1l0,0c0-0.2-0.2-0.2-0.2-0.4s0.1-0.2,0.2-0.4c-0.1-0.1-0.2-0.1-0.2-0.2
				s0.1-0.2,0.4-0.2H7.2C7.1,15.5,7.1,15.5,7.2,15.5L7.2,15.5z M7.3,17.1c0.1,1.4,0.8,3.3,1.1,4.6v0.2H4v-0.2c0.3-1.3,0.9-3.2,1-4.6
				H7.3z M6.4,18c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2C6.5,18.1,6.5,18,6.4,18L6.4,18z M6,18.7
				c-0.2,0-0.3,0.1-0.3,0.4c0,0.2,0.2,0.4,0.3,0.4s0.3-0.1,0.3-0.4l0,0C6.4,18.9,6.2,18.7,6,18.7L6,18.7z"
      />
      <path
        d="M10.8,13c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2C10.6,13.1,10.7,13,10.8,13
				L10.8,13z M11.4,13.6c0.4,0,0.7,0.3,0.7,0.7S11.8,15,11.4,15s-0.7-0.3-0.7-0.7l0,0C10.7,13.9,11,13.6,11.4,13.6z M11.6,13.9
				c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2S11.7,13.9,11.6,13.9L11.6,13.9z M12.2,15.5
				c0.1,0,0.2,0.1,0.2,0.2s-0.1,0.2-0.1,0.2c0.1,0.1,0.2,0.1,0.2,0.4s-0.2,0.2-0.2,0.4l0,0h-2.1l0,0c0-0.2-0.2-0.2-0.2-0.4
				s0.1-0.2,0.2-0.4c-0.1-0.1-0.2-0.1-0.2-0.2s0.1-0.2,0.4-0.2H12.2C12.1,15.5,12.1,15.5,12.2,15.5L12.2,15.5z M12.3,17.1
				c0.1,1.4,0.8,3.3,1.1,4.6v0.2H9.2v-0.2c0.3-1.3,0.9-3.2,1.1-4.6H12.3z M11.4,18c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
				c0.1,0,0.2-0.1,0.2-0.2C11.5,18.1,11.5,18,11.4,18L11.4,18z M11,18.7c-0.2,0-0.4,0.1-0.4,0.4c0,0.2,0.1,0.4,0.4,0.4
				s0.4-0.1,0.4-0.4l0,0C11.4,18.9,11.2,18.7,11,18.7L11,18.7z"
      />
      <path
        d="M15.3,13c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2C15.1,13.1,15.2,13,15.3,13L15.3,13
				z M15.9,13.6c0.4,0,0.7,0.3,0.7,0.7S16.3,15,15.9,15s-0.7-0.3-0.7-0.7l0,0C15.2,13.9,15.5,13.6,15.9,13.6z M16.1,13.9
				c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2C16.3,14,16.2,13.9,16.1,13.9L16.1,13.9z M16.7,15.5
				c0.1,0,0.2,0.1,0.2,0.2s-0.1,0.2-0.1,0.2C16.9,16,17,16,17,16.3s-0.2,0.2-0.2,0.4l0,0h-2.1l0,0c0-0.2-0.2-0.2-0.2-0.4
				s0.1-0.2,0.2-0.4c-0.1-0.1-0.2-0.1-0.2-0.2s0.1-0.2,0.4-0.2H16.7C16.6,15.5,16.6,15.5,16.7,15.5L16.7,15.5z M16.8,17.1
				c0.1,1.4,0.8,3.3,1,4.6v0.2h-4.2v-0.2c0.4-1.3,0.9-3.2,1.1-4.6H16.8z M15.9,18c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
				s0.2-0.1,0.2-0.2C16,18.1,16,18,15.9,18L15.9,18z M15.5,18.7c-0.2,0-0.4,0.1-0.4,0.4c0,0.2,0.1,0.4,0.4,0.4s0.4-0.1,0.4-0.4l0,0
				C15.9,18.9,15.7,18.7,15.5,18.7L15.5,18.7z"
      />
    </g>
  </SvgIcon>
);

export const DownloadPPTIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-8.674 -5.918)">
      <path
        d="M15.9,5.9c-0.5,0-1,0.5-1,1v6.9h8.5c0.9,0,1.5,0.6,1.5,1.5v7.5c0,0.9-0.6,1.5-1.5,1.5h-8.5v4.5
        c0,0.5,0.5,1,1,1h15c0.5,0,1-0.5,1-1V13h-6.4c-0.3,0-0.5-0.3-0.5-0.5V5.9C24.8,5.9,15.9,5.9,15.9,5.9z M25.9,6.3V12h5.4L25.9,6.3z
        M9.9,14.9c-0.3,0-0.5,0.3-0.5,0.5v7.5c0,0.3,0.3,0.5,0.5,0.5h13.5c0.3,0,0.5-0.3,0.5-0.5v-7.5c0-0.3-0.3-0.5-0.5-0.5H9.9z
        M11.3,17.2h1.4c0.4,0,0.6,0,1,0.1c0.3,0.1,0.5,0.3,0.6,0.4c0.1,0.3,0.3,0.5,0.3,0.9c0,0.3,0,0.5-0.1,0.6c-0.1,0.1-0.3,0.3-0.4,0.4
        c-0.1,0.1-0.3,0.1-0.5,0.3c-0.3,0-0.6,0.1-0.9,0h-0.5v1.6h-0.9L11.3,17.2z M15.4,17.2h1.4c0.4,0,0.6,0,1,0.1
        c0.3,0.1,0.5,0.3,0.6,0.4c0.1,0.3,0.3,0.5,0.3,0.9c0,0.3,0,0.5-0.1,0.6s-0.3,0.3-0.4,0.4c-0.1,0.1-0.3,0.1-0.5,0.3
        c-0.3,0-0.6,0.1-0.9,0h-0.5v1.6h-0.9L15.4,17.2z M19,17.2h3.4v0.8h-1.3v3.5h-0.9v-3.5H19V17.2z M12.2,17.9v1.3h0.5
        c0.3,0,0.5,0,0.6-0.1c0.1,0,0.3-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4s0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.3c-0.3,0-0.4,0-0.6,0
        L12.2,17.9L12.2,17.9z M16.3,17.9v1.3h0.5c0.3,0,0.5,0,0.6-0.1c0.3-0.1,0.4-0.3,0.4-0.5c0-0.1,0-0.3-0.1-0.4s-0.3-0.1-0.4-0.3
        c-0.3,0-0.4,0-0.6,0H16.3z"
      />
    </g>
  </SvgIcon>
);

export const ReportsReviewsIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-1.601 .602)">
      <g transform="translate(5.751 1.425)">
        <path
          d="M16.6-2H2.8C2-2,1.4-1.4,1.4-0.7v2.2h-2.2c-0.7,0-1.3,0.5-1.3,1.3v17.8c0,0.7,0.6,1.3,1.3,1.3H13
					c0.7,0,1.3-0.6,1.3-1.3v-2.2h2.2c0.7,0,1.3-0.6,1.3-1.3V-0.7C18-1.4,17.4-2,16.6-2z M13.3,20.5c0,0.1-0.1,0.2-0.2,0.2l0,0H-0.9
					c-0.1,0-0.2-0.1-0.2-0.2l0,0V2.9c0-0.1,0.1-0.2,0.2-0.2l0,0h2.2v14.4c0,0.7,0.6,1.3,1.3,1.3h10.5v2.1H13.3z M16.8,17.1
					c0,0.1-0.1,0.2-0.3,0.2l0,0H2.8c-0.1,0-0.3-0.1-0.3-0.2l0,0V-0.7c0-0.1,0.1-0.2,0.3-0.2l0,0h13.8c0.1,0,0.3,0.1,0.3,0.2l0,0
					L16.8,17.1L16.8,17.1z"
        />
        <path
          d="M13.4,10.8h-7c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5
					C13.9,11,13.7,10.8,13.4,10.8L13.4,10.8z"
        />
        <path
          d="M13.4,14.1h-7c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5
					C13.9,14.3,13.7,14.1,13.4,14.1z"
        />
        <path
          d="M13.4,7.5h-7C6.1,7.5,5.9,7.7,5.9,8c0,0.3,0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5
					C13.9,7.7,13.7,7.5,13.4,7.5C13.5,7.5,13.4,7.5,13.4,7.5z"
        />
        <path
          d="M6.6,5.2h0.8c0.2,0,0.2-0.1,0.2-0.1l0,0V3.7c0-0.1-0.1-0.2-0.2-0.2l0,0H6.6c-0.1,0-0.2,0.1-0.2,0.2l0,0V5
					C6.5,5.1,6.5,5.2,6.6,5.2z"
        />
        <path
          d="M9.1,5.1h0.8c0.1,0,0.2-0.1,0.2-0.2l0,0V2.5c0-0.1-0.1-0.2-0.2-0.2l0,0H9.1C9,2.3,8.9,2.4,8.9,2.5l0,0v2.3
					C8.9,5,9,5.1,9.1,5.1z"
        />
        <path
          d="M11.7,4.5h0.8c0.1,0,0.2-0.1,0.2-0.2l0,0V1c0-0.1-0.1-0.2-0.2-0.2h-0.8c-0.1,0-0.2,0.1-0.2,0.2l0,0v3.3
					C11.5,4.4,11.6,4.5,11.7,4.5z"
        />
      </g>
    </g>
  </SvgIcon>
);

export const PerformanceIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M2.4,4.8H0v16.8C0,22.9,1.1,24,2.4,24l0,0h16.8v-2.4H2.4V4.8z M21.6,0H7.2
      C5.9,0,4.8,1.1,4.8,2.4v14.4c0,1.3,1.1,2.4,2.4,2.4l0,0h14.4c1.3,0,2.4-1.1,2.4-2.4l0,0V2.4C24,1.1,22.9,0,21.6,0L21.6,0z
      M21.6,16.8H7.2V2.4h14.4V16.8z M9.6,8.3h9.6v2.4H9.6V8.3z M9.6,12h4.8v2.4H9.6V12z M9.6,4.8h9.6v2.4H9.6V4.8z"
    />
  </SvgIcon>
);

export const RangeReviewIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path
        d="M12.1,21.6H2.7v-9.4c0-0.6-0.6-1.1-1.3-1.1s-1.1,0.6-1.1,1.1v10.5c0,0.7,0.6,1.3,1.1,1.3h10.5
				c0.7,0,1.3-0.6,1.3-1.1C13.2,22.2,12.6,21.6,12.1,21.6L12.1,21.6L12.1,21.6z"
      />
      <path
        d="M22.6,0H12.1c-0.7,0-1.3,0.6-1.3,1.1s0.6,1.3,1.3,1.3h9.4v9.4c0,0.7,0.6,1.1,1.1,1.1
				c0.7,0,1.1-0.6,1.1-1.1l0,0V1.1C23.7,0.6,23.2,0,22.6,0z"
      />
      <path
        d="M17.8,6.1H6.2c-0.7,0-1.1,0.6-1.1,1.1c0,0.7,0.6,1.1,1.1,1.1h11.6c0.7,0,1.1-0.6,1.1-1.1
				C19.1,6.5,18.6,6.1,17.8,6.1L17.8,6.1z"
      />
      <path
        d="M17.8,10.9H6.2c-0.7,0-1.1,0.6-1.1,1.1c0,0.7,0.6,1.1,1.1,1.1h11.6c0.7,0,1.1-0.6,1.1-1.1
				C19.1,11.3,18.6,10.9,17.8,10.9L17.8,10.9z"
      />
      <path
        d="M17.8,15.7H6.2c-0.7,0-1.1,0.6-1.1,1.1c0,0.7,0.6,1.1,1.1,1.1l0,0h11.6c0.7,0,1.1-0.6,1.1-1.1
				C19.1,16.2,18.6,15.7,17.8,15.7L17.8,15.7z"
      />
    </g>
  </SvgIcon>
);

export const BrandBattlesIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(0 5)">
      <path
        d="M18.6,11.3c-3,0-5.4-2.4-5.4-5.4l0,0c0-0.4,0.3-0.8,0.8-0.8h9.3c0.4,0,0.8,0.3,0.8,0.8
        C24,8.8,21.6,11.3,18.6,11.3L18.6,11.3L18.6,11.3z M14.8,6.6c0.4,2.1,2.5,3.4,4.6,3c1.5-0.3,2.7-1.5,3-3H14.8z"
      />
      <path
        d="M5.4,15.1c-3,0-5.4-2.4-5.4-5.4c0,0,0,0,0,0c0-0.4,0.3-0.8,0.8-0.8h9.3c0.4,0,0.8,0.3,0.8,0.8
        C10.8,12.7,8.4,15.1,5.4,15.1z M1.6,10.5c0.4,2.1,2.5,3.4,4.6,3c1.5-0.3,2.7-1.5,3-3H1.6z"
      />
      <path
        d="M23.8,5.4l-4.6-6.2c-0.2-0.3-0.5-0.4-0.8-0.3L5.2,2.8c0,0-0.1,0-0.1,0.1h0C5,2.8,4.9,2.9,4.9,3L4.8,3.1
        L0.2,9.3c-0.3,0.3-0.2,0.8,0.2,1.1c0.3,0.3,0.8,0.2,1.1-0.2l0,0l4-5.4l4,5.4c0.3,0.3,0.7,0.4,1.1,0.2c0.3-0.3,0.4-0.7,0.2-1.1
        L6.7,4l9.9-2.9l-3.2,4.3c-0.3,0.3-0.2,0.8,0.2,1.1c0.3,0.3,0.8,0.2,1.1-0.2l0,0l4-5.4l4,5.4c0.3,0.3,0.7,0.4,1.1,0.2
        C24,6.2,24.1,5.7,23.8,5.4L23.8,5.4L23.8,5.4z"
      />
    </g>
  </SvgIcon>
);

export const EditIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-7.199 -7.253)">
      <path
        d="M11.5,30.7c-2.1,0-3.7-1.7-3.7-3.7V13.5c0-2.1,1.7-3.7,3.7-3.7h4.3c0.6,0,1.1,0.5,1.1,1.1
        c0,0.6-0.5,1.1-1.1,1.1h-4.3c-0.9,0-1.6,0.7-1.6,1.6l0,0V27c0,0.9,0.7,1.6,1.6,1.6H25c0.9,0,1.6-0.7,1.6-1.6c0,0,0,0,0,0v-4.3
        c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1V27c0,2.1-1.7,3.7-3.7,3.7H11.5z M14.5,23.6c-0.3-0.4-0.4-0.9-0.3-1.4l0.9-3
        c0-0.2,0.1-0.3,0.3-0.5l10-10c1.2-1.2,3.2-1.2,4.4,0c1.2,1.2,1.2,3.2,0,4.4l-10,10c-0.1,0.1-0.3,0.2-0.5,0.3l-3.1,0.9l-0.4,0.1l0,0
        C15.3,24.3,14.8,24,14.5,23.6z M17.1,20.1l-0.5,1.8l1.9-0.5l8.3-8.3l-1.3-1.3L17.1,20.1z M28.2,11.6L28.2,11.6c0.4-0.4,0.4-1,0-1.3
        c-0.4-0.4-1-0.4-1.3,0h0L28.2,11.6z"
      />
    </g>
  </SvgIcon>
);

export const PreviousIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12.01,0C18.64,0.01,24.01,5.39,24,12.01S18.61,24.01,11.99,24C5.37,23.99,0.01,18.63,0,12.01C-0.01,5.39,5.36,0.01,11.99,0
      C12,0,12,0,12.01,0z M12.01,21.88c5.46,0,9.89-4.43,9.89-9.89s-4.43-9.89-9.89-9.89s-9.89,4.43-9.89,9.89
      C2.13,17.45,6.55,21.87,12.01,21.88z M8.35,12.01l4.96,4.96l1.51-1.51l-3.47-3.45l3.45-3.47l-1.51-1.51L8.35,12.01z"
    />
  </SvgIcon>
);

export const NextIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M11.99,24C5.36,23.99-0.01,18.61,0,11.99C0.01,5.36,5.39-0.01,12.01,0C18.63,0.01,23.99,5.37,24,11.99
      c0.01,6.63-5.36,12.01-11.99,12.01C12,24,12,24,11.99,24z M11.99,2.12c-5.46,0-9.89,4.43-9.89,9.89s4.43,9.89,9.89,9.89
      s9.89-4.43,9.89-9.89C21.87,6.55,17.45,2.13,11.99,2.12z M15.65,11.99l-4.96-4.96L9.18,8.54l3.47,3.45L9.2,15.46l1.51,1.51
      L15.65,11.99z"
    />
  </SvgIcon>
);

export const DownloadIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-4 -968.362)">
      <path
        d="M15.95,971.52c-3.47-0.02-6.47,2.45-7.12,5.86c-3.23,0.62-5.35,3.75-4.73,6.98c0.54,2.81,3,4.84,5.86,4.84h12.74
        c2.86,0.06,5.23-2.21,5.29-5.07c0.06-2.76-2.06-5.08-4.82-5.28c0.07-3.98-3.1-7.26-7.07-7.33
        C16.06,971.52,16.01,971.52,15.95,971.52z M15.95,973.08c1.51-0.01,2.97,0.59,4.04,1.67c1.25,1.24,1.85,2.99,1.64,4.74
        c-0.05,0.43,0.25,0.82,0.68,0.87c0.03,0,0.06,0.01,0.09,0.01h0.31c2.01,0.07,3.58,1.75,3.52,3.76c-0.06,1.92-1.6,3.45-3.52,3.52
        H9.97c-2.44,0-4.42-1.98-4.42-4.42c0-2.28,1.74-4.19,4.01-4.4c0.36-0.04,0.65-0.32,0.7-0.68C10.6,975.25,13.05,973.07,15.95,973.08
        z M15.95,977.77c-0.43,0-0.78,0.35-0.78,0.78l0,0v5l-1.55-1.42c-0.32-0.27-0.81-0.25-1.11,0.05c-0.29,0.32-0.27,0.81,0.05,1.11
        l2.86,2.6c0.14,0.14,0.33,0.21,0.53,0.2c0.2,0.02,0.39-0.06,0.53-0.2l2.86-2.6c0.32-0.29,0.34-0.79,0.05-1.11
        c-0.3-0.29-0.78-0.31-1.11-0.05l-1.55,1.41v-5C16.73,978.11,16.38,977.76,15.95,977.77L15.95,977.77z"
      />
    </g>
  </SvgIcon>
);

export const SettingsIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-5.359 -4.57)">
      <path
        d="M28.5,14.59c0.66-0.49,0.85-1.4,0.43-2.11l-2.31-3.89c-0.4-0.74-1.33-1.01-2.07-0.61l-2.03,0.94
        c-0.36-0.24-0.73-0.45-1.11-0.64l-0.21-2.27L21.2,5.9c-0.14-0.76-0.79-1.31-1.56-1.33h-4.51c-0.83,0-1.52,0.64-1.58,1.46l-0.2,2.24
        c-0.38,0.19-0.76,0.4-1.11,0.64l-2.05-0.94l-0.05-0.02c-0.74-0.3-1.59-0.01-2,0.68l-2.24,3.84c-0.42,0.71-0.23,1.62,0.43,2.11
        l1.86,1.32c-0.02,0.22-0.03,0.44-0.03,0.66c-0.01,0.21,0,0.43,0.03,0.64l-1.92,1.32l-0.04,0.03c-0.66,0.49-0.85,1.4-0.43,2.11
        l2.26,3.89c0.19,0.33,0.5,0.59,0.86,0.72c0.4,0.13,0.83,0.09,1.2-0.11l2.04-0.93c0.35,0.24,0.72,0.45,1.1,0.64l0.21,2.27l0.02,0.11
        c0.14,0.75,0.79,1.31,1.56,1.32h4.5c0.83,0,1.52-0.64,1.58-1.46l0.2-2.23c0.38-0.19,0.76-0.4,1.11-0.64l2.06,0.95l0.05,0.02
        c0.74,0.3,1.59,0.01,2-0.68l2.23-3.84c0.42-0.71,0.24-1.62-0.42-2.11l-1.72-1.31c0.02-0.23,0.03-0.45,0.03-0.68
        c0-0.22-0.01-0.44-0.03-0.66l1.85-1.32L28.5,14.59z M24.37,14.93l0.09,0.64c0.06,0.33,0.09,0.67,0.09,1
        c-0.01,0.34-0.04,0.67-0.09,1l-0.09,0.62l2.36,1.79l-1.79,3.08l-2.79-1.29l-0.51,0.42c-0.53,0.41-1.12,0.75-1.74,1l-0.57,0.24
        l-0.26,3.02h-3.56l-0.26-3.02l-0.57-0.25c-0.63-0.27-1.23-0.61-1.77-1.03l-0.5-0.38l-2.77,1.28l-1.78-3.06l2.07-1.41l0.54-0.38
        l-0.09-0.65c-0.06-0.33-0.09-0.67-0.1-1c0.01-0.34,0.04-0.67,0.09-1l0.09-0.64l-2.51-1.79l1.78-3.06l2.79,1.28l0.51-0.42
        c0.53-0.41,1.11-0.75,1.74-1l0.58-0.24l0.26-3.02h3.55l0.26,3.02l0.57,0.25c0.63,0.27,1.23,0.61,1.77,1.03l0.5,0.38l2.76-1.27
        l1.83,3.05L24.37,14.93z"
      />
      <path
        d="M17.36,12.03c-2.51,0-4.54,2.03-4.54,4.54c0,2.51,2.03,4.54,4.54,4.54c2.51,0,4.54-2.03,4.54-4.54
        c0.03-2.48-1.96-4.51-4.44-4.54C17.42,12.03,17.39,12.03,17.36,12.03z M17.36,19c-1.34,0-2.43-1.09-2.43-2.43s1.09-2.43,2.43-2.43
        s2.43,1.09,2.43,2.43c0.01,1.33-1.05,2.41-2.37,2.43C17.39,19,17.38,19,17.36,19z"
      />
    </g>
  </SvgIcon>
);

export const LogoutIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(29.703 33.652) rotate(180)">
      <path
        d="M29.7,21.65c0,0.03,0,0.06,0,0.08c-0.01,0.16-0.06,0.31-0.13,0.45c0,0.01-0.01,0.02-0.02,0.03c-0.08,0.1-0.16,0.2-0.25,0.3
        l-0.01,0.01c-0.12,0.12-0.27,0.28-0.46,0.47l-2.94,2.94c-0.44,0.44-1.16,0.44-1.6,0s-0.44-1.16,0-1.6c0,0,0.75-0.75,1.55-1.55
        H15.57c-0.63,0-1.13-0.51-1.13-1.13s0.51-1.13,1.13-1.13h10.26l-1.55-1.55c-0.46-0.42-0.49-1.14-0.07-1.6
        c0.42-0.46,1.14-0.49,1.6-0.07c0.03,0.02,0.05,0.05,0.07,0.07l3.48,3.48C29.58,21.06,29.7,21.35,29.7,21.65z"
      />
      <path
        d="M22.23,27.4c0.42,0.47,0.38,1.18-0.09,1.6c0,0,0,0,0,0c-4.06,3.63-10.3,3.28-13.93-0.78S4.93,17.93,8.99,14.3
        c3.74-3.35,9.4-3.35,13.15,0c0.48,0.41,0.54,1.12,0.13,1.6c-0.41,0.48-1.12,0.54-1.6,0.13c-0.01-0.01-0.03-0.02-0.04-0.04
        c-3.13-2.8-7.93-2.53-10.73,0.6s-2.53,7.93,0.6,10.73c2.88,2.58,7.25,2.58,10.13,0C21.1,26.9,21.81,26.94,22.23,27.4
        C22.23,27.4,22.23,27.4,22.23,27.4z"
      />
    </g>
  </SvgIcon>
);

export const ChangePasswordIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-214.1 -349)">
      <path
        d="M216.01,361.82l-0.73,1.02c-0.34,0.47-0.24,1.12,0.23,1.46c0,0,0,0,0,0c0.18,0.13,0.4,0.21,0.63,0.21
        c0.34,0,0.66-0.17,0.86-0.44l0.76-1.02l0.76,1.02c0.2,0.28,0.52,0.44,0.86,0.44c0.22-0.01,0.44-0.08,0.63-0.21
        c0.47-0.34,0.57-0.99,0.24-1.46c0,0,0,0,0,0l-0.76-1.02l1.2-0.39c0.55-0.19,0.85-0.78,0.66-1.34c-0.18-0.54-0.77-0.84-1.32-0.67
        l-1.25,0.39v-1.28c0-0.58-0.47-1.04-1.04-1.04s-1.04,0.47-1.04,1.04v1.28l-1.2-0.39c-0.55-0.19-1.15,0.11-1.34,0.66
        c-0.19,0.55,0.11,1.15,0.66,1.34c0.01,0,0.01,0,0.02,0.01L216.01,361.82z"
      />
      <path
        d="M236.69,359.42l-1.2,0.39v-1.28c0-0.58-0.47-1.04-1.04-1.04s-1.04,0.47-1.04,1.04v1.28l-1.2-0.39
        c-0.56-0.17-1.15,0.14-1.32,0.69c-0.17,0.55,0.13,1.13,0.67,1.32l1.2,0.39l-0.79,1.02c-0.34,0.47-0.24,1.12,0.23,1.46c0,0,0,0,0,0
        c0.18,0.13,0.4,0.21,0.63,0.21c0.34,0,0.66-0.17,0.86-0.44l0.76-1.02l0.76,1.02c0.2,0.28,0.52,0.44,0.86,0.44
        c0.22-0.01,0.44-0.08,0.63-0.21c0.47-0.34,0.57-0.99,0.24-1.46c0,0,0,0,0,0l-0.75-1.02l1.2-0.39c0.55-0.18,0.85-0.78,0.68-1.33
        C237.84,359.56,237.25,359.27,236.69,359.42z"
      />
      <path
        d="M228.35,359.42l-1.23,0.39v-1.28c0-0.58-0.47-1.04-1.04-1.04s-1.04,0.47-1.04,1.04v1.28l-1.2-0.39
        c-0.55-0.19-1.15,0.11-1.34,0.66c-0.19,0.55,0.11,1.15,0.66,1.34c0.01,0,0.01,0,0.02,0.01l1.2,0.39l-0.76,1.02
        c-0.34,0.47-0.24,1.12,0.23,1.46c0,0,0,0,0,0c0.18,0.13,0.4,0.21,0.63,0.21c0.34,0,0.66-0.17,0.86-0.44l0.76-1.02l0.76,1.02
        c0.2,0.28,0.52,0.44,0.86,0.44c0.22-0.01,0.44-0.08,0.63-0.21c0.47-0.34,0.57-0.99,0.24-1.46c0,0,0,0,0,0l-0.76-1.02l1.2-0.39
        c0.55-0.18,0.85-0.78,0.68-1.33C229.49,359.56,228.91,359.27,228.35,359.42z"
      />
    </g>
  </SvgIcon>
);

export const CreateAccountIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-2 -9.211)">
      <path
        d="M17.75,18.74c1.13-1.03,1.77-2.49,1.76-4.02c0-3.04-2.47-5.51-5.51-5.51c-3.04,0-5.51,2.47-5.51,5.51
        c-0.01,1.53,0.63,2.99,1.76,4.02c-3.73,1.53-6.17,5.16-6.17,9.2c0,0.61,0.49,1.1,1.1,1.1s1.1-0.49,1.1-1.1
        c0-4.26,3.45-7.71,7.71-7.71s7.71,3.45,7.71,7.71c0,0.61,0.49,1.1,1.1,1.1s1.1-0.49,1.1-1.1C23.91,23.9,21.48,20.27,17.75,18.74z
        M10.7,14.72c0-1.82,1.48-3.3,3.3-3.3s3.3,1.48,3.3,3.3s-1.48,3.3-3.3,3.3C12.18,18.02,10.7,16.54,10.7,14.72z"
      />
      <path
        d="M18.4,28.81c0,0.6-0.49,1.09-1.1,1.1H15.1v2.2c0,0.61-0.49,1.1-1.1,1.1c-0.61,0-1.1-0.49-1.1-1.1v-2.2h-2.21
        c-0.61,0-1.1-0.49-1.1-1.1c0-0.61,0.49-1.1,1.1-1.1h2.2V25.5c0-0.61,0.49-1.1,1.1-1.1s1.1,0.49,1.1,1.1l0,0v2.21h2.2
        C17.91,27.71,18.4,28.2,18.4,28.81z"
      />
    </g>
  </SvgIcon>
);

export const UnblockUserIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-1.323 -0.48)">
      <path
        d="M13.32,0.48c-3.27,0-5.92,2.65-5.92,5.92v4.23H6.55c-1.4,0.01-2.53,1.14-2.54,2.54v8.78c0.01,1.4,1.14,2.53,2.54,2.53
        h13.54c1.4-0.01,2.53-1.14,2.53-2.53v-8.78c0-1.4-1.14-2.53-2.53-2.54h-11V6.4c0-2.34,1.89-4.23,4.23-4.23
        c2.34,0,4.23,1.89,4.23,4.23v0.84c-0.02,0.47,0.35,0.86,0.82,0.88s0.86-0.35,0.88-0.82c0-0.02,0-0.04,0-0.06V6.4
        C19.25,3.13,16.59,0.48,13.32,0.48z M6.55,12.32h13.54c0.46-0.01,0.83,0.35,0.84,0.81c0,0.01,0,0.03,0,0.04v8.78
        c0.01,0.45-0.35,0.83-0.8,0.84c-0.01,0-0.03,0-0.04,0H6.55c-0.45,0.01-0.83-0.35-0.84-0.8c0-0.01,0-0.03,0-0.04v-8.78
        c-0.01-0.46,0.35-0.84,0.8-0.85C6.53,12.32,6.54,12.32,6.55,12.32z"
      />
    </g>
  </SvgIcon>
);

export const DisableUserIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12,0c6.63,0,12,5.37,12,12s-5.37,12-12,12S0,18.63,0,12C0,5.37,5.37,0,12,0z M12,1.48C6.19,1.48,1.48,6.19,1.48,12
      S6.19,22.52,12,22.52c5.81,0,10.52-4.71,10.52-10.52C22.51,6.19,17.81,1.49,12,1.48z"
    />
    <path
      d="M6.15,10.76h11.76c0.19,0.01,0.34,0.16,0.36,0.35v1.77c0.01,0.19-0.14,0.35-0.33,0.35c-0.01,0-0.02,0-0.03,0
      H6.15c-0.24,0-0.41-0.12-0.41-0.35v-1.77C5.76,10.91,5.94,10.75,6.15,10.76z"
    />
  </SvgIcon>
);

export const EnableUserIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12,0c6.63,0,12,5.37,12,12s-5.37,12-12,12S0,18.63,0,12C0,5.37,5.37,0,12,0z M12,1.48C6.19,1.48,1.48,6.19,1.48,12
      S6.19,22.52,12,22.52c5.81,0,10.52-4.71,10.52-10.52C22.51,6.19,17.81,1.49,12,1.48z"
    />
    <path
      d="M16.84,8.14l-5.85,5.54l-2.43-2.57c-0.4-0.42-1.06-0.43-1.47-0.03s-0.43,1.06-0.03,1.47l0,0l3.15,3.29
      c0.19,0.2,0.45,0.31,0.72,0.31c0.27,0.01,0.53-0.09,0.72-0.27l6.61-6.26c0.4-0.39,0.42-1.03,0.03-1.44c-0.37-0.41-1-0.44-1.4-0.07
      C16.86,8.12,16.85,8.13,16.84,8.14L16.84,8.14z"
    />
  </SvgIcon>
);

export const ResetPasswordIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-7.497 -7.5)">
      <path
        d="M31.49,12.15c-0.06,0.47-0.47,0.83-0.95,0.83c-0.04,0-0.08,0-0.13-0.01l-1.66-0.22c3.72,5.28,2.46,12.58-2.82,16.31
        c-5.28,3.72-12.58,2.46-16.31-2.82S7.17,13.66,12.46,9.94c1.97-1.39,4.33-2.14,6.74-2.14c0.53,0,0.96,0.43,0.96,0.96
        c0,0.53-0.43,0.96-0.96,0.96c0,0,0,0,0,0c-5.41-0.01-9.81,4.38-9.81,9.79s4.38,9.81,9.79,9.81c5.41,0.01,9.81-4.38,9.81-9.79
        c0-2.03-0.62-4.01-1.8-5.67l-0.09,2.1c-0.02,0.51-0.44,0.91-0.95,0.91h-0.04c-0.53-0.02-0.94-0.47-0.91-1l0.2-4.45
        c0-0.01,0-0.03,0.01-0.04c0.01-0.01,0-0.02,0-0.03c0-0.01,0-0.01,0-0.01l0,0c0-0.02,0.01-0.04,0.01-0.06
        c0-0.02,0.01-0.06,0.02-0.08c0.01-0.03,0.01-0.03,0.01-0.04c0-0.01,0.02-0.03,0.02-0.05c0.01-0.02,0.02-0.05,0.03-0.08
        s0.01-0.03,0.02-0.04c0.01-0.01,0.02-0.03,0.04-0.05s0.03-0.04,0.04-0.06l0.01-0.02c0.01-0.01,0.01-0.02,0.02-0.03
        c0.01-0.01,0.03-0.03,0.05-0.04c0.02-0.01,0.03-0.03,0.04-0.04c0.02-0.01,0.03-0.03,0.04-0.04c0.02-0.01,0.04-0.03,0.06-0.04
        l0.04-0.03c0.02-0.01,0.04-0.02,0.05-0.03c0.02-0.01,0.04-0.02,0.07-0.03l0.05-0.02c0.02-0.01,0.04-0.02,0.06-0.02
        c0.02-0.01,0.04-0.01,0.06-0.01l0.06-0.01c0.02,0,0.04-0.01,0.05-0.01c0.02,0,0.04,0,0.06,0c0.02,0,0.03,0,0.04,0s0.03,0,0.04,0.01
        h0.05l4.19,0.56C31.19,11.15,31.56,11.63,31.49,12.15C31.49,12.16,31.49,12.16,31.49,12.15L31.49,12.15z"
      />
    </g>
  </SvgIcon>
);

export const DownArrowIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M20,0H4C1.79,0,0,1.79,0,4v16c0,2.21,1.79,4,4,4h16c2.21,0,4-1.79,4-4V4C24,1.79,22.21,0,20,0z M16.67,13.38l-3.94,3.93
      l-0.08,0.06l-0.02,0.02l-0.08,0.03h-0.04l-0.06,0.02h-0.11c-0.04,0.01-0.07,0.01-0.11,0c-0.03-0.01-0.05-0.02-0.08-0.03h-0.02
      c-0.03-0.01-0.06-0.02-0.08-0.04h-0.02c-0.03-0.02-0.05-0.04-0.08-0.07l-3.92-3.95c-0.21-0.21-0.21-0.56,0.01-0.78
      c0.21-0.21,0.56-0.21,0.78,0l2.98,3V6.68c0.03-0.3,0.3-0.53,0.6-0.5c0.3,0.02,0.53,0.29,0.5,0.59v8.82l2.99-3
      c0.21-0.18,0.53-0.18,0.74,0.01C16.86,12.8,16.87,13.15,16.67,13.38z"
    />
  </SvgIcon>
);

export const UpArrowIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M4,24l16,0c2.21,0,4-1.79,4-4l0-16c0-2.21-1.79-4-4-4L4,0C1.79,0,0,1.79,0,4l0,16C0,22.21,1.79,24,4,24z M7.33,10.62
      l3.94-3.93l0.08-0.06l0.02-0.02l0.08-0.03h0.04l0.06-0.02h0.11c0.04-0.01,0.07-0.01,0.11,0c0.03,0.01,0.05,0.02,0.08,0.03h0.02
      c0.03,0.01,0.06,0.02,0.08,0.04h0.02C12,6.65,12.02,6.67,12.05,6.7l3.92,3.95c0.21,0.21,0.21,0.56-0.01,0.78
      c-0.21,0.21-0.56,0.21-0.78,0l-2.98-3l0,8.89c-0.03,0.3-0.3,0.53-0.6,0.5c-0.3-0.02-0.53-0.29-0.5-0.59l0-8.82l-2.99,3
      c-0.21,0.18-0.53,0.18-0.74-0.01C7.14,11.2,7.13,10.85,7.33,10.62z"
    />
  </SvgIcon>
);

export const NeutralIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M20,0H4C1.8,0,0,1.8,0,4v16c0,2.2,1.8,4,4,4h16c2.2,0,4-1.8,4-4V4C24,1.8,22.2,0,20,0z M18,12.6H6c-0.3,0-0.6-0.3-0.6-0.6
	    s0.3-0.6,0.6-0.6h12c0.3,0,0.6,0.3,0.6,0.6S18.3,12.6,18,12.6z"
    />
  </SvgIcon>
);

export const DeleteIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-3 -1)">
      <path
        d="M22.64,7.55c-0.6,0-1.09,0.49-1.09,1.09l0,0v12.21c-0.07,1.15-1.04,2.03-2.19,1.97h-8.71c-1.15,0.06-2.13-0.82-2.19-1.97
        V8.64c-0.05-0.6-0.58-1.05-1.18-1c-0.53,0.04-0.95,0.47-1,1v12.21c0.07,2.35,2.02,4.2,4.37,4.15h8.71c2.35,0.05,4.3-1.8,4.37-4.16
        V8.64C23.73,8.03,23.24,7.55,22.64,7.55C22.64,7.55,22.64,7.55,22.64,7.55z"
      />
      <path
        d="M23.73,4.27h-4.37V2.09c0-0.6-0.48-1.09-1.09-1.09h-6.54c-0.6,0-1.09,0.49-1.09,1.09v2.18H6.27c-0.6,0-1.09,0.49-1.09,1.09
        s0.49,1.09,1.09,1.09l0,0h17.45c0.6,0,1.09-0.49,1.09-1.09S24.33,4.27,23.73,4.27L23.73,4.27z M12.82,4.27V3.18h4.36v1.09
        L12.82,4.27z"
      />
      <path
        d="M13.91,18.45v-7.64c0-0.6-0.49-1.09-1.09-1.09c-0.6,0-1.09,0.49-1.09,1.09v7.64c0,0.6,0.49,1.09,1.09,1.09
        C13.42,19.54,13.91,19.06,13.91,18.45z"
      />
      <path
        d="M18.27,18.45v-7.64c0-0.6-0.49-1.09-1.09-1.09c-0.6,0-1.09,0.49-1.09,1.09v7.64c0,0.6,0.49,1.09,1.09,1.09
        C17.79,19.54,18.27,19.06,18.27,18.45z"
      />
    </g>
  </SvgIcon>
);

export const SubscriptionIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-97.532 -27.999)">
      <path
        d="M121.2,36.1l-3.9-2.6v-3.7c0-1-0.8-1.9-1.8-1.9c0,0,0,0,0,0h-11.7c-1,0-1.9,0.8-1.9,1.8c0,0,0,0,0,0v3.7
        l-3.9,2.6c-0.2,0.2-0.3,0.4-0.3,0.7v12.8c0,1.3,1.1,2.4,2.4,2.4l0,0h19.2c1.3,0,2.4-1.1,2.4-2.4v0V36.8
        C121.5,36.5,121.4,36.3,121.2,36.1L121.2,36.1z M119.3,36.8l-2,1.4v-2.7L119.3,36.8z M103.7,29.6h11.7c0.1,0,0.3,0.1,0.3,0.3v9.4
        l-6.1,4.1l-6.1-4.1v-9.4C103.4,29.7,103.5,29.6,103.7,29.6L103.7,29.6z M101.8,35.4v2.7l-2-1.3L101.8,35.4z M119.1,50.4H99.9
        c-0.4,0-0.8-0.3-0.8-0.8c0,0,0,0,0,0V38.3l9.9,6.6c0.3,0.2,0.6,0.2,0.9,0l9.9-6.6v11.3C119.9,50,119.6,50.4,119.1,50.4
        C119.2,50.4,119.2,50.4,119.1,50.4L119.1,50.4z"
      />
      <path
        d="M107,32h5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-5c-0.3,0-0.5-0.2-0.5-0.5
        C106.5,32.3,106.7,32,107,32z"
      />
      <path
        d="M107,35h5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-5c-0.3,0-0.5-0.2-0.5-0.5
        C106.5,35.2,106.7,35,107,35z"
      />
    </g>
  </SvgIcon>
);

export const InfoIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.7,0,12,0C12,0,12,0,12,0z M13.2,18h-2.4v-7.2h2.4V18z
	    M13.2,8.4h-2.4V6h2.4V8.4z"
    />
  </SvgIcon>
);

export const IHIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M9.4,21.6V15h5.2v6.5c0,0.7,0.6,1.3,1.3,1.3h3.9c0.7,0,1.3-0.6,1.3-1.3v-9.1h2.2c0.4,0,0.6-0.3,0.6-0.7
	    c0-0.2-0.1-0.4-0.2-0.5L12.9,1.5c-0.5-0.4-1.3-0.4-1.7,0L0.2,11.3c-0.3,0.2-0.3,0.7,0,0.9c0.1,0.1,0.3,0.2,0.5,0.2h2.2v9.1
	    c0,0.7,0.6,1.3,1.3,1.3h3.9C8.8,22.9,9.4,22.3,9.4,21.6z"
    />
  </SvgIcon>
);

export const OOHIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M23.9,8.3L22.6,3c-0.2-1.1-1.2-1.8-2.3-1.8H3.7C2.6,1.2,1.6,2,1.4,3L0.1,8.3c-0.3,1.2,0,2.5,0.7,3.5
			C1,11.8,1.1,12,1.2,12.1v8.3c0,1.3,1.1,2.4,2.4,2.4h16.8c1.3,0,2.4-1.1,2.4-2.4v-8.3c0.1-0.1,0.2-0.2,0.3-0.3
			C23.9,10.8,24.2,9.5,23.9,8.3z M20.3,3.6l1.3,5.2c0.1,0.5,0,1-0.3,1.4c-0.3,0.4-0.7,0.6-1.1,0.6c-0.8,0-1.4-0.6-1.5-1.4L18,3.6
			L20.3,3.6z M13.2,3.6h2.4L16.2,9c0.1,0.5-0.1,0.9-0.4,1.3c-0.3,0.3-0.7,0.5-1.1,0.5c-0.8,0-1.5-0.7-1.5-1.6V3.6z M7.8,9l0.7-5.4
			h2.4v5.6c0,0.9-0.7,1.6-1.5,1.6c0,0,0,0,0,0c-0.4,0-0.8-0.2-1.1-0.5C7.9,10,7.7,9.5,7.8,9z M2.4,8.8l1.2-5.2H6L5.3,9.4
			c-0.1,0.8-0.7,1.3-1.5,1.4c-0.4,0-0.9-0.2-1.1-0.6C2.4,9.8,2.3,9.3,2.4,8.8z M3.6,20.4v-7.2c0.1,0,0.2,0,0.3,0c1,0,2-0.4,2.7-1.1
			c0.7,0.7,1.7,1.1,2.8,1.1c1,0,2-0.4,2.7-1.1c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.7-1.1c0.7,0.7,1.7,1.1,2.7,1.1
			c0.1,0,0.2,0,0.3,0v7.2H3.6z"
    />
  </SvgIcon>
);

export const ChannelIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M20.1,14c-0.7,0-1.4,0.3-1.9,0.8l-2.2-1.3c0.4-1,0.4-2,0-3l2.2-1.3c0.5,0.5,1.2,0.8,1.9,0.8c1.5,0,2.7-1.2,2.6-2.7
      S21.5,4.7,20,4.7c-1.5,0-2.6,1.2-2.6,2.7c0,0.2,0,0.5,0.1,0.7l-2.3,1.3c-0.6-0.8-1.6-1.3-2.6-1.5V5.3C14.1,4.9,15,3.5,14.6,2
      s-1.8-2.3-3.2-1.9C9.9,0.4,9.1,1.9,9.4,3.3c0.2,1,1,1.7,1.9,1.9v2.6c-1,0.2-1.9,0.7-2.6,1.5L6.5,8.1c0.1-0.2,0.1-0.5,0.1-0.7
      C6.5,5.9,5.3,4.8,3.8,4.8C2.4,4.9,1.4,6,1.3,7.3C1.3,8.8,2.5,10,4,10c0.7,0,1.4-0.3,1.9-0.8l2.2,1.3c-0.4,1-0.4,2,0,3l-2.2,1.3
      C5.4,14.3,4.7,14,4,14c-1.5,0-2.7,1.2-2.6,2.7s1.2,2.7,2.7,2.6c1.5,0,2.6-1.2,2.6-2.7c0-0.2,0-0.5-0.1-0.7l2.3-1.3
      c0.6,0.8,1.5,1.3,2.6,1.5v2.6C9.9,19.1,9,20.5,9.4,22s1.8,2.3,3.2,1.9c1.4-0.4,2.3-1.8,1.9-3.2c-0.2-1-1-1.7-1.9-1.9v-2.6
      c1-0.2,1.9-0.7,2.6-1.5l2.3,1.3c-0.1,0.2-0.1,0.5-0.1,0.7c0,1.5,1.2,2.7,2.6,2.7c0,0,0,0,0,0c1.5,0,2.7-1.2,2.7-2.7S21.5,14,20.1,14
      L20.1,14z M20.1,6c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4C18.7,6.6,19.3,6,20.1,6C20.1,6,20.1,6,20.1,6z
      M3.9,8.7c-0.8,0-1.4-0.6-1.4-1.4S3.2,6,3.9,6c0.8,0,1.4,0.6,1.4,1.4C5.3,8.1,4.7,8.7,3.9,8.7C4,8.7,3.9,8.7,3.9,8.7L3.9,8.7z
      M3.9,18c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C5.3,17.4,4.7,18,3.9,18C4,18,3.9,18,3.9,18L3.9,18z M10.6,2.7
      c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4C11.3,4.1,10.6,3.5,10.6,2.7C10.6,2.7,10.6,2.7,10.6,2.7z
      M13.4,21.3c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4C12.8,19.9,13.4,20.5,13.4,21.3
      C13.4,21.3,13.4,21.3,13.4,21.3L13.4,21.3z M12,14.9c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
      C14.9,13.6,13.6,14.9,12,14.9L12,14.9L12,14.9z M20.1,18c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
      C21.4,17.4,20.8,18,20.1,18L20.1,18z"
    />
  </SvgIcon>
);

export const SKUIcon = props => (
  <SvgIcon {...props}>
    <g>
      <path d="M23,4.4h-1v15.2H24V4.4H23z" />
      <path d="M0,4.4v15.2h1.9V4.4H0z" />
      <path d="M3.4,4.4v15.2h1.9V4.4H3.4z" />
      <path d="M6.7,4.4h1v15.2h-1V4.4z" />
      <path d="M10.1,4.4h-1v15.2H12V4.4H10.1z" />
      <path d="M13.4,4.4h1v15.2h-1V4.4z" />
      <path d="M18.7,4.4v15.2h1.9V4.4H18.7z" />
      <path d="M15.8,4.4v15.2h1.4V4.4H15.8z" />
    </g>
  </SvgIcon>
);

export const RankingIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M17.8,17.4c-0.4,0.4-0.5,1-0.4,1.6l0.6,2.7c0.5,2.1-0.6,2.9-2.5,1.8L12.9,22c-0.5-0.3-1.2-0.3-1.7,0l-2.6,1.5
      c-1.9,1.1-3,0.3-2.5-1.8L6.6,19c0.1-0.6-0.1-1.1-0.4-1.6L4,15.3C2.8,14,3.2,12.7,5,12.4L7.7,12c0.5-0.1,1-0.4,1.2-0.9L10.5,8
      c0.8-1.7,2.2-1.7,3,0L15,11c0.3,0.5,0.7,0.8,1.2,0.9l2.8,0.5c1.8,0.3,2.2,1.6,0.9,2.9L17.8,17.4z M5,9.9c0.5,0,0.9-0.4,0.9-0.9l0,0
      V0.9C5.9,0.4,5.5,0,5,0.1c-0.5,0-0.8,0.4-0.8,0.8V9C4.2,9.5,4.6,9.9,5,9.9z M18.9,9.9c0.5,0,0.9-0.4,0.9-0.9l0,0V0.9
      C19.8,0.4,19.5,0,19,0c-0.5,0-0.9,0.4-0.9,0.8c0,0,0,0,0,0.1V9C18.1,9.5,18.5,9.9,18.9,9.9z M12,4.1c0.5,0,0.9-0.4,0.9-0.9V0.9
      C12.9,0.4,12.5,0,12,0c-0.5,0-0.9,0.4-0.9,0.8c0,0,0,0,0,0.1v2.3C11.1,3.7,11.5,4.1,12,4.1L12,4.1z"
    />
  </SvgIcon>
);

export const WeightingsIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M23.1,19.7H11.9c-0.5-1.8-2.3-2.9-4.2-2.4c-1.2,0.3-2.1,1.2-2.4,2.4H0.9c-0.5,0-0.9,0.4-0.9,0.8c0,0.5,0.4,0.9,0.8,0.9
      c0,0,0,0,0.1,0h4.4c0.5,1.8,2.3,2.9,4.2,2.4c1.2-0.3,2.1-1.2,2.4-2.4h11.2c0.5,0,0.9-0.4,0.9-0.8C24,20.1,23.6,19.7,23.1,19.7
      C23.2,19.7,23.1,19.7,23.1,19.7L23.1,19.7z M8.6,22.3c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
      C10.3,21.5,9.5,22.3,8.6,22.3z M23.1,11.1h-4.4c-0.5-1.8-2.3-2.9-4.2-2.4c-1.2,0.3-2.1,1.2-2.4,2.4H0.9c-0.5,0-0.8,0.4-0.8,0.9
      c0,0.5,0.4,0.8,0.8,0.8h11.2c0.5,1.8,2.3,2.9,4.2,2.4c1.2-0.3,2.1-1.2,2.4-2.4h4.4c0.5,0,0.8-0.4,0.8-0.9
      C23.9,11.5,23.6,11.2,23.1,11.1L23.1,11.1z M15.4,13.7c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
      C17.1,12.9,16.4,13.7,15.4,13.7C15.4,13.7,15.4,13.7,15.4,13.7L15.4,13.7z M0.9,4.3h4.4c0.5,1.8,2.3,2.9,4.2,2.4
      c1.2-0.3,2.1-1.2,2.4-2.4h11.2c0.5,0,0.8-0.4,0.8-0.9c0-0.5-0.4-0.8-0.8-0.8H11.9c-0.5-1.8-2.3-2.9-4.2-2.4C6.5,0.5,5.6,1.4,5.3,2.6
      H0.9C0.4,2.6,0,2.9,0,3.4C0,3.9,0.4,4.3,0.9,4.3C0.8,4.3,0.9,4.3,0.9,4.3L0.9,4.3z M8.6,1.7c0.9,0,1.7,0.8,1.7,1.7
      c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7C6.9,2.5,7.6,1.7,8.6,1.7z"
    />
  </SvgIcon>
);

export const SKUEditIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M15.6,20.6h7.2c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-7.2c-0.7,0-1.2,0.5-1.2,1.2S14.9,20.6,15.6,20.6z M15.6,14h7.2
      c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2h-7.2c-0.7,0-1.2,0.5-1.2,1.2C14.4,13.4,14.9,14,15.6,14z M15.6,7.4h7.2
      c0.7,0,1.2-0.5,1.2-1.2S23.5,5,22.8,5h-7.2c-0.7,0-1.2,0.5-1.2,1.2S14.9,7.4,15.6,7.4z M9.4,3.6l2.1,2.1c0.2,0.2,0.2,0.6,0,0.9
      L9.4,8.7c-0.4,0.4-1,0.1-1-0.4V4C8.4,3.5,9,3.2,9.4,3.6z M8.4,7.4H8c-2.8,0-5.3,2.1-5.6,4.9c-0.3,3.2,2.2,5.9,5.4,5.9h3
      c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2h-3c-4.6,0-8.4-4.1-7.7-8.8c0.5-3.9,4-6.8,8-6.8h0.3"
    />
  </SvgIcon>
);

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import {
  format,
  parseISO,
  differenceInWeeks,
  differenceInMonths
} from "date-fns";
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Typography,
  Button,
  Box
} from "@mui/material";
import {
  StarBorderRounded,
  StarRounded,
  DateRangeOutlined
} from "@mui/icons-material";
import {
  DownloadPPTIcon,
  BrandBattlesIcon,
  AmbientIcon,
  ChilledIcon,
  ICIcon,
  FCIcon
} from "../Icons";
import {
  addToFavourites,
  removeFromFavourites,
  downloadPowerPoint
} from "../../actions";
import {
  getWeekStart,
  getWeekEnd,
  getMonthStart,
  getMonthEnd,
  getDateFromPeriod
} from "../../utils";

const styles = {
  header: { pt: 1, display: "flex", alignItems: "flex-start" },
  type: {
    mt: 2,
    mr: -1,
    px: 2,
    py: 0.5,
    bgcolor: "#f7f3f2",
    borderRadius: "12px 0 0 12px"
  },
  scopeBox: {
    mt: -0.75,
    mr: -1.25,
    ml: 2,
    height: 98,
    bgcolor: "#f7f3f2",
    border: "1px solid #e5e0df",
    borderRadius: "0 0 0 8px"
  },
  scope: {
    p: 1,
    height: 48,
    width: 48,
    color: "#e5e0df",
    float: "left",
    borderTop: "1px solid #e5e0df",
    borderRight: "1px solid #e5e0df"
  },
  divider: { borderBottom: "1px #009dbc dashed" },
  icon: {
    p: 1,
    mr: 1,
    fontSize: 48,
    borderRadius: "50%",
    border: "1px #009dbc dashed",
    float: "left"
  },
  period: { bgcolor: "#f7f3f2", mx: -2, my: -1, px: 2, py: 1 },
  periodTitle: { display: "flex", alignItems: "flex-start", mb: 1, ml: -0.25 },
  actionButton: {
    borderRadius: 0,
    textTransform: "none",
    p: 1.5,
    fontWeight: "normal"
  }
};

const PRRHeader = props => {
  const { report } = props;
  return (
    <Box sx={styles.scopeBox}>
      <Box sx={styles.scope}>
        <ICIcon
          color={
            report.consumption_type === "IMMEDIATE CONSUMPTION" ||
            report.consumption_type === "all"
              ? "primary"
              : "inherit"
          }
          sx={{ fontSize: 30 }}
        />
      </Box>
      <Box sx={styles.scope}>
        <FCIcon
          color={
            report.consumption_type === "FUTURE CONSUMPTION" ||
            report.consumption_type === "all"
              ? "primary"
              : "inherit"
          }
          sx={{ fontSize: 30 }}
        />
      </Box>
      <div style={{ clear: "both" }} />
      <Box sx={styles.scope}>
        <AmbientIcon
          color={
            report.temperature === "AMBIENT" || report.temperature === "all"
              ? "primary"
              : "inherit"
          }
          sx={{ fontSize: 30 }}
        />
      </Box>
      <Box sx={styles.scope}>
        <ChilledIcon
          color={
            report.temperature === "CHILLED" || report.temperature === "all"
              ? "primary"
              : "inherit"
          }
          sx={{ fontSize: 30 }}
        />
      </Box>
    </Box>
  );
};

const ReportCard = props => {
  const {
    report,
    isFavourite,
    downloadPPT,
    addFavourite,
    removeFavourite,
    userId,
    dataDate,
    client
  } = props;
  const isCustomDate = report.period.split(",").length === 4;
  let mainStart;
  let mainEnd;
  let compareStart;
  let compareEnd;
  let periodLength;
  const isMonth = report.story === "CGA";
  if (isCustomDate) {
    [compareStart, compareEnd, mainStart, mainEnd] = report.period
      .split(",")
      .map((i, k) => {
        if (isMonth) {
          return k % 2 === 0 ? getMonthStart(i) : getMonthEnd(i);
        }
        return k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i);
      });
    periodLength = isMonth
      ? differenceInMonths(mainEnd, mainStart) + 1
      : differenceInWeeks(mainEnd, mainStart) + 1;
  } else {
    [compareStart, compareEnd, mainStart, mainEnd] = getDateFromPeriod(
      report.period,
      dataDate,
      isMonth
    );
  }
  return (
    <Grid item md={4} xs={6}>
      <Link to={`/reports?${report.query}`}>
        <Card raised>
          <CardHeader
            sx={styles.header}
            action={
              ((report.story === "IDA" || report.story === "CGA") && (
                <Typography
                  variant="subtitle3"
                  color="secondary"
                  sx={styles.type}
                >
                  {report.story === "IDA" ? "In Home" : ""}
                  {report.story === "CGA" ? "Out of Home" : ""}
                </Typography>
              )) ||
              (report.story === "PRR" && <PRRHeader report={report} />)
            }
            title={
              <Fragment>
                {report.date
                  ? format(parseISO(report.date), "dd/MM/yy")
                  : format(new Date(), "dd/MM/yy")}
                <IconButton
                  sx={{ mt: -0.5, ml: 0.5 }}
                  onClick={e => {
                    e.preventDefault();
                    if (isFavourite) {
                      removeFavourite(userId, report);
                    } else {
                      addFavourite(userId, report);
                    }
                  }}
                  size="large"
                >
                  {isFavourite ? (
                    <StarRounded color="yellow" />
                  ) : (
                    <StarBorderRounded />
                  )}
                </IconButton>
              </Fragment>
            }
            titleTypographyProps={{
              variant: "subtitle2",
              color: "text.secondary",
              fontWeight: "normal"
            }}
            subheader={
              report.story === "BB" ? (
                <Fragment>
                  <Typography
                    color="primary"
                    variant="subtitle2"
                    component="span"
                  >
                    {report.product_a.split("=")[1]}
                  </Typography>
                  <BrandBattlesIcon
                    fontSize="large"
                    color="primary"
                    sx={styles.icon}
                  />
                  <Divider sx={styles.divider} />
                  <Typography
                    color="primary"
                    variant="subtitle2"
                    component="span"
                  >
                    {report.product_b.split("=")[1]}
                  </Typography>
                </Fragment>
              ) : (
                (report.story === "PRR" && (
                  <Typography variant="subtitle3" gutterBottom fontSize={13}>
                    {report.category ? report.category : "All Categories"}
                    {report.sector ? ` / ${report.sector}` : " / All Sectors"}
                  </Typography>
                )) || (
                  <Fragment>
                    <Typography variant="subtitle3" gutterBottom fontSize={13}>
                      {_.values(
                        _.omit(
                          _.omitBy(
                            report,
                            (i, j) => i === "all" || j.startsWith("context")
                          ),
                          [
                            "metric_name",
                            "retailer",
                            "period",
                            "story",
                            "query",
                            "date",
                            "reportId"
                          ]
                        )
                      ).join(" / ")}
                    </Typography>
                    {report.story === "IDA" ||
                      (report.story === "CGA" && (
                        <Typography
                          variant="subtitle3"
                          gutterBottom
                          fontSize={13}
                        >
                          <Typography
                            variant="h7"
                            component="span"
                            fontSize={13}
                          >
                            Within
                          </Typography>
                          &nbsp;
                          {_.values(
                            _.pickBy(report, (i, j) => j.startsWith("context"))
                          )
                            .map(i => i.split("=")[1])
                            .join(" / ")}
                        </Typography>
                      ))}
                  </Fragment>
                )
              )
            }
          />
          <CardContent sx={{ pt: 0 }}>
            {report.story === "BB" && (
              <Fragment>
                <Typography variant="h7" fontSize={13}>
                  Context
                </Typography>
                <Typography variant="subtitle3" gutterBottom fontSize={13}>
                  {_.values(
                    _.omit(report, [
                      "metric_name",
                      "retailer",
                      "period",
                      "story",
                      "query",
                      "date",
                      "reportId",
                      "product_a",
                      "product_b"
                    ])
                  ).join(" / ")}
                </Typography>
                <Divider sx={{ my: 1, mx: -1 }} />
              </Fragment>
            )}
            <Typography variant="h7" fontSize={13}>
              Where
            </Typography>
            <Typography variant="subtitle3" gutterBottom fontSize={13}>
              {report.retailer}
            </Typography>
            <Divider sx={{ my: 1, mx: -1 }} />
            <Box sx={styles.period}>
              <Typography
                variant="subtitle2"
                color="orange.dark"
                sx={styles.periodTitle}
                gutterBottom
              >
                <DateRangeOutlined fontSize="small" /> &nbsp;&nbsp;
                {isCustomDate
                  ? `${periodLength} ${isMonth ? "months" : "weeks"}`
                  : report.period}
              </Typography>
              <Typography variant="h7" gutterBottom>
                Period: &nbsp;&nbsp;
                <Typography variant="subtitle3" component="span" gutterBottom>
                  {format(mainStart, "dd/MM/yy")}&nbsp;-&nbsp;
                  {format(mainEnd, "dd/MM/yy")}
                </Typography>
              </Typography>
              <Typography variant="h7" gutterBottom>
                Comp:&nbsp; &nbsp;&nbsp;
                <Typography variant="subtitle3" component="span" gutterBottom>
                  {format(compareStart, "dd/MM/yy")}&nbsp;-&nbsp;
                  {format(compareEnd, "dd/MM/yy")}
                </Typography>
              </Typography>
            </Box>
            <Divider sx={{ my: 1, mx: -1 }} />
            {report.story === "PRR" && (
              <Fragment>
                <Typography variant="h7" fontSize={13}>
                  Implementation Date
                </Typography>
                <Typography variant="subtitle3" gutterBottom fontSize={13}>
                  {report.implementation_date
                    ? format(
                        getWeekStart(report.implementation_date),
                        "dd/MM/yy"
                      )
                    : format(mainStart, "dd/MM/yy")}
                </Typography>
                <Divider sx={{ my: 1, mx: -1 }} />
              </Fragment>
            )}
            <Typography variant="h7" fontSize={13}>
              {report.story === "PRR" ? "Result Hierarchy" : "Metric"}
            </Typography>
            <Typography variant="subtitle3" gutterBottom fontSize={13}>
              {report.story === "PRR"
                ? `${_.startCase(report.hier1)} ${
                    report.hier2 ? `/ ${_.startCase(report.hier2)}` : ""
                  }`
                : report.metric_name}
            </Typography>
          </CardContent>
          <CardActions
            onClick={e => {
              e.preventDefault();
              downloadPPT(report.reportId, report.story, client);
            }}
            disableSpacing
            sx={{ p: 0 }}
          >
            <Button
              variant="contained"
              fullWidth
              disableElevation
              startIcon={<DownloadPPTIcon />}
              sx={styles.actionButton}
              size="large"
            >
              Download Report
            </Button>
          </CardActions>
        </Card>
      </Link>
    </Grid>
  );
};

PRRHeader.propTypes = {
  report: PropTypes.shape()
};

PRRHeader.defaultProps = {
  report: {}
};

ReportCard.propTypes = {
  report: PropTypes.shape(),
  isFavourite: PropTypes.bool,
  downloadPPT: PropTypes.func,
  addFavourite: PropTypes.func,
  removeFavourite: PropTypes.func,
  userId: PropTypes.string,
  dataDate: PropTypes.string,
  client: PropTypes.string
};

ReportCard.defaultProps = {
  report: {},
  isFavourite: false,
  downloadPPT: () => {},
  addFavourite: () => {},
  removeFavourite: () => {},
  userId: "",
  dataDate: "",
  client: ""
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  downloadPPT: (reportId, story, client) => {
    const { userId } = ownProps;
    dispatch(
      downloadPowerPoint(reportId, story, userId, client, -1, "ReportCard") // -1 -> slideIndex, "ReportCard" -> slideKey
    );
  },
  addFavourite: (userId, report) => dispatch(addToFavourites(userId, report)),
  removeFavourite: (userId, report) =>
    dispatch(removeFromFavourites(userId, report))
});

export default connect(null, mapDispatchToProps)(ReportCard);

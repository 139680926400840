import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { TextField, Autocomplete } from "@mui/material";
import { NUMBER_SUGGESTIONS } from "../../constants";
import { getDateLabel } from "../../utils";

const SearchBar = props => {
  const {
    searchOptions,
    value,
    onChange,
    searchEngine,
    disabled,
    multiple,
    placeholder,
    size,
    disableClearable
  } = props;
  return (
    <Autocomplete
      freeSolo
      options={searchOptions.map(i => ({ ...i, name: i.name[0] }))}
      getOptionLabel={option => {
        if (_.isEmpty(option)) {
          return "";
        }
        return option.name
          ? (option.name.value && option.name.value) ||
              (option.name.period &&
                `${option.name.period} ${option.name.date}`) ||
              (option.name.split(",").length === 4 &&
                getDateLabel(option.name, option.story)) ||
              option.name
          : option.label;
      }}
      renderOption={(innerProps, option) => (
        <li {...innerProps} key={innerProps.id}>
          {(option.name.value && option.name.value) ||
            (option.name.period && option.name.period) ||
            option.name}{" "}
          ({option.subsection})
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          margin="normal"
          variant="outlined"
          placeholder={placeholder}
        />
      )}
      filterOptions={(options, state) => {
        const { inputValue } = state;
        const suggestions = searchEngine
          .search(inputValue)
          .slice(0, NUMBER_SUGGESTIONS)
          .map(i => ({ ...i.item, name: i.item.name[0] }));
        const intersection = _.intersectionWith(
          suggestions,
          options,
          _.isEqual
        );
        return _.differenceBy(
          intersection,
          value,
          i =>
            ((i.name.value && i.name.value) ||
              (i.name.period && i.name.period) ||
              i.name) + i.subsection
        );
      }}
      onChange={onChange}
      value={value}
      disabled={disabled || _.isEmpty(searchEngine)}
      multiple={multiple}
      filterSelectedOptions
      size={size}
      disableClearable={disableClearable}
    />
  );
};

SearchBar.propTypes = {
  searchOptions: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  onChange: PropTypes.func,
  searchEngine: PropTypes.shape(),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  disableClearable: PropTypes.bool
};

SearchBar.defaultProps = {
  searchOptions: [],
  value: {} || [],
  onChange: () => {},
  searchEngine: {},
  disabled: false,
  multiple: false,
  placeholder: "",
  size: "medium",
  disableClearable: false
};

export default SearchBar;

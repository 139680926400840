import _ from "lodash";
import defaultState from "./defaultState";
import {
  ENTER_SEARCH_TERM,
  DELETE_LAST_SEARCH_TERM,
  DELETE_TERM,
  ENTER_SELECTED_TERM,
  ENTER_AUTOCOMPLETE_TERM,
  SEARCH_REPORT_HINT,
  CLEAR_SEARCH_REPORT_HINT,
  FILTER_STATIC_DATA_REQUEST,
  FILTER_STATIC_DATA_SUCCESS,
  FILTER_STATIC_DATA_ERROR,
  REMOVE_STATIC_DATA_FILTER,
  CLEAR_ALL_SEARCH_DATA,
  CLEAR_STORY_SEARCH_DATA,
  GET_CONTEXT_REQUEST,
  GET_CONTEXT_SUCCESS,
  GET_CONTEXT_ERROR,
  REMOVE_CONTEXT
} from "../constants/actionConstants";
import { NUMBER_SUGGESTIONS } from "../constants";
import { initSearchEngine, mapSubsectionsToSearchList } from "./data";

/**
 * Constructs the search string from a list of search terms
 * @param  {Array} terms Array of search term objects
 * @return {string}      Natural language search string
 */
const buildSearchString = (terms, story) =>
  terms
    .filter(term => term.story !== "prr") // don't build at all for prr
    .filter(term => term.story !== "lookout") // don't build at all for lookout
    .filter(term => term.table !== "context") // don't show context
    .filter(term => term.story === story || term.subsection === "autocomplete")
    .map(term =>
      term.subsection === "period"
        ? `${term.name.period} ${term.name.date}`
        : term.name
    )
    .join(" ");

const buildSearchTerms = (searchTerms, suggestions) =>
  searchTerms
    .filter(term => term.subsection !== "autocomplete")
    .concat(suggestions);

const isNotInSearchTermsFilter = searchTerms => suggestion =>
  !searchTerms.find(
    searchTerm =>
      (_.isEqual(searchTerm.name, suggestion.item.name[0]) ||
        _.isEqual(searchTerm.name, suggestion.item.name[0].value)) &&
      searchTerm.subsection === suggestion.item.subsection
  );

const allSearchTerms = (searchTerms, story) => {
  if (story === "prr") {
    if (!searchTerms.find(s => s.subsection === "temperature")) {
      return false;
    }
    if (!searchTerms.find(s => s.subsection === "consumption_type")) {
      return false;
    }
    if (!searchTerms.find(s => s.subsection === "retailer")) {
      return false;
    }
    if (!searchTerms.find(s => s.subsection === "period")) {
      return false;
    }
    if (!searchTerms.find(s => s.subsection === "hier1")) {
      return false;
    }
    if (!searchTerms.find(s => s.subsection === "metric_name")) {
      return false;
    }
    return true;
  }
  if (story === "bb") {
    if (!searchTerms.find(s => s.subsection.startsWith("product_a"))) {
      return false;
    }
    if (!searchTerms.find(s => s.subsection.startsWith("product_b"))) {
      return false;
    }
  }
  if (
    searchTerms.filter(
      t => t.table === "what" && !t.subsection.startsWith("product_")
    ).length === 0
  ) {
    return false;
  }
  if (searchTerms.filter(t => t.table === "where").length === 0) {
    return false;
  }
  if (searchTerms.filter(t => t.table === "when").length === 0) {
    return false;
  }
  if (searchTerms.filter(t => t.table === "measure").length === 0) {
    return false;
  }
  return true;
};

export default (state = defaultState.search, action) => {
  const { type } = action;

  switch (type) {
    case ENTER_SEARCH_TERM: {
      // grab autocomplete term from search terms.
      const autoCompleteTerm = action.suggestions.find(
        term => term.subsection === "autocomplete"
      ) || { name: "" };
      // The fuzzy search engine is created in FETCH_STATIC_DATA_SUCCESS
      const suggestions = action.searchEngine
        .search(autoCompleteTerm.name)
        .filter(isNotInSearchTermsFilter(state.searchTerms[action.story] || []))
        .slice(0, NUMBER_SUGGESTIONS);
      // remove old autocomplete search term and concat new search terms
      const searchTerms = buildSearchTerms(
        state.searchTerms[action.story] || [],
        action.suggestions
      );
      const allTerms = allSearchTerms(searchTerms, action.story);

      return {
        ...state,
        searchString: buildSearchString(searchTerms, action.story),
        searchTerms: { ...state.searchTerms, [action.story]: searchTerms },
        suggestions: suggestions.map(i => ({
          ...i.item,
          name: i.item.name[0]
        })),
        autoCompleteTerm,
        allTerms: { ...state.allTerms, [action.story]: allTerms }
      };
    }

    case ENTER_AUTOCOMPLETE_TERM: {
      // remove old autocomplete search term and concat new search terms
      const searchTerms = buildSearchTerms(
        state.searchTerms[action.story] || [],
        action.suggestion
      );
      const allTerms = allSearchTerms(searchTerms, action.story);
      const prevHint = state.hint || "";
      const hint = prevHint.includes(action.suggestion.table) ? "" : prevHint;
      return {
        ...state,
        searchString: buildSearchString(searchTerms, action.story),
        searchTerms: { ...state.searchTerms, [action.story]: searchTerms },
        suggestions: [],
        autoCompleteTerm: { name: "" },
        allTerms: { ...state.allTerms, [action.story]: allTerms },
        hint
      };
    }

    case ENTER_SELECTED_TERM: {
      // remove old autocomplete search term and concat new search terms
      const searchTerms = buildSearchTerms(
        state.searchTerms[action.story] || [],
        action.selectedTerm
      );
      const allTerms = allSearchTerms(searchTerms, action.story);
      const prevHint = state.hint || "";
      const hint = prevHint.includes(action.selectedTerm.table) ? "" : prevHint;
      return {
        ...state,
        searchString: buildSearchString(searchTerms, action.story),
        searchTerms: { ...state.searchTerms, [action.story]: searchTerms },
        allTerms: { ...state.allTerms, [action.story]: allTerms },
        hint
      };
    }

    case DELETE_LAST_SEARCH_TERM: {
      const searchTerms = [...state.searchTerms[action.story]];
      // remove autocomplete, and last search term
      searchTerms.splice(-1);
      const allTerms = allSearchTerms(searchTerms, action.story);
      return {
        ...state,
        searchString: buildSearchString(searchTerms, action.story),
        searchTerms: { ...state.searchTerms, [action.story]: searchTerms },
        allTerms: { ...state.allTerms, [action.story]: allTerms }
      };
    }

    case DELETE_TERM: {
      const searchTerms = [...state.searchTerms[action.story]];
      const newTerms = searchTerms.filter(t => !_.isEqual(t, action.term));
      const allTerms = allSearchTerms(newTerms, action.story);
      return {
        ...state,
        searchTerms: { ...state.searchTerms, [action.story]: newTerms },
        searchString: buildSearchString(newTerms, action.story),
        allTerms: { ...state.allTerms, [action.story]: allTerms }
      };
    }

    case SEARCH_REPORT_HINT: {
      return {
        ...state,
        hint: action.hint
      };
    }

    case CLEAR_SEARCH_REPORT_HINT: {
      return {
        ...state,
        hint: ""
      };
    }

    case FILTER_STATIC_DATA_REQUEST: {
      return {
        ...state,
        isFilteringData: true,
        error: ""
      };
    }

    case FILTER_STATIC_DATA_SUCCESS: {
      const filteredSearchList = mapSubsectionsToSearchList(
        action.data.subsections
      );
      const filteredSearchEngine = initSearchEngine(filteredSearchList);
      return {
        ...state,
        filteredSearchEngine: {
          ...state.filteredSearchEngine,
          [action.story]: filteredSearchEngine
        },
        filteredSearchList: {
          ...state.filteredSearchList,
          [action.story]: filteredSearchList
        },
        isFilteringData: false,
        filteredSubsections: {
          ...state.filteredSubsections,
          [action.story]: action.data.subsections
        },
        error: "",
        isFilteredData: { ...state.isFilteredData, [action.story]: true },
        filterTerms: {
          ...state.filterTerms,
          [action.story]: action.filterTerms
        }
      };
    }

    case FILTER_STATIC_DATA_ERROR: {
      return {
        ...state,
        isFilteringData: false,
        error: action.error
      };
    }

    case REMOVE_STATIC_DATA_FILTER: {
      return {
        ...state,
        filteredSearchEngine: {
          ...state.filteredSearchEngine,
          [action.story]: {}
        },
        filteredSearchList: { ...state.filteredSearchList, [action.story]: [] },
        filteredSubsections: {
          ...state.filteredSubsections,
          [action.story]: []
        },
        error: "",
        isFilteredData: { ...state.isFilteredData, [action.story]: false },
        filterTerms: { ...state.filterTerms, [action.story]: [] }
      };
    }

    case CLEAR_ALL_SEARCH_DATA: {
      return defaultState.search;
    }

    case CLEAR_STORY_SEARCH_DATA: {
      return {
        ...state,
        searchTerms: { ...state.searchTerms, [action.story]: [] },
        allTerms: { ...state.allTerms, [action.story]: false },
        filteredSubsections: {
          ...state.filteredSubsections,
          [action.story]: []
        },
        isFilteredData: { ...state.isFilteredData, [action.story]: false },
        filterTerms: { ...state.filterTerms, [action.story]: [] },
        context: { ...state.context, [action.story]: [] },
        suggestedContext: { ...state.suggestedContext, [action.story]: [] }
      };
    }

    case GET_CONTEXT_REQUEST: {
      return {
        ...state,
        isLoadingContext: true
      };
    }

    case GET_CONTEXT_SUCCESS: {
      const contextSearchList = mapSubsectionsToSearchList(
        action.data.subsections,
        true
      );
      const contextSearchEngine = initSearchEngine(contextSearchList);
      return {
        ...state,
        context: {
          ...state.context,
          [action.story]: action.data.subsections
        },
        contextSearchEngine: {
          ...state.contextSearchEngine,
          [action.story]: contextSearchEngine
        },
        isLoadingContext: false,
        suggestedContext: {
          ...state.suggestedContext,
          [action.story]: action.data.suggestedContext
        }
      };
    }

    case GET_CONTEXT_ERROR: {
      return {
        ...state,
        error: action.error,
        isLoadingContext: false
      };
    }

    case REMOVE_CONTEXT: {
      return {
        ...state,
        context: {
          ...state.context,
          [action.story]: []
        },
        contextSearchEngine: {
          ...state.contextSearchEngine,
          [action.story]: {}
        },
        isLoadingContext: false,
        suggestedContext: {
          ...state.suggestedContext,
          [action.story]: []
        },
        searchTerms: {
          ...state.searchTerms,
          [action.story]: state.searchTerms[action.story].filter(
            i => i.table !== "context"
          )
        }
      };
    }

    default: {
      return state;
    }
  }
};

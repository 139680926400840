import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardHeader,
  Chip
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { EditIcon, ChannelIcon } from "../Icons";
import { getColumns, Toolbar } from "./Components";
import { getProducts } from "../../actions";
import { initSearch, fuseSearch } from "../../utils";
import { STERLING_HIGHLIGHT_COLUMNS } from "../../constants";

const styles = {
  highlight: {
    ".top-1": theme => ({ bgcolor: `${theme.palette.primary.main}ff` }),
    ".top-2": theme => ({ bgcolor: `${theme.palette.primary.main}e6` }),
    ".top-3": theme => ({ bgcolor: `${theme.palette.primary.main}cc` }),
    ".top-4": theme => ({ bgcolor: `${theme.palette.primary.main}b3` }),
    ".top-5": theme => ({ bgcolor: `${theme.palette.primary.main}99` }),
    ".top-6": theme => ({ bgcolor: `${theme.palette.primary.main}80` }),
    ".top-7": theme => ({ bgcolor: `${theme.palette.primary.main}66` }),
    ".top-8": theme => ({ bgcolor: `${theme.palette.primary.main}4d` }),
    ".top-9": theme => ({ bgcolor: `${theme.palette.primary.main}33` }),
    ".top-10": theme => ({ bgcolor: `${theme.palette.primary.main}1a` }),
    ".bottom-1": theme => ({ bgcolor: `${theme.palette.yellow.main}ff` }),
    ".bottom-2": theme => ({ bgcolor: `${theme.palette.yellow.main}e6` }),
    ".bottom-3": theme => ({ bgcolor: `${theme.palette.yellow.main}cc` }),
    ".bottom-4": theme => ({ bgcolor: `${theme.palette.yellow.main}b3` }),
    ".bottom-5": theme => ({ bgcolor: `${theme.palette.yellow.main}99` }),
    ".bottom-6": theme => ({ bgcolor: `${theme.palette.yellow.main}80` }),
    ".bottom-7": theme => ({ bgcolor: `${theme.palette.yellow.main}66` }),
    ".bottom-8": theme => ({ bgcolor: `${theme.palette.yellow.main}4d` }),
    ".bottom-9": theme => ({ bgcolor: `${theme.palette.yellow.main}33` }),
    ".bottom-10": theme => ({ bgcolor: `${theme.palette.yellow.main}1a` })
  }
};

const Products = props => {
  const {
    channel,
    environments,
    handleBack,
    getOptions,
    options,
    number,
    isLoading,
    error,
    rankType,
    consumptionType,
    date
  } = props;
  const [searchEngine, setSearchEngine] = useState({});
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState(options);
  const [isHighlight, setIsHighlight] = useState(false);
  const isEmpty = options.length === 0;

  useEffect(() => {
    getOptions(channel, environments, rankType, consumptionType);
  }, []);

  useEffect(() => {
    setSearchEngine(initSearch(options, ["product_name"], false));
  }, []);

  useEffect(() => {
    setRows(options);
    setSearchEngine(initSearch(options, ["product_name"], false));
  }, [options]);

  const handleChange = val => {
    setSearch(val);
    const results = fuseSearch(searchEngine, val);
    if (val.length === 0) {
      setRows(options);
    } else {
      setRows(results.map(i => i.item));
    }
  };

  return (
    <Grid container>
      <Grid item md={12} sx={{ mb: 2 }}>
        <Stack spacing={2}>
          <Card sx={{ mb: 4 }}>
            <CardHeader
              title={
                <Grid container alignItems="center" sx={{ pb: 1 }}>
                  <ChannelIcon color="primary" sx={{ fontSize: 14, mr: 0.5 }} />
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                  >
                    {channel === "ih" ? "In Home" : "Out of Home"}
                  </Typography>
                </Grid>
              }
              subheader={
                <Box sx={{ mx: -0.5 }}>
                  {environments.map(i => (
                    <Chip key={i} sx={{ m: 0.5 }} label={i} />
                  ))}
                </Box>
              }
              action={
                <Button
                  size="small"
                  color="yellow"
                  sx={{ mx: 0.5 }}
                  onClick={() => handleBack()}
                >
                  <EditIcon sx={{ fontSize: 16, m: 0.5 }} />
                  Edit
                </Button>
              }
            />
          </Card>
        </Stack>
      </Grid>
      <Box sx={{ minWidth: 1, minHeight: 450 }}>
        <DataGrid
          sx={{
            fontSize: 12,
            ".MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1.2
            },
            ".MuiDataGrid-columnHeaders": { bgcolor: "#e5e0df" },
            ".MuiDataGrid-columnSeparator": { color: "divider" },
            ".disabled": {
              bgcolor: "#e5e0df",
              borderBottom: 2,
              borderColor: "divider",
              "&:hover": { bgcolor: "#e5e0df" }
            },
            ...styles.highlight,
            // move scrollbar to top
            ".MuiDataGrid-virtualScroller": { transform: "rotateX(180deg)" },
            ".MuiDataGrid-virtualScrollerContent": {
              transform: "rotateX(180deg)"
            }
          }}
          rows={rows}
          columns={getColumns(isHighlight)}
          autoHeight={!isEmpty}
          headerHeight={72}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          hideFooter={isEmpty}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              search,
              setSearch: val => handleChange(val),
              rankType,
              environments,
              consumptionType,
              isHighlight,
              setHighlight: val => setIsHighlight(val),
              dataDate: channel === "ooh" ? date.cga : date.nielsen
            }
          }}
          loading={isLoading}
          getRowClassName={params =>
            params.row.rank <= number && params.row.rank > 0 ? "" : "disabled"
          }
          getCellClassName={params => {
            if (
              !isHighlight ||
              !STERLING_HIGHLIGHT_COLUMNS.includes(params.field) ||
              params.value == null
            ) {
              return "";
            }
            if (!params.row[`${params.field}_rank`]) {
              return "";
            }
            const val = params.row[`${params.field}_rank`];
            return val > 0 ? `top-${val}` : `bottom-${-val}`;
          }}
          error={error || undefined}
          columnVisibilityModel={{
            category: false,
            sector: false,
            brand: false,
            pack_type: false,
            offer_type: false,
            value_sales_export: false,
            consumption_type: false,
            units_per_package: false,
            container_material: false,
            pack_size_litres: false
          }}
        />
      </Box>
    </Grid>
  );
};

Products.propTypes = {
  channel: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  handleBack: PropTypes.func,
  getOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  number: PropTypes.number,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  rankType: PropTypes.string,
  consumptionType: PropTypes.string,
  date: PropTypes.shape()
};

Products.defaultProps = {
  channel: "",
  environments: [],
  handleBack: () => {},
  getOptions: () => {},
  options: [],
  number: 0,
  isLoading: false,
  error: "",
  rankType: "",
  consumptionType: "",
  date: {}
};

const mapDispatchToProps = dispatch => ({
  getOptions: (channel, environments, rankType, consumptionType) =>
    dispatch(
      getProducts(channel, environments, "view", rankType, consumptionType)
    )
});

export default connect(null, mapDispatchToProps)(Products);

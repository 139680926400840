import React from "react";
import {
  Stack,
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  header,
  performance,
  brandBattles,
  rangeReview,
  logo
} from "../images";

const styles = {
  header: {
    p: 6,
    backgroundImage: `url(${header})`,
    backgroundRepeat: "no-repeat",
    borderRadius: "4px 4px 0 0"
  },
  headerButton: {
    mt: 4,
    color: "secondary.main",
    textTransform: "none",
    bgcolor: "white",
    "&:hover": {
      bgcolor: "white"
    }
  },
  section: { p: 6 },
  contrast: { bgcolor: "#f7f7f7" },
  listItem: { alignItems: "flex-start" },
  highlight: { color: "#171414" },
  footer: { color: "#736f6f" }
};

const About = () => (
  <Paper>
    {/* Header */}
    <Grid
      sx={styles.header}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item md={4}>
        <img
          src={logo}
          alt="Interrodata"
          style={{ transform: "scale(0.95)" }}
        />
      </Grid>
      <Grid item md={6}>
        <Typography variant="h2" gutterBottom color="white">
          Supercharge what you can do with your data
        </Typography>
        <Typography variant="body2" gutterBottom color="white">
          Interrodata Reports are here to simplify the understanding of business
          performance.
        </Typography>
        <Typography variant="body2" gutterBottom color="white">
          Interrodata intelligently analyses standard industry datasets and
          generates meaningful stories about what happened, and why.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={styles.headerButton}
          component={Link}
          to="/support"
          disableElevation
        >
          Talk to our Helpdesk or give Feedback
        </Button>
      </Grid>
    </Grid>
    <Stack>
      {/* Performance */}
      <Grid sx={styles.section} container>
        <Grid item md={7}>
          <Typography variant="h1" gutterBottom color="yellow">
            Performance Reports
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Detailed report about drivers of change, causal factors, key
            performing products and more...
          </Typography>
          <Typography variant="body2" gutterBottom>
            You have data about everything in your business – understanding all
            of it is nigh on impossible. Performance reports allow anyone to ask
            a question about a brand, category, sector or pack type.
          </Typography>
          <Box sx={{ mt: 6, mb: 1 }}>
            <Box
              component="span"
              sx={{ ...styles.contrast, borderRadius: 1, px: 5, py: 1 }}
            >
              <Typography
                component="span"
                fontWeight="medium"
                color="yellow.main"
              >
                Perfect for
              </Typography>
            </Box>
          </Box>
          <List>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <ArrowForward color="yellow" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      component="span"
                      sx={styles.highlight}
                    >
                      Marketing teams
                    </Typography>{" "}
                    to understand market trends & those areas that might need a
                    bit more TLC.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <ArrowForward color="yellow" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      component="span"
                      sx={styles.highlight}
                    >
                      Senior executives
                    </Typography>{" "}
                    to get a quick and holistic understanding of performance
                    without distracting the Insights team from high level
                    strategic work.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item md={5}>
          <img
            alt="performance"
            src={performance}
            justify="center"
            style={{ margin: 30 }}
          />
        </Grid>
      </Grid>
      {/* Brand Battles */}
      <Grid sx={{ ...styles.section, ...styles.contrast }} container>
        <Grid item md={5}>
          <img
            alt="brand battles"
            src={brandBattles}
            justify="center"
            style={{ margin: 30 }}
          />
        </Grid>
        <Grid item md={7}>
          <Typography variant="h1" gutterBottom color="orange">
            Brand Battles&trade;
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Empower your organization to act both before, and directly against
            your key competitors
          </Typography>
          <Typography variant="body2" gutterBottom>
            Be first off the line with Brand Battles.
            <br />
            <br />
            Ask IDA any question about the relative performance of your product,
            brand or even the whole organisation against your closest
            competition.
            <br />
            IDA will generate a rich report that outlines the key drivers of
            share change between the two products. She will even give you
            recommended actions to leave your rivals thinking &quot;how on earth
            did they know to do that!&quot;
          </Typography>
          <Box sx={{ mt: 6, mb: 1 }}>
            <Box
              component="span"
              sx={{ bgcolor: "white", borderRadius: 1, px: 5, py: 1 }}
            >
              <Typography
                component="span"
                fontWeight="medium"
                color="orange.main"
              >
                Perfect for
              </Typography>
            </Box>
          </Box>
          <List>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <ArrowForward color="orange" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      component="span"
                      sx={styles.highlight}
                    >
                      Sales teams
                    </Typography>{" "}
                    to use to prepare their top-level strategy, answer customer
                    questions on the fly and show retailers why your product
                    should take pride of place on the shelf.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      {/* Range Review */}
      <Grid sx={styles.section} container>
        <Grid item md={7}>
          <Typography variant="h1" gutterBottom color="primaryDark">
            Range Review Impact Assessments
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Report on performance of any range review you’ve undertaken – in
            minutes.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Range Reviews are a crucial part of the FMCG business process. Yet,
            clients told us that they barely had time to assess the previous
            Review, yet alone build strategies and recommendations for their
            customers.
            <br />
            This allows you to spend your time developing actionable,
            revenue-driving recommendations to customers rather than scrambling
            to merely explain what’s happened.
          </Typography>
          <Box sx={{ mt: 6, mb: 1 }}>
            <Box
              component="span"
              sx={{ ...styles.contrast, borderRadius: 1, px: 5, py: 1 }}
            >
              <Typography
                component="span"
                fontWeight="medium"
                color="primary.dark"
              >
                Perfect for
              </Typography>
            </Box>
          </Box>
          <List>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <ArrowForward color="primaryDark" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      component="span"
                      sx={styles.highlight}
                    >
                      Category teams
                    </Typography>{" "}
                    who are frustrated at consistently looking back, not
                    forward.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item md={5}>
          <img
            alt="range review"
            src={rangeReview}
            justify="center"
            style={{ margin: 30 }}
          />
        </Grid>
      </Grid>
      {/* Footer */}
      <Grid sx={{ ...styles.section, ...styles.contrast }} container>
        <Grid item md={12}>
          <Typography
            variant="body1"
            sx={styles.footer}
            gutterBottom
            align="center"
          >
            And more...
          </Typography>
          <Typography variant="h2" gutterBottom align="center">
            Solve real world problems that truly matter to your organization.
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  </Paper>
);

export default About;

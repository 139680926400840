import _ from "lodash";
import {
  GOTO_SLIDE,
  DOWNLOAD_REPORT_REQUEST,
  GENERATE_DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR
} from "../constants/actionConstants";
import { addNotification } from "./notification";
import { PPT_URL, API_KEY } from "../constants";
import logger from "../logger";

// hidden slide logic
function findSlide(slideIndex, report, direction, slideCount) {
  const isHidden = report[slideIndex].hidden;
  let slide = slideIndex;
  if (isHidden) {
    if (_.isEqual(direction, "prev")) {
      if (slideIndex - 1 > 0) {
        slide = findSlide(slideIndex - 1, report, "prev");
      }
    } else if (slideIndex + 1 < slideCount) {
      slide = findSlide(slideIndex + 1, report, "next", slideCount);
    } else {
      // if last slide is hidden, go back
      slide = findSlide(slideIndex, report, "prev");
    }
  }
  return slide;
}

export const goToSlide = (
  slideIndex,
  report,
  direction,
  slideCount,
  reportId,
  userId,
  story,
  client
) => {
  // to go to first slide
  if (slideIndex === 0) {
    return {
      type: GOTO_SLIDE,
      slideIndex
    };
  }
  const index = findSlide(slideIndex, report, direction, slideCount);
  const slideKey = report[index].slidekey;
  logger.info({
    date: new Date().toISOString(),
    action: "NAVIGATE_REPORT",
    report_id: reportId,
    slide_index: index + 1,
    slide_key: slideKey,
    total_slide_count: slideCount,
    user_id: userId,
    story,
    client,
    product: "ida"
  });
  return {
    type: GOTO_SLIDE,
    slideIndex: index
  };
};

function downloadReportRequest(id) {
  return {
    type: DOWNLOAD_REPORT_REQUEST,
    id
  };
}

function generateDownloadReportSuccess(url) {
  return {
    type: GENERATE_DOWNLOAD_REPORT_SUCCESS,
    url
  };
}

function downloadReportSuccess() {
  return {
    type: DOWNLOAD_REPORT_SUCCESS
  };
}

function downloadReportError(error) {
  return {
    type: DOWNLOAD_REPORT_ERROR,
    error
  };
}

// adapted from https://github.com/msn0/file-downloader/blob/master/file-downloader.js
const downloadFile = url => {
  try {
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", true);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  } catch (error) {
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const downloadPowerPoint =
  (reportId, story, userId, client, slideIndex, slideKey) => dispatch => {
    dispatch(downloadReportRequest(reportId));
    return fetch(PPT_URL, {
      method: "post",
      body: JSON.stringify({
        report_id: reportId,
        story,
        client
      }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          const { status, url } = res;
          if (status === "COMPLETE") {
            dispatch(generateDownloadReportSuccess(url));
            logger.info({
              date: new Date().toISOString(),
              action: "DOWNLOAD_REPORT",
              report_id: reportId,
              user_id: userId,
              download_url: url,
              story,
              client,
              slide_index: slideIndex + 1,
              slide_key: slideKey,
              product: "ida"
            });
            downloadFile(url).then(
              () => {
                dispatch(downloadReportSuccess());
              },
              downloadErr => {
                dispatch(
                  downloadReportError({
                    status: "DOWNLOAD_ERROR",
                    error: downloadErr,
                    message: "There was an error downloading the file."
                  })
                );
                dispatch(
                  addNotification(
                    "There was an error downloading the file",
                    "error"
                  )
                );
              }
            );
          } else if (status === "ERROR") {
            dispatch(downloadReportError(res));
            dispatch(addNotification(res.error_message, "error"));
          } else {
            dispatch(downloadReportError(res));
            dispatch(
              addNotification(
                "There was an error generating the download file",
                "error"
              )
            );
          }
        },
        err => {
          dispatch(downloadReportError(err));
        }
      );
  };

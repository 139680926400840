import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Slider,
  Box,
  Button,
  Tooltip
} from "@mui/material";
import { getWeightings, editSterling } from "../../actions";

const styles = {
  heading: { p: 1, bgcolor: "#e5e0df", border: "1px solid white" },
  slider: { px: 8, py: 0.2, bgcolor: "#f7f3f2", border: "1px solid white" }
};

const Weightings = props => {
  const { options, getOptions, edit, isLoading } = props;
  const [weightings, setWeightings] = useState([]);

  useEffect(() => {
    if (_.isEmpty(options)) {
      getOptions(options);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(options)) {
      setWeightings(options.details);
    }
  }, [options]);

  return (
    <Card sx={{ p: 2.5, width: 1 }}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item md={8}>
          <CardHeader
            title="Weightings"
            titleTypographyProps={{ variant: "h2", gutterBottom: true }}
            subheader={
              <Typography variant="body2" gutterBottom>
                This sheet allows you to edit the weightings given to each input
                of the model. You should increase or decrease importance of an
                input if you think it should impact the output of the model more
                or less than other inputs. By default all options have been
                assigned &apos;Standard&apos; weighting.
              </Typography>
            }
          />
        </Grid>
        <Grid item md={4}>
          <Box textAlign="right" sx={{ p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() =>
                edit(_.differenceBy(weightings, options.details), {
                  ...options,
                  details: weightings
                })
              }
              disabled={
                isLoading || options.length === 0 || weightings.length === 0
              }
            >
              Apply Changes
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CardContent>
        <Grid container>
          {/* title */}
          <Grid item container justifyContent="flex-end" alignItems="stretch">
            <Grid
              item
              xs={8}
              container
              justifyContent="space-around"
              alignItems="stretch"
            >
              <Grid item xs={2} sx={styles.heading}>
                <Typography variant="subtitle3" align="center">
                  Not important at all
                </Typography>
              </Grid>
              <Grid item xs={2} sx={styles.heading}>
                <Typography variant="subtitle3" align="center">
                  Not very important
                </Typography>
              </Grid>
              <Grid item xs={2} sx={styles.heading}>
                <Typography variant="subtitle3" align="center">
                  No change
                </Typography>
              </Grid>
              <Grid item xs={2} sx={styles.heading}>
                <Typography variant="subtitle3" align="center">
                  Important
                </Typography>
              </Grid>
              <Grid item xs={2} sx={styles.heading}>
                <Typography variant="subtitle3" align="center">
                  Very important
                </Typography>
              </Grid>
              <Grid item xs={2} sx={styles.heading}>
                <Typography variant="subtitle3" align="center">
                  Most important
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!_.isEmpty(options) &&
            options.categories.map(i => {
              const details = weightings.filter(j => j.parent === i);
              return (
                <Grid item container alignItems="center" key={i}>
                  <Grid item xs={4}>
                    <Grid container alignItems="stretch">
                      <Grid item xs={5} sx={styles.heading}>
                        <Typography variant="subtitle1" align="center">
                          {i}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Stack>
                          {details.map(j => (
                            <Tooltip title={j.description || ""} key={j.name}>
                              <Typography
                                variant="subtitle2"
                                sx={styles.heading}
                              >
                                {j.name}
                              </Typography>
                            </Tooltip>
                          ))}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Stack>
                      {details.map(j => (
                        <Box sx={styles.slider} key={j.name}>
                          <Slider
                            step={20}
                            size="small"
                            marks
                            value={j.weighting}
                            onChange={(e, val) =>
                              setWeightings(
                                weightings.map(k =>
                                  k.id === j.id ? { ...k, weighting: val } : k
                                )
                              )
                            }
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </CardContent>
    </Card>
  );
};

Weightings.propTypes = {
  options: PropTypes.shape(),
  getOptions: PropTypes.func,
  edit: PropTypes.func,
  isLoading: PropTypes.bool
};

Weightings.defaultProps = {
  options: {},
  getOptions: () => {},
  edit: () => {},
  isLoading: false
};

const mapStateToProps = state => ({
  options: state.sterling.weightings,
  isLoading: state.sterling.isLoading
});

const mapDispatchToProps = dispatch => ({
  getOptions: () => dispatch(getWeightings()),
  edit: (data, updatedData) =>
    dispatch(editSterling("weightings", data, updatedData))
});

export default connect(mapStateToProps, mapDispatchToProps)(Weightings);

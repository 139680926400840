import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import LeftMenu from "./LeftMenu";
import NewPerformanceReport from "../NewPerformanceReport";
import NewRangeReview from "../NewRangeReview";
import NewBrandbattlesReport from "../NewBrandBattlesReport";
import PerformanceHistory from "../PerformanceHistory";
import RangeReviewHistory from "../RangeReviewHistory";
import BrandBattlesHistory from "../BrandBattlesHistory";
import "./ReportsReviews.css";

const ReportsReviews = () => {
  const { type } = useParams();
  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <LeftMenu type={type} />
      </Grid>
      <Grid item md={9}>
        {(type === "performance" && (
          <PerformanceHistory story={["ida", "cga"]} />
        )) ||
          (type === "new-ih-performance" && (
            <NewPerformanceReport story="ida" dataSet="nielsen" />
          )) ||
          (type === "new-ooh-performance" && (
            <NewPerformanceReport story="cga" dataSet="cga" />
          )) ||
          (type === "rangereview" && <RangeReviewHistory story="prr" />) ||
          (type === "new-rangereview" && (
            <NewRangeReview story="prr" dataSet="nielsen" />
          )) ||
          (type === "brandbattles" && <BrandBattlesHistory story="bb" />) ||
          (type === "new-brandbattles" && (
            <NewBrandbattlesReport story="bb" dataSet="nielsen_secondary" />
          ))}
      </Grid>
    </Grid>
  );
};

export default ReportsReviews;

import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Button
} from "@mui/material";
import { sterling } from "../../images";
import { getSheets } from "../../actions";
import Sheets from "./Sheets";

const SKUEditing = props => {
  const { getSKUSheets, sheets } = props;
  useEffect(() => {
    getSKUSheets();
  }, []);
  return (
    <Card sx={{ p: 2.5, width: 1 }}>
      <Grid item md={8}>
        <CardHeader
          title="Shopper-Based Ranging"
          titleTypographyProps={{ variant: "h2", gutterBottom: true }}
          subheader={
            <Fragment>
              <Typography variant="body2" fontWeight="bold" color="secondary">
                Welcome to the Edit area of the Ranging Prioritsation Tool.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Here you can make changes to the importance of SKUs by
                environment. This is here to combine your expertise with the
                data to produce the best output possible, with this output to be
                used throughout CCEP.
              </Typography>
            </Fragment>
          }
        />
      </Grid>
      <CardContent sx={{ display: "flex" }}>
        {sheets.length === 0 ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ py: 4 }}
          >
            <Box sx={{ p: 1, mb: 1 }}>
              <img alt="sterling" src={sterling} />
            </Box>
            <Typography variant="h5" color="primary.dark" gutterBottom>
              You have not added any sheets yet!
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Would you like to add a sheet now?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/new-prioritisation"
              disableElevation
            >
              Create new sheet
            </Button>
          </Grid>
        ) : (
          <Grid container sx={{ mt: -2 }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/new-prioritisation"
              disableElevation
            >
              Create new sheet
            </Button>
            <Sheets sheets={sheets} />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

SKUEditing.propTypes = {
  getSKUSheets: PropTypes.func,
  sheets: PropTypes.arrayOf(PropTypes.shape())
};

SKUEditing.defaultProps = {
  getSKUSheets: () => {},
  sheets: []
};

const mapStateToProps = state => ({
  sheets: state.sterling.sheets
});

const mapDispatchToProps = dispatch => ({
  getSKUSheets: () => dispatch(getSheets())
});

export default connect(mapStateToProps, mapDispatchToProps)(SKUEditing);

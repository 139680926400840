import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "cookies-js";
import { TIMEOUT } from "../constants";

import search from "./search";
import presentation from "./presentation";
import report from "./report";
import user from "./user";
import data from "./data";
import notification from "./notification";
import lookout from "./lookout";
import sterling from "./sterling";

const userPersistConfig = {
  key: "user",
  storage: new CookieStorage(Cookies, { expiration: { default: TIMEOUT } }),
  whitelist: ["user", "isUser"]
};

export default combineReducers({
  search,
  presentation,
  report,
  user: persistReducer(userPersistConfig, user),
  data,
  notification,
  lookout,
  sterling
});

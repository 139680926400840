import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid, List, ListItem, Button, Paper, Typography } from "@mui/material";

const TransferList = props => {
  const { options, right, setRight, left, setLeft } = props;
  const [checked, setChecked] = useState([]);
  useEffect(() => {
    setLeft(_.differenceBy(options, right, "sku"));
  }, [options]);
  const leftChecked = _.intersectionBy(checked, left, "sku");
  const rightChecked = _.intersectionBy(checked, right, "sku");

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(_.differenceBy(left, leftChecked, "sku"));
    setChecked(_.differenceBy(checked, leftChecked, "sku"));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(_.differenceBy(right, rightChecked, "sku"));
    setChecked(_.differenceBy(checked, rightChecked, "sku"));
  };

  const customList = items => (
    <Paper sx={{ width: 1, height: 300, overflow: "auto" }}>
      <List dense>
        {items.map(i => {
          const selected = checked.indexOf(i) !== -1;
          return (
            <ListItem
              key={i.sku}
              onClick={handleToggle(i)}
              sx={{
                bgcolor: selected ? "primary.dark" : "auto",
                cursor: "pointer"
              }}
            >
              <Typography variant="body2" color={selected ? "white" : "auto"}>
                {i.sku} - {i.name}
              </Typography>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item md={5.5}>
        {customList(left)}
      </Grid>
      <Grid item md={1}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item md={5.5}>
        {customList(right)}
      </Grid>
    </Grid>
  );
};

TransferList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  right: PropTypes.arrayOf(PropTypes.shape()),
  setRight: PropTypes.func,
  left: PropTypes.arrayOf(PropTypes.shape()),
  setLeft: PropTypes.func
};

TransferList.defaultProps = {
  options: [],
  right: [],
  setRight: () => {},
  left: [],
  setLeft: () => {}
};

export default TransferList;

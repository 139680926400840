import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  ListItem,
  Button,
  Typography,
  FormHelperText,
  FormControl,
  FormLabel,
  Box
} from "@mui/material";
import { BB_PRODUCTS } from "../../constants";
import { AdvancedSearch, SearchBar } from "../SharedComponents";
import Brands from "./Brands";
import { InfoIcon } from "../Icons";
import {
  enterSelectedTerm,
  deleteTerm,
  filterStaticData,
  removeFilterTerm
} from "../../actions";

const Context = props => {
  const {
    filteredSubsections,
    searchTerms,
    filterTerms,
    isFilteringData,
    searchEngine,
    story,
    isFilteredData,
    onChange,
    contextError,
    clearErrors,
    client,
    placeholder
  } = props;
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const searchOptions = isFilteredData
    ? searchEngine._docs.filter(i => !BB_PRODUCTS.includes(i.subsection))
    : [];
  const terms = searchTerms.filter(
    i => i.table === "what" && !i.subsection.startsWith("product_")
  );
  const productA = searchTerms.find(s => s.subsection.startsWith("product_a"));
  const productB = searchTerms.find(s => s.subsection.startsWith("product_b"));
  return (
    <Fragment>
      <Brands productA={productA} productB={productB} />
      <ListItem>
        <FormControl
          component="fieldset"
          className="fieldset"
          sx={{ p: 2, mb: 2 }}
          fullWidth
        >
          <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
            <Typography color="textPrimary" fontWeight="bold">
              Define Product Scope
            </Typography>
          </FormLabel>
          <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
            Enter the category/sector and any other attributes that you would
            like to compare these products within - or select Total NARTD
          </Typography>
          <Box sx={{ mt: -1, px: 1 }}>
            <SearchBar
              searchOptions={searchOptions}
              value={terms}
              onChange={(e, val, reason) => {
                if (_.isEqual(reason, "selectOption")) {
                  const toAdd = val[val.length - 1];
                  onChange(toAdd, reason, story, filterTerms, client);
                } else if (_.isEqual(reason, "removeOption")) {
                  const toDelete = _.difference(terms, val)[0];
                  onChange(toDelete, reason, story, filterTerms, client);
                } else if (_.isEqual(reason, "clear")) {
                  onChange(terms, reason, story, filterTerms, client);
                }
                clearErrors();
              }}
              searchEngine={searchEngine}
              disabled={!isFilteredData}
              multiple
              placeholder={terms.length === 0 ? placeholder : ""}
            />
            <ListItem disablePadding>
              <InfoIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="subtitle2" color="primary">
                What you select here will become the scope of the report. You
                can enter multiple attributes.
              </Typography>
            </ListItem>
            <FormHelperText error>{contextError}</FormHelperText>
          </Box>
          {isAdvancedSearch ? (
            <Fragment>
              <Box sx={{ mt: 3 }}>
                <AdvancedSearch
                  searchTerms={searchTerms.filter(s => s.table === "what")}
                  options={filteredSubsections
                    .filter(s => s.table === "what")
                    .filter(i => !BB_PRODUCTS.includes(i.name))}
                  filterTerms={filterTerms}
                  story={story}
                  isFilteringData={isFilteringData}
                  clearErrors={clearErrors}
                />
              </Box>
              <Box textAlign="center">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setIsAdvancedSearch(!isAdvancedSearch)}
                >
                  Close Advanced Search
                </Button>
              </Box>
            </Fragment>
          ) : (
            <Box sx={{ px: 1, mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Can&apos;t find what you&apos;re looking for?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setIsAdvancedSearch(!isAdvancedSearch)}
              >
                Advanced Search
              </Button>
            </Box>
          )}
        </FormControl>
      </ListItem>
    </Fragment>
  );
};

Context.propTypes = {
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  isFilteringData: PropTypes.bool,
  story: PropTypes.string,
  searchEngine: PropTypes.shape(),
  isFilteredData: PropTypes.bool,
  onChange: PropTypes.func,
  contextError: PropTypes.string,
  clearErrors: PropTypes.func,
  client: PropTypes.string,
  placeholder: PropTypes.string
};

Context.defaultProps = {
  filteredSubsections: [],
  searchTerms: [],
  filterTerms: [],
  isFilteringData: false,
  story: "",
  searchEngine: {},
  isFilteredData: false,
  onChange: () => {},
  contextError: "",
  clearErrors: () => {},
  client: "",
  placeholder: ""
};

const mapDispatchToProps = dispatch => ({
  onChange: (selected, e, story, filterTerms, client) => {
    if (_.isEqual(e, "selectOption")) {
      const term = selected.name.value
        ? { ...selected, name: selected.name.value, story }
        : { ...selected, story };
      dispatch(enterSelectedTerm(term, story));
      dispatch(filterStaticData(term, filterTerms, story, client));
    } else if (_.isEqual(e, "removeOption")) {
      dispatch(deleteTerm(selected, story));
      dispatch(removeFilterTerm(selected, filterTerms, story, client));
    } else if (_.isEqual(e, "clear")) {
      selected.forEach(i => {
        dispatch(deleteTerm(i, story));
        dispatch(removeFilterTerm(i, filterTerms, story, client));
      });
    }
  }
});

export default connect(null, mapDispatchToProps)(Context);

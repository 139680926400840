import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  List,
  Typography,
  ListItem,
  FormHelperText,
  Button,
  Grid
} from "@mui/material";
import { InfoIcon } from "../Icons";
import { SearchBar } from "../SharedComponents";
import {
  deleteTerm,
  filterStaticData,
  clearSearchHints,
  enterSelectedTerm,
  removeFilterTerm,
  checkSearchHints,
  getContext
} from "../../actions";

const checkForErrors = searchTerms => {
  let err = "";
  if (searchTerms.filter(t => t.table === "what").length === 0) {
    err = "Please select which product you are interested in.";
  } else if (searchTerms.filter(t => t.table === "where").length === 0) {
    err = "Please select which retailer or channel you are interested in.";
  } else if (searchTerms.filter(t => t.table === "when").length === 0) {
    err = "Please select which time period you are interested in.";
  } else if (searchTerms.filter(t => t.table === "measure").length === 0) {
    err = "Please select which measure you are interested in.";
  }
  return err;
};

const SearchInput = props => {
  const {
    searchTerms,
    filterTerms,
    searchEngine,
    filteredSearchEngine,
    story,
    isFilteredData,
    onChange,
    client,
    placeholder,
    allTerms,
    isStaticData,
    hint,
    isFilteringData,
    searchHint,
    handleNext,
    getProductContext,
    removeTerm
  } = props;
  return (
    <List sx={{ m: 2 }}>
      <Typography variant="subtitle2">
        Describe what you would like to see. Add a product, market, time period,
        and metric.
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={10} xs={12} sx={{ mt: -1 }}>
          <SearchBar
            searchOptions={
              isStaticData
                ? (isFilteredData && filteredSearchEngine._docs) ||
                  searchEngine._docs
                : []
            }
            value={searchTerms}
            onChange={(e, val, reason) => {
              if (_.isEqual(reason, "selectOption")) {
                const toAdd = val[val.length - 1];
                if (toAdd.table !== "what" && searchTerms.length > 0) {
                  // remove the existing option
                  const toDelete = searchTerms.find(
                    i => i.table === toAdd.table
                  );
                  if (toDelete) {
                    removeTerm(toDelete, story);
                  }
                }
                onChange(toAdd, reason, story, filterTerms, client, hint);
              } else if (_.isEqual(reason, "removeOption")) {
                const toDelete = _.difference(searchTerms, val)[0];
                onChange(toDelete, reason, story, filterTerms, client);
              }
            }}
            searchEngine={isFilteredData ? filteredSearchEngine : searchEngine}
            disabled={!isStaticData || isFilteringData}
            placeholder={searchTerms.length === 0 ? placeholder : ""}
            disableClearable
            multiple
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            onClick={() => {
              if (allTerms) {
                const product = searchTerms.filter(s => s.table === "what");
                getProductContext(product, story, client);
                handleNext();
              } else {
                const err = checkForErrors(searchTerms);
                searchHint(err);
              }
            }}
          >
            Review & Run
          </Button>
        </Grid>
      </Grid>
      <ListItem disablePadding>
        <InfoIcon color="primary" sx={{ mr: 1 }} />
        <Typography variant="subtitle2" color="primary">
          We recommend that you only enter the distributor or brand here and add
          the product scope on the review screen.
        </Typography>
      </ListItem>
      <FormHelperText error>{hint}</FormHelperText>
    </List>
  );
};

SearchInput.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  isStaticData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  client: PropTypes.string,
  placeholder: PropTypes.string,
  searchEngine: PropTypes.shape(),
  filteredSearchEngine: PropTypes.shape(),
  allTerms: PropTypes.bool,
  isFilteredData: PropTypes.bool,
  onChange: PropTypes.func,
  hint: PropTypes.string,
  searchHint: PropTypes.func,
  handleNext: PropTypes.func,
  getProductContext: PropTypes.func,
  removeTerm: PropTypes.func
};

SearchInput.defaultProps = {
  searchTerms: [],
  isStaticData: false,
  isFilteringData: false,
  filterTerms: [],
  story: "",
  client: "",
  placeholder: "",
  searchEngine: {},
  filteredSearchEngine: {},
  allTerms: false,
  isFilteredData: false,
  onChange: () => {},
  hint: "",
  searchHint: () => {},
  handleNext: () => {},
  getProductContext: () => {},
  removeTerm: () => {}
};

const mapDispatchToProps = dispatch => ({
  removeTerm: (term, story) => {
    dispatch(deleteTerm(term, story));
  },
  onChange: (selected, e, story, filterTerms, client, hint) => {
    if (_.isEqual(e, "selectOption")) {
      const term = selected.name.value
        ? { ...selected, name: selected.name.value, story }
        : { ...selected, story };
      dispatch(enterSelectedTerm(term, story));
      if (selected.table === "what") {
        dispatch(filterStaticData(term, filterTerms, story, client));
      }
      // remove hints
      let table = "";
      switch (selected.table) {
        case "what":
          table = "product";
          break;
        case "where":
          table = "retailer";
          break;
        case "when":
          table = "time";
          break;
        case "measure":
          table = "measure";
          break;
        default:
          table = "";
      }
      if (hint.includes(table)) {
        dispatch(clearSearchHints());
      }
    } else if (_.isEqual(e, "removeOption")) {
      dispatch(deleteTerm(selected, story));
      if (selected.table === "what") {
        dispatch(removeFilterTerm(selected, filterTerms, story, client));
      }
    }
  },
  searchHint: hint => {
    dispatch(checkSearchHints(hint));
  },
  getProductContext: (terms, story, client) => {
    dispatch(getContext(terms, [], "product", story, client, true));
  }
});

export default connect(null, mapDispatchToProps)(SearchInput);

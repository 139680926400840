import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import { removeNotification } from "./actions";

const Notification = props => {
  const { notification, handleClose } = props;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: notification.vertical || "bottom",
        horizontal: notification.horizontal || "center"
      }}
      open={notification.isVisible}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert severity={notification.level || "info"} onClose={handleClose}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape(),
  handleClose: PropTypes.func
};

Notification.defaultProps = {
  notification: {},
  handleClose: () => {}
};

function mapStateToProps(state) {
  return {
    notification: state.notification
  };
}

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(removeNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

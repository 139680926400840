import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  ListItem,
  Collapse,
  Button,
  Box,
  FormHelperText,
  FormControl,
  FormLabel,
  Typography
} from "@mui/material";
import { enterSelectedTerm, deleteTerm, clearSearchHints } from "../../actions";
import { customFilter, customStyles } from "../../utils";
import { getStoryConfig } from "../../config";
import CustomDate from "../CustomDate";

const WhenSection = props => {
  const { options, selected, onSelect, hint, story, dataDate, clearErrors } =
    props;
  const [isCustomDate, setIsCustomDate] = useState(false);
  const optionList = options
    .map(option =>
      option.keywords.map(kw => ({ name: kw, subsection: option.name }))
    )
    .reduce((array, option) => array.concat(option), []);
  const optionsToUse = [
    ...optionList.map(option => ({
      value: `${option.subsection}--${option.name.period}--${option.name.date}`,
      label: `${option.name.period} ${option.name.date}`
    })),
    {
      value: "custom",
      label: "Choose range on calendar"
    }
  ];
  const selectedValue = selected
    ? {
        value: `${selected.subsection}--${selected.name.period}--${selected.name.date}`,
        label: `${selected.name.period} ${selected.name.date}`
      }
    : "no-value";
  useEffect(() => {
    if (selected && !selected.name.period) {
      setIsCustomDate(true);
    }
  }, []);
  const { dataSet } = getStoryConfig(story);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            When
          </Typography>
        </FormLabel>
        <Collapse in={!isCustomDate}>
          <ListItem sx={{ px: 1 }}>
            <Select
              styles={customStyles}
              className="smallSelect textInput"
              onChange={e => {
                if (e.value === "custom") {
                  setIsCustomDate(true);
                  onSelect("no-value", selected, story, hint);
                } else {
                  onSelect(e.value, selected, story, hint);
                }
                clearErrors();
              }}
              options={optionsToUse}
              placeholder="Type or scroll to select a Range"
              value={selectedValue}
              backspaceRemovesValue={false}
              filterOption={customFilter}
            />
          </ListItem>
          <FormHelperText error>
            {hint && hint.includes("time") ? hint : null}
          </FormHelperText>
        </Collapse>
        <Collapse in={isCustomDate} mountOnEnter unmountOnExit>
          <CustomDate
            story={story}
            selectedPeriod={
              selected && !selected.name.period ? selected : undefined
            }
            dataDate={dataDate[dataSet]}
            isMonth={story === "cga"}
          />
          <FormHelperText error>
            {hint && hint.includes("time") ? hint : null}
          </FormHelperText>
          <Box textAlign="center" sx={{ mt: 2 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsCustomDate(false);
                onSelect("no-value", selected, story, hint);
              }}
            >
              Standard Periods
            </Button>
          </Box>
        </Collapse>
      </FormControl>
    </ListItem>
  );
};

WhenSection.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  selected: PropTypes.shape(),
  onSelect: PropTypes.func,
  hint: PropTypes.string,
  story: PropTypes.string,
  dataDate: PropTypes.shape(),
  clearErrors: PropTypes.func
};

WhenSection.defaultProps = {
  options: [],
  selected: undefined,
  onSelect: () => {},
  hint: "",
  story: "",
  dataDate: {},
  clearErrors: () => {}
};

const mapStateToProps = state => ({
  dataDate: state.data.date
});

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story, hint) => {
    if (oldValue !== undefined) {
      dispatch(deleteTerm(oldValue, story));
    }
    if (selectedValue !== "no-value") {
      const value = selectedValue.split("--");
      dispatch(
        enterSelectedTerm(
          {
            name: { period: value[1], date: value[2] },
            subsection: value[0],
            table: "when",
            story
          },
          story
        )
      );
      if (hint && hint.includes("time")) {
        dispatch(clearSearchHints());
      }
    }
  }
});

export { WhenSection as WhenSectionUnconnected };
export default connect(mapStateToProps, mapDispatchToProps)(WhenSection);

import React from "react";
import PropTypes from "prop-types";
import ReportImage from "./ReportImage";

const getTitleTemplate = client => (
  <div>
    <ReportImage
      layout={{
        height: 100,
        left: 12.4,
        top: 0,
        width: 87.7
      }}
      data={`/templates/${client}/bb_background_title.png`}
    />
    <ReportImage
      layout={{
        height: 36.2,
        left: 0,
        top: 63.8,
        width: 25
      }}
      data="/templates/bb-logo.png"
    />
    <ReportImage
      layout={{
        height: 14.1,
        left: 3.9,
        top: 5.8,
        width: 28.1
      }}
      data={`/templates/${client}/bb_insights.png`}
    />
  </div>
);

const getSectionTemplate = client => (
  <div>
    <ReportImage
      layout={{
        height: 100,
        left: 0,
        top: 0,
        width: 100
      }}
      data={`/templates/${client}/bb_background_section.png`}
    />
    <ReportImage
      layout={{
        height: 7.9,
        left: 0.6,
        top: 91.4,
        width: 11.5
      }}
      data="/templates/logo-white.png"
    />
    <ReportImage
      layout={{
        height: 11.8,
        left: 74.3,
        top: 83.7,
        width: 23.5
      }}
      data={`/templates/${client}/bb_insights_white.png`}
    />
  </div>
);

const getDefaultTemplate = client => (
  <div>
    <ReportImage
      layout={{
        height: 9.9,
        left: 88.2,
        top: 4.6,
        width: 9.5
      }}
      data={`/templates/${client}/logo1.png`}
    />
    <ReportImage
      layout={{
        height: 21.3,
        left: 0,
        top: 78.7,
        width: 100
      }}
      data={`/templates/${client}/bb_footer.png`}
    />
    <ReportImage
      layout={{
        height: 6.6,
        left: 0.4,
        top: 92.7,
        width: 9.7
      }}
      data="/templates/logo-grayscale.png"
    />
    <ReportImage
      layout={{
        height: 8.2,
        left: 82.6,
        top: 89.3,
        width: 16.3
      }}
      data={`/templates/${client}/bb_insights_white.png`}
    />
  </div>
);

const ReportTemplate = props => {
  const { activeSlideIndex, client, story, slideKey } = props;
  if (story === "bb") {
    return (
      <div className="ReportTemplate">
        {/* title */}
        {activeSlideIndex === 0 && getTitleTemplate(client)}
        {/* section header */}
        {slideKey.includes("section") && getSectionTemplate(client)}
        {/* default */}
        {activeSlideIndex > 0 &&
          !slideKey.includes("section") &&
          getDefaultTemplate(client)}
      </div>
    );
  }
  return (
    <div className="ReportTemplate">
      <div>
        <ReportImage
          layout={{
            height: 9.9,
            left: 88.2,
            top: 4.1,
            width: 9.5
          }}
          data={`/templates/${client}/logo1.png`}
        />
        <ReportImage
          layout={{
            height: 7.38,
            left: 1.16,
            top: 92.18,
            width: 7.38
          }}
          data="/templates/logo-cropped.png"
        />
      </div>
    </div>
  );
};

ReportTemplate.propTypes = {
  activeSlideIndex: PropTypes.number,
  client: PropTypes.string,
  story: PropTypes.string,
  slideKey: PropTypes.string
};

ReportTemplate.defaultProps = {
  activeSlideIndex: 0,
  client: "",
  story: "",
  slideKey: ""
};

export default ReportTemplate;

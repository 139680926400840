import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  Tooltip,
  IconButton,
  TextField
} from "@mui/material";
import {
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import { DeleteIcon } from "../Icons";
import { table } from "../../images";

export const getColumns = (setIsDeleteOpen, setSelected) => [
  {
    field: "sku",
    headerName: "SKU",
    flex: 1,
    sortable: false,
    headerAlign: "center",
    renderCell: params => {
      const { value } = params;
      return (
        <Stack direction="row" alignItems="center">
          <IconButton
            size="small"
            onClick={() => {
              setIsDeleteOpen(true);
              setSelected(params);
            }}
          >
            <DeleteIcon sx={{ fontSize: 14 }} />
          </IconButton>
          <Typography variant="body2">{value}</Typography>
        </Stack>
      );
    }
  },
  {
    field: "name",
    headerName: "Product Name",
    flex: 2.5,
    sortable: false,
    headerAlign: "center",
    renderCell: params => {
      const { value } = params;
      return (
        <Tooltip title={value}>
          <Typography variant="body2" noWrap>
            {value}
          </Typography>
        </Tooltip>
      );
    }
  },
  {
    field: "importance",
    headerName: "Strategic / Customer Importance",
    editable: true,
    flex: 0.75,
    type: "number",
    sortable: false,
    renderEditCell: params => {
      const { id, value, api, field } = params;
      return (
        <TextField
          value={value}
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 0, max: 3 } }}
          onChange={e =>
            api.setEditCellValue(
              { id, field, value: Number(e.target.value) },
              e
            )
          }
        />
      );
    }
  },
  {
    field: "sustainability",
    headerName: "Sustainable Score",
    editable: true,
    flex: 0.75,
    type: "number",
    sortable: false,
    renderEditCell: params => {
      const { id, value, api, field } = params;
      return (
        <TextField
          value={value}
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          onChange={e =>
            api.setEditCellValue(
              { id, field, value: Number(e.target.value) },
              e
            )
          }
        />
      );
    }
  },
  {
    field: "sector_projection",
    headerName: "Sector Performance / Projection",
    flex: 0.75,
    type: "number",
    sortable: false
  },
  {
    field: "ic_fc_projection",
    headerName: "IC/FC Performance / Projection",
    flex: 0.75,
    type: "number",
    sortable: false
  },
  {
    field: "sugar_projection",
    headerName: "Sugar Content Performance / Projection",
    flex: 0.75,
    type: "number",
    sortable: false
  }
];

export const NoRowsOverlay = props => {
  const { handleClick } = props;
  return (
    <GridOverlay>
      <Stack alignItems="center" spacing={1}>
        <Box sx={{ p: 1, mb: 1 }}>
          <img alt="table" src={table} />
        </Box>
        <Stack alignItems="center">
          <Typography
            variant="h5"
            color="primary.dark"
            align="center"
            gutterBottom
          >
            Get started by adding products to the sheet
          </Typography>
          <Typography variant="body2" gutterBottom>
            Would you like to add a product now?
          </Typography>
        </Stack>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item md={6}>
            <Button
              variant="outlined"
              color="primaryDark"
              fullWidth
              onClick={handleClick}
            >
              Add SKU
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </GridOverlay>
  );
};

export const Footer = props => {
  const { handleClick } = props;
  return (
    <Grid container alignItems="center" spacing={2} sx={{ p: 2 }}>
      <Grid item md={3}>
        <Button
          variant="outlined"
          color="primaryDark"
          fullWidth
          onClick={handleClick}
        >
          Add SKU
        </Button>
      </Grid>
    </Grid>
  );
};

export const Toolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

NoRowsOverlay.propTypes = {
  handleClick: PropTypes.func
};

NoRowsOverlay.defaultProps = {
  handleClick: () => {}
};

Footer.propTypes = {
  handleClick: PropTypes.func
};

Footer.defaultProps = {
  handleClick: () => {}
};

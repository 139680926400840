import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  FormControl,
  TextField,
  Button,
  FormHelperText,
  Collapse,
  Autocomplete,
  createFilterOptions,
  ListItem,
  Box
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Loader } from "../SharedComponents";
import { createUser, getDepartments, addDepartment } from "../../actions";

const filter = createFilterOptions();

const CreateAccount = props => {
  const {
    error,
    message,
    submitCreateUserForm,
    isLoading,
    onClose,
    client,
    departments,
    getDepts,
    addDept,
    subscriptionError,
    subscriptionMessage
  } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [department, setDepartment] = useState("");
  useEffect(() => getDepts(client), []);

  const reset = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setDepartment("");
  };

  return (
    <Card sx={{ m: 2, p: 2 }}>
      <CardHeader
        titleTypographyProps={{
          variant: "h4"
        }}
        avatar={
          <IconButton
            color="secondary"
            onClick={() => {
              onClose();
              reset();
            }}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        title="Create Account"
      />
      <Grid item md={6} sx={{ m: 2 }}>
        <form>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Name"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Confirm password"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <Collapse in={!email.includes("@interrodata.com")}>
            <ListItem sx={{ mb: 1 }}>
              <FormControl fullWidth>
                <Autocomplete
                  value={department}
                  freeSolo
                  onChange={(e, newValue) => {
                    if (typeof newValue === "string") {
                      setDepartment(newValue);
                    } else if (newValue && newValue.newOption) {
                      // Create a new value from the user input
                      setDepartment(newValue.newOption);
                      addDept(newValue.newOption, client);
                    } else {
                      setDepartment(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        newOption: params.inputValue,
                        label: `Add "${params.inputValue}"`
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={option => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.newOption) {
                      return option.label;
                    }
                    // Regular option
                    return option;
                  }}
                  options={departments}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Department"
                      type="text"
                      value={department}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </ListItem>
          </Collapse>
          <ListItem sx={{ mb: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <Grid item md={6}>
                <Box textAlign="left">
                  <Button
                    variant="contained"
                    color="primary"
                    type="sumbit"
                    disableElevation
                    onClick={e => {
                      e.preventDefault();
                      submitCreateUserForm(
                        name,
                        email,
                        password,
                        confirmPassword,
                        department,
                        client
                      );
                      // if success
                      if (message.length > 0) {
                        reset();
                      }
                    }}
                  >
                    Create account
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box textAlign="right">
                  <Button
                    color="primary"
                    type="cancel"
                    disableElevation
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                      reset();
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
          {error.length > 0 ? (
            <FormHelperText error sx={{ textAlign: "center" }}>
              {error.startsWith("ERROR:") ? error.substring(12) : error}
            </FormHelperText>
          ) : null}
          {subscriptionError.length > 0 ? (
            <FormHelperText error sx={{ textAlign: "center" }}>
              {subscriptionError}
            </FormHelperText>
          ) : null}
          {message.length > 0 ? (
            <FormHelperText sx={{ textAlign: "center" }}>
              {message}
            </FormHelperText>
          ) : null}
          {subscriptionMessage.length > 0 ? (
            <FormHelperText sx={{ textAlign: "center" }}>
              {subscriptionMessage}
            </FormHelperText>
          ) : null}
        </form>
        {isLoading ? <Loader /> : null}
      </Grid>
    </Card>
  );
};

CreateAccount.propTypes = {
  submitCreateUserForm: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  client: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.string),
  getDepts: PropTypes.func,
  addDept: PropTypes.func,
  subscriptionError: PropTypes.string,
  subscriptionMessage: PropTypes.string
};

CreateAccount.defaultProps = {
  submitCreateUserForm: () => {},
  error: "",
  message: "",
  isLoading: false,
  onClose: () => {},
  client: "",
  departments: [],
  getDepts: () => {},
  addDept: () => {},
  subscriptionError: "",
  subscriptionMessage: ""
};

const mapDispatchToProps = dispatch => ({
  submitCreateUserForm: (
    name,
    email,
    password,
    confirmPassword,
    department,
    client
  ) => {
    dispatch(
      createUser(name, email, password, confirmPassword, department, client)
    );
  },
  getDepts: client => dispatch(getDepartments(client)),
  addDept: (dept, client) => dispatch(addDepartment(dept, client))
});

export default connect(null, mapDispatchToProps)(CreateAccount);

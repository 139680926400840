import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const PresentationNav = props => {
  const { slideIndex, reportLength } = props;
  return (
    <Typography
      color="primary.dark"
      id="presentation-nav-container"
      align="center"
      sx={{ py: 2 }}
    >
      <span id="presentation-current-slide-number">{slideIndex + 1}</span> /{" "}
      <span id="presentation-total-slide-number">{reportLength}</span>
    </Typography>
  );
};

PresentationNav.propTypes = {
  slideIndex: PropTypes.number,
  reportLength: PropTypes.number
};

PresentationNav.defaultProps = {
  slideIndex: 0,
  reportLength: 0
};

export default PresentationNav;

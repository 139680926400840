import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  Collapse,
  Button,
  Box,
  FormControl,
  FormLabel,
  Typography
} from "@mui/material";
import { AdvancedSearch } from "../SharedComponents";

const WhatSection = props => {
  const {
    options,
    searchTerms,
    filterTerms,
    hint,
    story,
    isFilteringData,
    client
  } = props;
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            What
          </Typography>
        </FormLabel>
        <Collapse in={!isAdvancedSearch}>
          <Box textAlign="center" sx={{ mt: -1 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(true);
              }}
            >
              Advanced search
            </Button>
          </Box>
        </Collapse>
        <Collapse in={isAdvancedSearch}>
          <Box textAlign="center" sx={{ mt: -1 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
          <AdvancedSearch
            searchTerms={searchTerms}
            options={options}
            filterTerms={filterTerms}
            story={story}
            hint={hint}
            isFilteringData={isFilteringData}
            client={client}
          />
          <Box textAlign="center">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
        </Collapse>
      </FormControl>
    </ListItem>
  );
};

WhatSection.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  options: PropTypes.arrayOf(PropTypes.shape()),
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  hint: PropTypes.string,
  story: PropTypes.string,
  isFilteringData: PropTypes.bool,
  client: PropTypes.string
};

WhatSection.defaultProps = {
  searchTerms: [],
  options: [],
  filterTerms: [],
  hint: "",
  story: "",
  isFilteringData: false,
  client: ""
};

export default WhatSection;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  FormControl,
  TextField,
  Button,
  FormHelperText,
  ListItem,
  Box
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Loader } from "../SharedComponents";
import { resetPassword } from "../../actions";

const ResetPassword = props => {
  const { error, message, submitResetPasswordForm, isLoading, onClose } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const reset = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <Card sx={{ m: 2, p: 2 }}>
      <CardHeader
        titleTypographyProps={{
          variant: "h4"
        }}
        avatar={
          <IconButton
            color="secondary"
            onClick={() => {
              onClose();
              reset();
            }}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        title="Reset Password"
      />
      <Grid item md={6} sx={{ m: 2 }}>
        <form>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Confirm password"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <Grid item md={6}>
                <Box textAlign="left">
                  <Button
                    variant="contained"
                    color="primary"
                    type="sumbit"
                    disableElevation
                    onClick={e => {
                      e.preventDefault();
                      submitResetPasswordForm(email, password, confirmPassword);
                      // if success
                      if (message.length > 0) {
                        reset();
                      }
                    }}
                  >
                    Reset password
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box textAlign="right">
                  <Button
                    color="primary"
                    type="cancel"
                    disableElevation
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                      reset();
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
          {error.length > 0 ? (
            <FormHelperText error sx={{ textAlign: "center" }}>
              {error.startsWith("ERROR:") ? error.substring(12) : error}
            </FormHelperText>
          ) : null}
          {message.length > 0 ? (
            <FormHelperText sx={{ textAlign: "center" }}>
              {message}
            </FormHelperText>
          ) : null}
        </form>
        {isLoading ? <Loader /> : null}
      </Grid>
    </Card>
  );
};

ResetPassword.propTypes = {
  submitResetPasswordForm: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func
};

ResetPassword.defaultProps = {
  submitResetPasswordForm: () => {},
  error: "",
  message: "",
  isLoading: false,
  onClose: () => {}
};

const mapDispatchToProps = dispatch => ({
  submitResetPasswordForm: (email, password, confirmPassword) => {
    dispatch(resetPassword(email, password, confirmPassword));
  }
});

export default connect(null, mapDispatchToProps)(ResetPassword);

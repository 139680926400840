import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  Button,
  Typography,
  Collapse,
  Box,
  FormControl,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import { EditIcon } from "../Icons";
import { getDateLabel, buildQueryString } from "../../utils";
import ProductContext from "./ProductContext";

const Review = props => {
  const {
    searchTerms,
    story,
    handleReset,
    handleBack,
    isLoadingContext,
    context,
    searchEngine,
    clearContext,
    suggestedContext,
    client
  } = props;
  const [isContextOpen, setIsContextOpen] = useState(false);
  const [contextError, setContextError] = useState(null);
  const whatTerms = searchTerms.filter(s => s.table === "what");
  const contextTerms = searchTerms.filter(s => s.table === "context");
  const where = searchTerms.find(s => s.table === "where");
  const when = searchTerms.find(s => s.table === "when");
  const measure = searchTerms.find(s => s.table === "measure");
  const dateString = when.name.period
    ? `${when.name.period} ${when.name.date}`
    : getDateLabel(when.name, story);
  useEffect(() => {
    setIsContextOpen(whatTerms.length > 1 && contextTerms.length === 0);
  }, []);
  return (
    <Fragment>
      <Typography variant="h4" color="secondary.dark" sx={{ px: 2, mt: 2 }}>
        Review & Run
      </Typography>
      <Typography variant="body2" color="primary" gutterBottom sx={{ px: 2 }}>
        Please review your selections and add or change the product scope. To
        change your initial selections, please go back to the query builder.
      </Typography>
      <Box textAlign="right" sx={{ m: 2 }}>
        {contextTerms.length > 0 ? (
          <Link
            to={`/reports?story=${story.toUpperCase()}&${buildQueryString(
              searchTerms
            )}`}
            onClick={handleReset}
          >
            <Button variant="contained" color="primary" disableElevation>
              Generate Report
            </Button>
          </Link>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setContextError("Please select a context")}
          >
            Generate Report
          </Button>
        )}
      </Box>
      <List>
        <ListItem>
          <FormControl
            component="fieldset"
            className="fieldset"
            sx={{ p: 2, mb: 2 }}
            fullWidth
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>What</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {whatTerms.map((i, k) => (
                      <Typography
                        color="primary"
                        fontWeight="bold"
                        key={k.toString()}
                      >
                        {i.name}
                      </Typography>
                    ))}
                  </TableCell>
                </TableRow>
                {!isContextOpen && (
                  <TableRow>
                    <TableCell>
                      <Typography>Product Scope</Typography>
                    </TableCell>
                    <TableCell align="right">
                      {contextTerms.map((i, k) => (
                        <Typography key={k.toString()}>{i.name}</Typography>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Collapse in={isContextOpen}>
              <ProductContext
                isLoadingContext={isLoadingContext}
                options={context.filter(s => s.table === "what")}
                contextTerms={contextTerms}
                whatTerms={whatTerms}
                searchEngine={searchEngine}
                story={story}
                clearErrors={() => setContextError(null)}
                contextError={contextError}
                suggestedContext={suggestedContext}
                client={client}
              />
            </Collapse>
            <Box textAlign="center">
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={isContextOpen ? null : <EditIcon />}
                onClick={() => {
                  setIsContextOpen(!isContextOpen);
                }}
                disableElevation
              >
                {isContextOpen ? "OK" : "Edit Scope"}
              </Button>
            </Box>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl
            component="fieldset"
            className="fieldset"
            sx={{ p: 2, mb: 2 }}
            fullWidth
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>Where</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="primary" fontWeight="bold">
                      {where.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl
            component="fieldset"
            className="fieldset"
            sx={{ p: 2, mb: 2 }}
            fullWidth
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>When</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="primary" fontWeight="bold">
                      {dateString}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl
            component="fieldset"
            className="fieldset"
            sx={{ p: 2, mb: 2 }}
            fullWidth
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>Measure</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="primary" fontWeight="bold">
                      {measure.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </FormControl>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              clearContext();
              handleBack();
            }}
          >
            Back
          </Button>
        </ListItem>
      </List>
    </Fragment>
  );
};

Review.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  handleReset: PropTypes.func,
  handleBack: PropTypes.func,
  isLoadingContext: PropTypes.bool,
  context: PropTypes.arrayOf(PropTypes.shape()),
  searchEngine: PropTypes.shape(),
  clearContext: PropTypes.func,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string
};

Review.defaultProps = {
  searchTerms: [],
  story: "",
  handleReset: () => {},
  handleBack: () => {},
  isLoadingContext: false,
  context: [],
  searchEngine: {},
  clearContext: () => {},
  suggestedContext: [],
  client: ""
};

export default Review;

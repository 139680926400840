import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  LabelList,
  CartesianGrid
} from "recharts";
import { getNiceTickValues } from "recharts-scale";
import { scaleLinear } from "d3-scale";

const getDomain = dataValues => {
  const minValue = Math.min(...dataValues, 0);
  const maxValue = Math.max(...dataValues, 0);
  const domain = [minValue, maxValue];
  const tickValues = getNiceTickValues(domain);
  return [Math.min(...tickValues), Math.max(...tickValues)];
};

const ClusteredBar = props => {
  const { data, display, chartData, formatNumber } = props;
  return (
    <ResponsiveContainer>
      <BarChart
        data={chartData}
        layout="vertical"
        margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
      >
        {display.value_axis.has_major_gridlines ? (
          <CartesianGrid horizontal={false} />
        ) : null}
        <YAxis
          dataKey="x"
          type="category"
          axisLine={display.category_axis.visible}
          width={Math.max(...chartData.map(i => i.x.length)) > 75 ? 425 : 350}
          fontSize={
            display.category_axis.tick_labels
              ? display.category_axis.tick_labels.font.size
              : "auto"
          }
          reversed
          hide={!display.category_axis.visible}
          interval={0}
        />
        <XAxis
          type="number"
          dataKey="y"
          axisLine={display.value_axis.visible}
          label={{
            value: `${
              display.value_axis.has_title
                ? display.value_axis.axis_title.text_frame.text
                : ""
            }`,
            position: "bottom",
            offset: 0,
            fill: "#666",
            fontSize: 11
          }}
          tick={display.value_axis.visible}
          ticks={scaleLinear().domain(getDomain(data.y)).ticks(6)}
        />
        <Bar dataKey="y">
          {display.data_labels ? (
            <LabelList
              dataKey="y"
              formatter={i =>
                formatNumber(i, display.data_labels.number_format)
              }
              style={{
                fill: "#000",
                fontSize: display.data_labels.font.size
              }}
              position={
                display.data_labels.position === "outside_end"
                  ? "right"
                  : "center"
              }
            />
          ) : null}
          {chartData.map((e, i) =>
            display.apply ? (
              <Cell key={i.toString()} fill={e.y > 0 ? "#01B050" : "#FF0000"} />
            ) : (
              <Cell key={i.toString()} fill={`#${display.color}`} />
            )
          )}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

ClusteredBar.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  chartData: PropTypes.arrayOf(PropTypes.shape()),
  formatNumber: PropTypes.func
};

ClusteredBar.defaultProps = {
  data: {},
  display: {},
  chartData: [],
  formatNumber: () => {}
};

export default ClusteredBar;

import React from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  Card,
  Typography,
  FormControl,
  Grid,
  CardHeader,
  Box
} from "@mui/material";
import { BrandBattlesIcon } from "../Icons";

const styles = {
  card: { bgcolor: "#e5e0df" },
  icon: {
    p: 1,
    fontSize: 48,
    borderRadius: "50%",
    border: "1px #009dbc dashed"
  }
};

const Brands = props => {
  const { productA, productB } = props;
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <Typography
          color="primary"
          fontWeight="bold"
          align="center"
          gutterBottom
        >
          Selected Products
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={3}>
            <Card sx={styles.card}>
              <CardHeader
                titleTypographyProps={{
                  variant: "body2",
                  align: "center",
                  gutterBottom: true
                }}
                title="Primary Product"
                subheaderTypographyProps={{
                  variant: "h4",
                  align: "center",
                  gutterBottom: true,
                  color: "primary.dark"
                }}
                subheader={productA && productA.name}
              />
            </Card>
          </Grid>
          <Grid item md={1}>
            <Box textAlign="center">
              <BrandBattlesIcon
                sx={styles.icon}
                fontSize="large"
                color="primary"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Card sx={styles.card}>
              <CardHeader
                titleTypographyProps={{
                  variant: "body2",
                  align: "center",
                  gutterBottom: true
                }}
                title="Comparison Product"
                subheaderTypographyProps={{
                  variant: "h4",
                  align: "center",
                  gutterBottom: true,
                  color: "primary.dark"
                }}
                subheader={productB && productB.name}
              />
            </Card>
          </Grid>
        </Grid>
      </FormControl>
    </ListItem>
  );
};

Brands.propTypes = {
  productA: PropTypes.shape(),
  productB: PropTypes.shape()
};

Brands.defaultProps = {
  productA: {},
  productB: {}
};

export default Brands;

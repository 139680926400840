import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid, Typography, Button, Box } from "@mui/material";
import { lookoutLogo } from "../../images";
import UpdateDate from "./UpdateDate";

const NoInsights = props => {
  const { isPreference, dataDate, channel } = props;
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ pt: 4, pb: 2 }}
    >
      <Box sx={{ p: 1 }}>
        <img alt="lookout" src={lookoutLogo} />
      </Box>
      <Typography variant="h6" gutterBottom align="center">
        {isPreference
          ? "Thank you for adding your preferences"
          : "No insights yet..."}
      </Typography>
      <Typography
        variant="subtitle3"
        gutterBottom
        fontSize={14}
        align="center"
        sx={{ mb: 4 }}
      >
        {isPreference
          ? "Insights will be delivered to you at the next data update!"
          : "Please choose your preferences!"}
      </Typography>
      {isPreference ? (
        <UpdateDate dataDate={dataDate} />
      ) : (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/lookout-preferences:${channel}`}
          disableElevation
        >
          Choose your Preferences
        </Button>
      )}
    </Grid>
  );
};

NoInsights.propTypes = {
  isPreference: PropTypes.bool,
  dataDate: PropTypes.shape(),
  channel: PropTypes.string
};

NoInsights.defaultProps = {
  isPreference: false,
  dataDate: {},
  channel: ""
};

export default NoInsights;

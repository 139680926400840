import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Stack, TextField } from "@mui/material";
import { ChannelEnvironment } from "../SharedComponents";

const Environment = props => {
  const { name, setName, channel, setChannel, environments, setEnvironments } =
    props;

  const changeName = e => {
    setName(e.target.value);
  };

  return (
    <Grid container>
      <Grid item md={8}>
        <Stack spacing={5}>
          <Stack>
            <Typography variant="subtitle2" gutterBottom>
              Sheet name
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="e.g. My first sheet"
              value={name}
              onChange={changeName}
              sx={{ width: 0.75 }}
            />
          </Stack>
          <ChannelEnvironment
            channel={channel}
            setChannel={setChannel}
            environments={environments}
            setEnvironments={setEnvironments}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

Environment.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  channel: PropTypes.string,
  setChannel: PropTypes.func,
  environments: PropTypes.arrayOf(PropTypes.string),
  setEnvironments: PropTypes.func
};

Environment.defaultProps = {
  name: "",
  setName: () => {},
  channel: "",
  setChannel: () => {},
  environments: [],
  setEnvironments: () => {}
};

export default Environment;

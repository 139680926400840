import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";
import {
  ListItem,
  Button,
  Radio,
  FormControlLabel,
  Typography,
  Collapse,
  Divider,
  FormHelperText,
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Grid
} from "@mui/material";
import { BB_PRODUCTS } from "../../constants";
import {
  enterSelectedTerm,
  deleteTerm,
  filterStaticData,
  removeFilterTerm
} from "../../actions";
import { customFilter, customStyles } from "../../utils";
import { SearchBar } from "../SharedComponents";
import { BrandBattlesIcon, InfoIcon } from "../Icons";

const styles = {
  divider: { width: "47%", borderBottom: "1px #009dbc dashed" },
  icon: {
    p: 1,
    fontSize: 48,
    borderRadius: "50%",
    border: "1px #009dbc dashed"
  }
};

const Products = props => {
  const {
    story,
    onSelect,
    onChange,
    subsections,
    filteredSubsections,
    filterTerms,
    isStaticData,
    productA,
    productB,
    searchEngine,
    filteredSearchEngine,
    isFilteredData,
    productAError,
    productBError,
    clearErrors,
    client
  } = props;
  const [isAdvancedSearchA, setIsAdvancedSearchA] = useState(false);
  const [isAdvancedSearchB, setIsAdvancedSearchB] = useState(false);
  const [productTypeA, setProductTypeA] = useState(null);
  const [productTypeB, setProductTypeB] = useState(null);
  const [aValue, setAValue] = useState(null);
  const [bValue, setBValue] = useState(null);
  useEffect(() => {
    setAValue(productA || null);
    setBValue(productB || null);
    setProductTypeA(productA ? productA.subsection.split("=")[1] : null);
    setProductTypeB(productB ? productB.subsection.split("=")[1] : null);
  }, []);

  const getOptionsFromList = (optionList, subsection) =>
    Object.assign(
      optionList.map(i => ({ value: `${subsection}--${i}`, label: i }))
    );

  const getSelectedValue = selected =>
    selected
      ? {
          value: `${selected.subsection}--${selected.name}`,
          label: selected.name
        }
      : "no-value";

  const getList = (subsectionsToUse, subsection) => {
    const list = subsectionsToUse.filter(i => i.name === subsection);
    return isStaticData && list.length > 0 ? list[0].keywords.sort() : [];
  };
  const searchOptionsA = isStaticData
    ? searchEngine._docs.filter(i => BB_PRODUCTS.includes(i.subsection))
    : [];
  const searchOptionsB = isFilteredData
    ? filteredSearchEngine._docs.filter(i => BB_PRODUCTS.includes(i.subsection))
    : [];
  const comparisonProductOptions = BB_PRODUCTS.filter(i =>
    filteredSubsections.find(j => j.name === i && j.keywords.length > 0)
  )
    .sort((a, b) => {
      if (a === productTypeA) return -1;
      if (b === productTypeA) return 1;
      return 0;
    })
    .map(i => _.startCase(i))
    .join(" or a ");
  return (
    <Fragment>
      <ListItem>
        <FormControl
          component="fieldset"
          className="fieldset"
          sx={{ p: 2, mb: 2 }}
          fullWidth
        >
          <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
            <Typography color="textPrimary" fontWeight="bold">
              Primary Product{" "}
              <Typography
                color="textPrimary"
                fontWeight="bold"
                component="span"
                fontSize={13}
              >
                (typically a product distributed by us)
              </Typography>
            </Typography>
          </FormLabel>
          <Typography variant="subtitle2" sx={{ mx: 1 }}>
            Describe what you would like to see as the primary product
          </Typography>
          <Box sx={{ mt: -1, px: 1 }}>
            <SearchBar
              searchOptions={searchOptionsA}
              value={aValue}
              onChange={(e, val) => {
                setProductTypeA(val ? val.subsection : null);
                setAValue(val);
                if (productB) {
                  // remove productB
                  onChange(undefined, productB, "b", story, [productB], client);
                  setBValue(null);
                  setProductTypeB(null);
                }
                onChange(
                  val,
                  productA,
                  "a",
                  story,
                  productA ? [productA] : filterTerms,
                  client
                );
                clearErrors();
              }}
              searchEngine={searchEngine}
              disabled={!isStaticData}
            />
            <ListItem disablePadding>
              <InfoIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="subtitle2" color="primary">
                You can select a Distributor, a Major Brand or a Sub Brand.
              </Typography>
            </ListItem>
            <FormHelperText error>{productAError}</FormHelperText>
          </Box>
          {isAdvancedSearchA ? (
            <FormControl
              component="fieldset"
              className="fieldset"
              sx={{ p: 2, my: 2 }}
              fullWidth
            >
              <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
                <Typography color="textPrimary" fontWeight="bold">
                  Advanced Search
                </Typography>
              </FormLabel>
              <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
                Which product do you want to explore?
              </Typography>
              <RadioGroup>
                {BB_PRODUCTS.map(i => (
                  <ListItem key={i} sx={{ px: 1 }}>
                    <FormControlLabel
                      sx={{ width: 150 }}
                      control={
                        <Radio
                          value={i}
                          checked={productTypeA === i}
                          onChange={e => {
                            setProductTypeA(e.target.value);
                            if (productA) {
                              onSelect(
                                "no-value",
                                getSelectedValue(productA),
                                "a",
                                story,
                                filterTerms,
                                client
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {_.startCase(i)}
                        </Typography>
                      }
                    />
                    <Select
                      styles={customStyles}
                      className="smallSelect"
                      onChange={e => {
                        setProductTypeA(i);
                        setAValue(e);
                        if (productB) {
                          // remove productB
                          onSelect(
                            "no-value",
                            getSelectedValue(productB),
                            "b",
                            story,
                            [productB],
                            client
                          );
                          setBValue(null);
                          setProductTypeB(null);
                        }
                        onSelect(
                          e.value,
                          getSelectedValue(productA),
                          "a",
                          story,
                          productA ? [productA] : filterTerms,
                          client
                        );
                        clearErrors();
                      }}
                      options={getOptionsFromList(getList(subsections, i), i)}
                      placeholder={`Type or scroll to select a ${_.lowerCase(
                        i
                      )}`}
                      value={productTypeA === i && getSelectedValue(productA)}
                      backspaceRemovesValue={false}
                      filterOption={customFilter}
                    />
                  </ListItem>
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <Box sx={{ px: 1, mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Can&apos;t find what you&apos;re looking for?
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsAdvancedSearchA(!isAdvancedSearchA)}
              >
                Advanced Search
              </Button>
            </Box>
          )}
        </FormControl>
      </ListItem>
      <Collapse in={!!productA && isFilteredData}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Divider sx={styles.divider} />
          <BrandBattlesIcon sx={styles.icon} color="primary" />
          <Divider sx={styles.divider} />
        </Grid>
        <ListItem>
          <FormControl
            component="fieldset"
            className="fieldset"
            sx={{ p: 2, mb: 2 }}
            fullWidth
          >
            <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
              <Typography color="textPrimary" fontWeight="bold">
                Comparison Product{" "}
                <Typography
                  color="textPrimary"
                  fontWeight="bold"
                  component="span"
                  fontSize={13}
                >
                  (typically a product distributed by competitors)
                </Typography>
              </Typography>
            </FormLabel>
            <Typography variant="subtitle2" sx={{ mx: 1 }}>
              Describe what you would like to see as the comparison product
            </Typography>
            <Box sx={{ mt: -1, px: 1 }}>
              <SearchBar
                searchOptions={searchOptionsB}
                value={bValue}
                onChange={(e, val) => {
                  setProductTypeB(val ? val.subsection : null);
                  setBValue(val);
                  onChange(val, productB, "b", story, filterTerms, client);
                  clearErrors();
                }}
                searchEngine={filteredSearchEngine}
                disabled={!isStaticData || !isFilteredData}
              />
              <ListItem disablePadding>
                <InfoIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="subtitle2" color="primary">
                  {`You have chosen a ${_.startCase(
                    productTypeA
                  )} as the Primary Product. Please select a different ${
                    comparisonProductOptions || _.startCase(productTypeB)
                  } as your Comparison Product`}
                </Typography>
              </ListItem>
              <FormHelperText error>{productBError}</FormHelperText>
            </Box>
            {isAdvancedSearchB ? (
              <FormControl
                component="fieldset"
                className="fieldset"
                sx={{ p: 2, my: 2 }}
                fullWidth
              >
                <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
                  <Typography color="textPrimary" fontWeight="bold">
                    Advanced Search
                  </Typography>
                </FormLabel>
                <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
                  Which product do you want to explore?
                </Typography>
                <RadioGroup>
                  {BB_PRODUCTS.filter(i =>
                    filteredSubsections.find(
                      j => j.name === i && j.keywords.length > 0
                    )
                  ).map(i => (
                    <ListItem key={i} sx={{ px: 1 }}>
                      <FormControlLabel
                        sx={{ width: 150 }}
                        control={
                          <Radio
                            value={i}
                            checked={productTypeB === i}
                            onChange={e => {
                              setProductTypeB(e.target.value);
                              if (productB) {
                                onSelect(
                                  "no-value",
                                  getSelectedValue(productB),
                                  "b",
                                  story,
                                  filterTerms,
                                  client
                                );
                              }
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {_.startCase(i)}
                          </Typography>
                        }
                      />
                      <Select
                        styles={customStyles}
                        className="smallSelect"
                        onChange={e => {
                          setProductTypeB(i);
                          setBValue(e);
                          onSelect(
                            e.value,
                            getSelectedValue(productB),
                            "b",
                            story,
                            filterTerms,
                            client
                          );
                          clearErrors();
                        }}
                        options={getOptionsFromList(
                          getList(filteredSubsections, i),
                          i
                        )}
                        placeholder={`Type or scroll to select a ${_.lowerCase(
                          i
                        )}`}
                        value={productTypeB === i && getSelectedValue(productB)}
                        backspaceRemovesValue={false}
                        filterOption={customFilter}
                      />
                    </ListItem>
                  ))}
                </RadioGroup>
              </FormControl>
            ) : (
              <Box sx={{ px: 1, mt: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Can&apos;t find what you&apos;re looking for?
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsAdvancedSearchB(!isAdvancedSearchB)}
                >
                  Advanced Search
                </Button>
              </Box>
            )}
          </FormControl>
        </ListItem>
      </Collapse>
    </Fragment>
  );
};

Products.propTypes = {
  story: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  isStaticData: PropTypes.bool,
  productA: PropTypes.shape(),
  productB: PropTypes.shape(),
  searchEngine: PropTypes.shape(),
  filteredSearchEngine: PropTypes.shape(),
  isFilteredData: PropTypes.bool,
  productAError: PropTypes.string,
  productBError: PropTypes.string,
  clearErrors: PropTypes.func,
  client: PropTypes.string
};

Products.defaultProps = {
  story: "",
  onSelect: () => {},
  onChange: () => {},
  subsections: [],
  filteredSubsections: [],
  filterTerms: [],
  isStaticData: false,
  productA: undefined,
  productB: undefined,
  searchEngine: {},
  filteredSearchEngine: {},
  isFilteredData: false,
  productAError: "",
  productBError: "",
  clearErrors: () => {},
  client: ""
};

const mapDispatchToProps = dispatch => ({
  onChange: (selectedTerm, oldValue, product, story, filterTerms, client) => {
    if (oldValue) {
      dispatch(deleteTerm(oldValue, story));
      dispatch(removeFilterTerm(oldValue, filterTerms, story, client));
    }
    if (selectedTerm) {
      const updatedFilterTerms = filterTerms.filter(
        t => !_.isEqual(t, oldValue)
      );
      dispatch(
        enterSelectedTerm(
          {
            ...selectedTerm,
            subsection: `product_${product}=${selectedTerm.subsection}`,
            story
          },
          story
        )
      );
      dispatch(
        filterStaticData(
          {
            ...selectedTerm,
            subsection: `product_${product}=${selectedTerm.subsection}`,
            story
          },
          updatedFilterTerms,
          story,
          client
        )
      );
    }
  },
  onSelect: (selectedValue, oldValue, product, story, filterTerms, client) => {
    const value = selectedValue.split("--");
    if (oldValue !== "no-value") {
      const old = oldValue.value.split("--");
      dispatch(
        deleteTerm(
          { name: old[1], subsection: old[0], table: "what", story },
          story
        )
      );
      dispatch(
        removeFilterTerm(
          { name: old[1], subsection: old[0], table: "what", story },
          filterTerms,
          story,
          client
        )
      );
    }
    if (selectedValue !== "no-value") {
      let updatedFilterTerms = filterTerms;
      if (oldValue !== "no-value") {
        const old = oldValue.value.split("--");
        const term = { name: old[1], subsection: old[0], table: "what", story };
        updatedFilterTerms = filterTerms.filter(t => !_.isEqual(t, term));
      }
      dispatch(
        enterSelectedTerm(
          {
            name: value[1],
            subsection: `product_${product}=${value[0]}`,
            table: "what",
            story
          },
          story
        )
      );
      dispatch(
        filterStaticData(
          {
            name: value[1],
            subsection: `product_${product}=${value[0]}`,
            table: "what",
            story
          },
          updatedFilterTerms,
          story,
          client
        )
      );
    }
  }
});

export default connect(null, mapDispatchToProps)(Products);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  Box,
  Button
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Environment from "./Environment";
import Details from "./Details";
import { editSterling } from "../../actions";

const NewPrioritisation = props => {
  const { options, isLoading, edit, error } = props;
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(
    (location.state && location.state.step) || 0
  );
  const [name, setName] = useState(
    (location.state && location.state.name) || ""
  );
  const [channel, setChannel] = useState(
    (location.state && location.state.channel) || "ih"
  );
  const [environments, setEnvironments] = useState(
    (location.state && location.state.environments) || []
  );
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (location.state && options.length > 0) {
      setRows(options.filter(i => location.state.skus.includes(i.sku)));
    }
  }, [options]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setRows([]);
  };

  return (
    <Card sx={{ p: 2.5, width: 1 }} className="overflow">
      <CardHeader
        title="SKU Prioritisation"
        titleTypographyProps={{ variant: "h2" }}
        avatar={
          activeStep === 0 ? (
            <IconButton component={Link} color="secondary" to="/sku-editing">
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton color="secondary" onClick={handleBack}>
              <ArrowBack />
            </IconButton>
          )
        }
      />
      {activeStep === 0 ? (
        <Grid item md={8}>
          <Typography variant="body2" sx={{ px: 7, mx: 2, mt: -2 }}>
            Please select the channel and then which environments you would like
            to add information for. You can select as many environments within a
            channel as you like, and the scores you add will be given to that
            sku in all selected environments.
          </Typography>
        </Grid>
      ) : (
        <Grid item md={12}>
          <Typography variant="body2" sx={{ px: 7, mx: 2, mt: -2 }}>
            Search for skus or groups of skus in the search bar. Once selected,
            you will be able to add custom scores for the skus within that
            selection. There can only be one score for a sku within an
            environment, so any editing existing scores will overwrite them.
          </Typography>
        </Grid>
      )}
      <CardContent sx={{ px: 7, py: 3, mx: 2 }}>
        {activeStep === 0 && (
          <Environment
            name={name}
            setName={setName}
            channel={channel}
            setChannel={setChannel}
            environments={environments}
            setEnvironments={setEnvironments}
          />
        )}
        {activeStep === 1 && (
          <Details
            name={name}
            channel={channel}
            environments={environments}
            handleBack={handleBack}
            options={options}
            rows={rows}
            setRows={setRows}
            isLoading={isLoading}
            error={error}
          />
        )}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 5 }}
        >
          <Grid item md={6}>
            <Box textAlign="left">
              {activeStep === 0 ? (
                <Button
                  component={Link}
                  to="/sku-editing"
                  startIcon={<ArrowBack />}
                >
                  Cancel
                </Button>
              ) : (
                <Button startIcon={<ArrowBack />} onClick={handleBack}>
                  Back
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box textAlign="right">
              {activeStep === 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleNext}
                  disabled={name.length === 0 || environments.length === 0}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() =>
                    edit(
                      _.difference(rows, options),
                      channel,
                      environments,
                      handleBack,
                      name,
                      rows.map(i => i.sku)
                    )
                  }
                  disabled={
                    isLoading || options.length === 0 || rows.length === 0
                  }
                >
                  Create sheet
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

NewPrioritisation.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  edit: PropTypes.func,
  error: PropTypes.string
};

NewPrioritisation.defaultProps = {
  options: [],
  isLoading: false,
  edit: () => {},
  error: ""
};

const mapStateToProps = state => ({
  options: state.sterling.products,
  isLoading: state.sterling.isLoading,
  error: state.sterling.error
});

const mapDispatchToProps = dispatch => ({
  edit: (data, channel, environments, onSuccess, name, skus) =>
    dispatch(
      editSterling(
        "sku",
        data,
        [],
        channel,
        environments,
        onSuccess,
        name,
        skus
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPrioritisation);

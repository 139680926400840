import AWS from "aws-sdk/global";
import DynamoDB from "aws-sdk/clients/dynamodb";
import {
  REPORT_URL,
  AWS_CONFIG,
  REPORT_TABLE,
  FIRST_POLL_TIMER,
  GENERAL_POLL_TIMER,
  TOTAL_POLLS,
  USER_TRACKING_URL,
  LIST_RECENT_REPORTS_URL,
  ADD_TO_FAVOURITES_URL,
  REMOVE_FROM_FAVOURITES_URL,
  LIST_FAVOURITES_URL,
  API_KEY
} from "../constants";
import {
  LOAD_REPORT_REQUEST,
  LOAD_REPORT_SUCCESS,
  LOAD_REPORT_ERROR,
  ADD_USER_TRACKING_REQUEST,
  ADD_USER_TRACKING_SUCCESS,
  ADD_USER_TRACKING_ERROR,
  GET_MY_RECENT_REPORTS_REQUEST,
  GET_MY_RECENT_REPORTS_SUCCESS,
  GET_MY_RECENT_REPORTS_ERROR,
  GET_MY_FAVOURITE_REPORTS_REQUEST,
  GET_MY_FAVOURITE_REPORTS_SUCCESS,
  GET_MY_FAVOURITE_REPORTS_ERROR,
  ADD_TO_FAVOURITES_REQUEST,
  ADD_TO_FAVOURITES_SUCCESS,
  ADD_TO_FAVOURITES_ERROR,
  REMOVE_FROM_FAVOURITES_REQUEST,
  REMOVE_FROM_FAVOURITES_SUCCESS,
  REMOVE_FROM_FAVOURITES_ERROR
} from "../constants/actionConstants";
import { addNotification } from "./notification";
import * as localReportData from "../localReportData.json";
import { getTitleFromQueryString } from "../utils";
import logger from "../logger";

function loadReportRequest(report) {
  return {
    type: LOAD_REPORT_REQUEST,
    report
  };
}

function loadReportSuccess(report) {
  return {
    type: LOAD_REPORT_SUCCESS,
    report
  };
}

function loadReportError(error) {
  return {
    type: LOAD_REPORT_ERROR,
    error
  };
}

function addUserTrackingRequest() {
  return {
    type: ADD_USER_TRACKING_REQUEST
  };
}

function addUserTrackingSuccess() {
  return {
    type: ADD_USER_TRACKING_SUCCESS
  };
}

function addUserTrackingError(error) {
  return {
    type: ADD_USER_TRACKING_ERROR,
    error
  };
}

function addUserTracking(dispatch, userId, reportId, queryString, success) {
  return fetch(USER_TRACKING_URL, {
    method: "post",
    body: JSON.stringify({
      user_id: userId,
      report_id: reportId,
      query: queryString,
      success
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(addUserTrackingSuccess());
        } else {
          dispatch(addUserTrackingError(res));
        }
      },
      err => {
        dispatch(addUserTrackingError(err.message));
      }
    );
}

function pollDynamo(
  dispatch,
  reportId,
  count,
  userId,
  queryString,
  story,
  client
) {
  AWS.config.update(AWS_CONFIG);
  const dynamodb = new DynamoDB({ apiVersion: "2012-08-10" });

  const params = {
    Key: {
      id: {
        S: reportId
      }
    },
    TableName: REPORT_TABLE[story]
  };
  dynamodb.getItem(params, (err, data) => {
    try {
      if (!data.Item) {
        throw new Error(
          "Unable to find report with this id. Try running as a new report."
        );
      }
      if (
        data.Item.status.S.startsWith("ERROR") ||
        data.Item.status.S.startsWith("E")
      ) {
        // add to user analytics table
        dispatch(addUserTrackingRequest());
        addUserTracking(dispatch, userId, reportId, queryString, false);
        logger.info({
          date: new Date().toISOString(),
          action: "VIEW_REPORT",
          user_id: userId,
          query: queryString,
          report_id: reportId,
          success: false,
          story,
          client,
          product: "ida"
        });
        throw new Error(data.Item.status.S);
      } else if (data.Item.status.S === "RUNNING") {
        if (count > TOTAL_POLLS) {
          throw new Error(
            "Unable to generate a report for the chosen constraints at this time. Please refresh or try again later."
          );
        }
        return setTimeout(() => {
          pollDynamo(
            dispatch,
            reportId,
            count + 1,
            userId,
            queryString,
            story,
            client
          );
        }, GENERAL_POLL_TIMER);
      } else {
        // add to user analytics table
        dispatch(addUserTrackingRequest());
        addUserTracking(dispatch, userId, reportId, queryString, true);
        logger.info({
          date: new Date().toISOString(),
          action: "VIEW_REPORT",
          user_id: userId,
          query: queryString,
          report_id: reportId,
          success: true,
          story,
          client,
          product: "ida"
        });
        const { report, title } = JSON.parse(data.Item.report.S);
        return dispatch(
          loadReportSuccess({
            data: report,
            title,
            client,
            id: reportId,
            story
          })
        );
      }
    } catch (e) {
      return dispatch(loadReportError(e));
    }
  });
}

export const fetchLocalReport = () => dispatch => {
  const { report, title, client } = localReportData.default;
  return dispatch(
    loadReportSuccess({
      data: report,
      title,
      client,
      id: "123"
    })
  );
};

export const fetchReport =
  (queryString, userId, date, client) => async dispatch => {
    dispatch(loadReportRequest(queryString));
    try {
      if (queryString.length === 0) {
        throw new Error(
          "You did not provide a search query. This may be because this link is corrupted. Try making a new report."
        );
      }
      // // currently set to 4 search terms, but there seem to be reports that run with only 3 search terms.
      if (queryString.match(/=/g).length < 4) {
        throw new Error(
          "You did not provide enough search terms. Try adding more search terms."
        );
      }
      const res = await fetch(
        `${REPORT_URL}?${queryString}&userid=${userId}&datadate=${date}&client=${client}`,
        {
          headers: { "X-API-KEY": API_KEY }
        }
      );
      if (!res.ok) {
        throw new Error(
          "A technical issue has occurred while generating the report. Please contact our tech support team."
        );
      }
      const data = await res.json();
      if (data.status.startsWith("ERROR") || data.status.startsWith("E")) {
        // add to user analytics table
        dispatch(addUserTrackingRequest());
        addUserTracking(dispatch, userId, data.id, queryString, false);
        logger.info({
          date: new Date().toISOString(),
          action: "VIEW_REPORT",
          user_id: userId,
          query: queryString,
          report_id: data.id,
          success: false,
          story: data.story,
          client,
          product: "ida"
        });
        throw new Error(data.status);
      } else if (data.status === "COMPLETE") {
        // add to user analytics table
        dispatch(addUserTrackingRequest());
        addUserTracking(dispatch, userId, data.id, queryString, true);
        logger.info({
          date: new Date().toISOString(),
          action: "VIEW_REPORT",
          user_id: userId,
          query: queryString,
          report_id: data.id,
          success: true,
          story: data.story,
          client,
          product: "ida"
        });
        const { report, title } = JSON.parse(data.report);
        return dispatch(
          loadReportSuccess({
            data: report,
            title,
            client,
            id: data.id,
            story: data.story
          })
        );
      } else {
        // wait before polling
        return setTimeout(() => {
          pollDynamo(
            dispatch,
            data.id,
            0,
            userId,
            queryString,
            data.story,
            client
          );
        }, FIRST_POLL_TIMER);
      }
    } catch (err) {
      return dispatch(loadReportError(err));
    }
  };

function getMyRecentReportsRequest() {
  return {
    type: GET_MY_RECENT_REPORTS_REQUEST
  };
}

function getMyRecentReportsSuccess(myRecentReports) {
  return {
    type: GET_MY_RECENT_REPORTS_SUCCESS,
    myRecentReports
  };
}

function getMyRecentReportsError(error) {
  return {
    type: GET_MY_RECENT_REPORTS_ERROR,
    error
  };
}

export const getMyRecentReports = userId => dispatch => {
  dispatch(getMyRecentReportsRequest());
  return fetch(LIST_RECENT_REPORTS_URL, {
    method: "post",
    body: JSON.stringify({
      user_id: userId
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getMyRecentReportsError(res));
        } else {
          dispatch(getMyRecentReportsSuccess(res));
        }
      },
      err => {
        dispatch(getMyRecentReportsError(err.message));
      }
    );
};

function getFavouriteMyReportsRequest() {
  return {
    type: GET_MY_FAVOURITE_REPORTS_REQUEST
  };
}

function getMyFavouriteReportsSuccess(myFavouriteReports) {
  return {
    type: GET_MY_FAVOURITE_REPORTS_SUCCESS,
    myFavouriteReports
  };
}

function getMyFavouriteReportsError(error) {
  return {
    type: GET_MY_FAVOURITE_REPORTS_ERROR,
    error
  };
}

export const getMyFavouriteReports = userId => dispatch => {
  dispatch(getFavouriteMyReportsRequest());
  return fetch(LIST_FAVOURITES_URL, {
    method: "post",
    body: JSON.stringify({
      user_id: userId
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getMyFavouriteReportsError(res));
        } else {
          dispatch(getMyFavouriteReportsSuccess(res));
        }
      },
      err => {
        dispatch(getMyFavouriteReportsError(err.message));
      }
    );
};

function addToFavouritesRequest() {
  return {
    type: ADD_TO_FAVOURITES_REQUEST
  };
}

function addToFavouritesSuccess(report) {
  return {
    type: ADD_TO_FAVOURITES_SUCCESS,
    report
  };
}

function addToFavouritesError(error) {
  return {
    type: ADD_TO_FAVOURITES_ERROR,
    error
  };
}

export const addToFavourites = (userId, report) => dispatch => {
  const { query } = report;
  dispatch(addToFavouritesRequest());
  return fetch(ADD_TO_FAVOURITES_URL, {
    method: "post",
    body: JSON.stringify({
      user_id: userId,
      query
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(addToFavouritesSuccess(report));
          dispatch(
            addNotification(
              `Report "${getTitleFromQueryString(query)}" added to favourites`,
              "success"
            )
          );
        } else {
          dispatch(addToFavouritesError(res));
          dispatch(
            addNotification(
              `Report could not be added to favourites`,
              "warning"
            )
          );
        }
      },
      err => {
        dispatch(addToFavouritesError(err.message));
      }
    );
};

function removeFromFavouritesRequest() {
  return {
    type: REMOVE_FROM_FAVOURITES_REQUEST
  };
}

function removeFromFavouritesSuccess(query) {
  return {
    type: REMOVE_FROM_FAVOURITES_SUCCESS,
    query
  };
}

function removeFromFavouritesError(error) {
  return {
    type: REMOVE_FROM_FAVOURITES_ERROR,
    error
  };
}

export const removeFromFavourites = (userId, report) => dispatch => {
  const { query } = report;
  dispatch(removeFromFavouritesRequest());
  return fetch(REMOVE_FROM_FAVOURITES_URL, {
    method: "post",
    body: JSON.stringify({
      user_id: userId,
      query
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(removeFromFavouritesSuccess(query));
          dispatch(
            addNotification(
              `Report "${getTitleFromQueryString(
                query
              )}" removed from favourites`,
              "info"
            )
          );
        } else {
          dispatch(removeFromFavouritesError(res));
        }
      },
      err => {
        dispatch(removeFromFavouritesError(err.message));
      }
    );
};

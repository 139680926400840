import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Box,
  Button,
  TextField,
  FormHelperText,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { ChannelEnvironment } from "../SharedComponents";
import Products from "./Products";

const styles = { radio: { "& .MuiFormControlLabel-label": { fontSize: 14 } } };

const SKUPrioritisation = props => {
  const { options, isLoading, error, date } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [channel, setChannel] = useState("ih");
  const [environments, setEnvironments] = useState([]);
  const [number, setNumber] = useState(0);
  const [rankType, setRankType] = useState("sku");
  const [consumptionType, setconsumptionType] = useState("all");

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Card sx={{ p: 2.5, width: 1 }}>
      <Grid container>
        <Grid item md={8}>
          <CardHeader
            title="SKU Prioritisation"
            titleTypographyProps={{ variant: "h2", gutterBottom: true }}
            subheader={
              <Typography variant="body2" gutterBottom>
                Please select a channel and then one or more environments within
                it:
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <CardContent>
        <Grid container>
          {activeStep === 0 && (
            <Fragment>
              <Grid item md={8}>
                <Stack spacing={5}>
                  <ChannelEnvironment
                    channel={channel}
                    setChannel={setChannel}
                    environments={environments}
                    setEnvironments={setEnvironments}
                    additionalOption={
                      <Grid container alignItems="center">
                        <Stack sx={{ px: 1 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            View ranking by
                          </Typography>
                          <RadioGroup row>
                            <FormControlLabel
                              label="SKU"
                              sx={styles.radio}
                              control={
                                <Radio
                                  size="small"
                                  value="sku"
                                  checked={rankType === "sku"}
                                  onChange={e => setRankType(e.target.value)}
                                />
                              }
                            />
                            <FormControlLabel
                              label="Liquid"
                              sx={styles.radio}
                              control={
                                <Radio
                                  size="small"
                                  value="liquid"
                                  checked={rankType === "liquid"}
                                  onChange={e => setRankType(e.target.value)}
                                />
                              }
                            />
                          </RadioGroup>
                        </Stack>
                        {channel === "ih" && (
                          <Stack sx={{ px: 1 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Products filtered by
                            </Typography>
                            <RadioGroup row>
                              <FormControlLabel
                                label="All"
                                sx={styles.radio}
                                control={
                                  <Radio
                                    size="small"
                                    value="all"
                                    checked={consumptionType === "all"}
                                    onChange={e =>
                                      setconsumptionType(e.target.value)
                                    }
                                  />
                                }
                              />
                              <FormControlLabel
                                label="IC"
                                sx={styles.radio}
                                control={
                                  <Radio
                                    size="small"
                                    value="ic"
                                    checked={consumptionType === "ic"}
                                    onChange={e =>
                                      setconsumptionType(e.target.value)
                                    }
                                  />
                                }
                              />
                              <FormControlLabel
                                label="FC"
                                sx={styles.radio}
                                control={
                                  <Radio
                                    size="small"
                                    value="fc"
                                    checked={consumptionType === "fc"}
                                    onChange={e =>
                                      setconsumptionType(e.target.value)
                                    }
                                  />
                                }
                              />
                            </RadioGroup>
                          </Stack>
                        )}
                      </Grid>
                    }
                  />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="subtitle2" gutterBottom>
                    Number of products
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={number}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={e => setNumber(Number(e.target.value))}
                  />
                  <FormHelperText>
                    Please enter the number of products that can be listed
                    within the given environment(s)
                  </FormHelperText>
                </Stack>
              </Grid>
            </Fragment>
          )}
          {activeStep === 1 && (
            <Grid item md={12} sx={{ mb: 2 }}>
              <Products
                channel={channel}
                environments={environments}
                handleBack={handleBack}
                options={options}
                number={number}
                isLoading={isLoading}
                error={error}
                rankType={rankType}
                consumptionType={consumptionType}
                date={date}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 5, px: 2 }}
      >
        <Grid item md={6}>
          <Box textAlign="left">
            {activeStep === 1 && (
              <Button startIcon={<ArrowBack />} onClick={handleBack}>
                Back
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box textAlign="right">
            {activeStep === 0 && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleNext}
                disabled={environments.length === 0 || number === 0}
              >
                Next
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

SKUPrioritisation.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  date: PropTypes.shape()
};

SKUPrioritisation.defaultProps = {
  options: [],
  isLoading: false,
  error: "",
  date: ""
};

const mapStateToProps = state => ({
  options: state.sterling.products,
  isLoading: state.sterling.isLoading,
  error: state.sterling.error,
  date: state.data.date
});

export default connect(mapStateToProps, null)(SKUPrioritisation);

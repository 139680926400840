import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardHeader, Typography, Button, List } from "@mui/material";
import { RangeReviewIcon } from "../Icons";
import { fetchStaticData, clearStorySearchData } from "../../actions";
import { HIERARCHY_LIST } from "../../constants";
import Scope from "./Scope";
import ProductRetailer from "./ProductRetailer";
import Hierarchy from "./Hierarchy";
import Period from "./Period";
import ImplementationDate from "./ImplementationDate";
import Measure from "./Measure";
import { buildQueryString } from "../../utils";

class NewRangeReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retailerError: null,
      hierarchyError: null,
      measureError: null,
      consumptionError: null,
      periodError: null
    };
    this.showErrors = this.showErrors.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const { isStaticData, getData, client } = this.props;
    if (!isStaticData) {
      getData(client);
    }
  }

  showErrors = () => {
    const { searchTerms } = this.props;
    if (!searchTerms.find(s => s.subsection === "temperature")) {
      this.setState({ temperatureError: "Please pick at least one option" });
    }
    if (!searchTerms.find(s => s.subsection === "consumption_type")) {
      this.setState({ consumptionError: "Please pick at least one option" });
    }
    if (!searchTerms.find(s => s.subsection === "retailer")) {
      this.setState({ retailerError: "Please define a retailer" });
    }
    if (!searchTerms.find(s => s.subsection === "period")) {
      this.setState({ periodError: "Please define a period" });
    }
    if (!searchTerms.find(s => s.subsection === "hier1")) {
      this.setState({ hierarchyError: "Please define a hierarchy" });
    }
    if (!searchTerms.find(s => s.subsection === "metric_name")) {
      this.setState({ measureError: "Please select a measure type" });
    }
  };

  handleReset = () => {
    const { reset, story } = this.props;
    reset(story);
  };

  render() {
    const {
      isStaticData,
      subsections,
      filteredSubsections,
      searchTerms,
      dataDate,
      isFilteringData,
      isFilteredData,
      filterTerms,
      allTerms,
      story,
      client
    } = this.props;
    const {
      temperatureError,
      consumptionError,
      retailerError,
      hierarchyError,
      measureError,
      periodError
    } = this.state;
    const categoryList = isFilteredData
      ? filteredSubsections.filter(e => e.name === "category")
      : subsections.filter(e => e.name === "category");
    const sectorList = isFilteredData
      ? filteredSubsections.filter(e => e.name === "sector")
      : subsections.filter(e => e.name === "sector");
    const retailerList = subsections.filter(e => e.name === "prr_retailer");
    return (
      <Card className={isStaticData ? "overflow" : "wait"}>
        <CardHeader
          avatar={<RangeReviewIcon fontSize="small" color="secondaryDark" />}
          titleTypographyProps={{ variant: "h5" }}
          title="New Range Review"
        />
        <Typography variant="body2" sx={{ px: 2 }}>
          Conduct a range review to optimise the revenue and profit that you are
          generating from your space. You will spell out specific objectives for
          the range reviews.
        </Typography>
        <List>
          <Scope
            temperatureError={temperatureError}
            consumptionError={consumptionError}
            selectedTemperature={searchTerms.find(
              s => s.subsection === "temperature"
            )}
            selectedConsumption={searchTerms.find(
              s => s.subsection === "consumption_type"
            )}
            filterTerms={filterTerms}
            story={story}
            client={client}
          />
          <ProductRetailer
            categoryList={
              isStaticData && categoryList.length > 0
                ? categoryList[0].keywords.map(i => (i.value ? i.value : i))
                : []
            }
            sectorList={
              isStaticData && sectorList.length > 0
                ? sectorList[0].keywords.map(i => (i.value ? i.value : i))
                : []
            }
            retailerList={
              isStaticData && retailerList.length > 0
                ? retailerList[0].keywords.map(i => (i.value ? i.value : i))
                : []
            }
            selectedCategory={searchTerms.find(
              s => s.subsection === "category"
            )}
            selectedSector={searchTerms.find(s => s.subsection === "sector")}
            selectedRetailer={searchTerms.find(
              s => s.subsection === "retailer"
            )}
            selectedRefRetailer={searchTerms.find(
              s => s.subsection === "ref_market"
            )}
            isStaticData={isStaticData}
            isFilteringData={isFilteringData}
            retailerError={retailerError}
            filterTerms={filterTerms}
            story={story}
            client={client}
          />
          <Period
            dataDate={dataDate}
            selectedPeriod={searchTerms.find(s => s.subsection === "period")}
            periodError={periodError}
            story={story}
          />
          <ImplementationDate
            selectedImplementationDate={searchTerms.find(
              s => s.subsection === "implementation_date"
            )}
            selectedPeriod={searchTerms.find(s => s.subsection === "period")}
            story={story}
          />
          <Hierarchy
            hierarchyList={
              (searchTerms.filter(s => s.subsection === "sector").length > 0 &&
                HIERARCHY_LIST.filter(
                  i => i.name !== "sector" && i.name !== "category"
                )) ||
              (searchTerms.filter(s => s.subsection === "category").length >
                0 &&
                HIERARCHY_LIST.filter(i => i.name !== "category")) ||
              HIERARCHY_LIST
            }
            selectedFirstHierarchy={searchTerms.find(
              s => s.subsection === "hier1"
            )}
            selectedSecondHierarchy={searchTerms.find(
              s => s.subsection === "hier2"
            )}
            hierarchyError={hierarchyError}
            story={story}
          />
          <Measure
            selectedMeasure={searchTerms.find(
              s => s.subsection === "metric_name"
            )}
            measureError={measureError}
            story={story}
          />
        </List>
        {allTerms ? (
          <Link
            to={`/reports?story=${story.toUpperCase()}&${buildQueryString(
              searchTerms
            )}`}
            onClick={this.handleReset}
          >
            <Button
              sx={{ ml: 2, mb: 3 }}
              variant="contained"
              color="primary"
              disableElevation
            >
              Generate Report
            </Button>
          </Link>
        ) : (
          <Link to="/reports-reviews/new-rangereview" onClick={this.showErrors}>
            <Button
              sx={{ ml: 2, mb: 3 }}
              variant="contained"
              color="primary"
              disableElevation
            >
              Generate Report
            </Button>
          </Link>
        )}
      </Card>
    );
  }
}

NewRangeReview.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  isStaticData: PropTypes.bool,
  getData: PropTypes.func,
  dataDate: PropTypes.string,
  isFilteringData: PropTypes.bool,
  isFilteredData: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  allTerms: PropTypes.bool,
  story: PropTypes.string,
  reset: PropTypes.func,
  client: PropTypes.string
};

NewRangeReview.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  searchTerms: [],
  isStaticData: false,
  getData: () => {},
  dataDate: "",
  isFilteringData: false,
  isFilteredData: false,
  filterTerms: [],
  allTerms: false,
  story: "",
  reset: () => {},
  client: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      searchTerms = { story: [] },
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      isFilteringData = false,
      filterTerms = { story: [] },
      allTerms = { story: false }
    },
    data: { subsections = { dataSet: [] }, isStaticData = false }
  } = state;
  return {
    dataDate: state.data.date[dataSet],
    searchTerms: searchTerms[story],
    subsections: subsections[dataSet],
    filteredSubsections: filteredSubsections[story],
    isStaticData,
    isFilteredData: isFilteredData[story],
    isFilteringData,
    filterTerms: filterTerms[story],
    allTerms: allTerms[story],
    client: state.user.user.client
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => {
    dispatch(fetchStaticData(client));
  },
  reset: story => {
    dispatch(clearStorySearchData(story));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRangeReview);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  Tabs,
  Tab,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { EditIcon, ChannelIcon, DeleteIcon } from "../Icons";
import { NoRowsOverlay, Footer, Toolbar, getColumns } from "./Components";
import AddSKU from "./AddSKU";
import { getProducts } from "../../actions";

const Details = props => {
  const {
    name,
    channel,
    environments,
    handleBack,
    getOptions,
    options,
    rows,
    setRows,
    isLoading,
    error
  } = props;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [selected, setSelected] = useState(null);
  const isEmpty = rows.length === 0;

  useEffect(() => {
    getOptions(channel, environments);
  }, []);

  const handleChange = (e, val) => {
    setTab(val);
  };

  return (
    <Grid container>
      <Grid item md={12} sx={{ mb: 2 }}>
        <Stack spacing={2}>
          <Card sx={{ my: 2 }}>
            <CardHeader
              title={
                <Grid container alignItems="center" sx={{ pb: 1 }}>
                  <Typography variant="h4" fontWeight="medium">
                    {name}
                  </Typography>
                  <ChannelIcon
                    color="primary"
                    sx={{ fontSize: 14, ml: 2.5, mr: 0.5 }}
                  />
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                  >
                    {channel === "ih" ? "In Home" : "Out of Home"}
                  </Typography>
                </Grid>
              }
              subheader={
                <Box sx={{ mx: -0.5 }}>
                  {environments.map(i => (
                    <Chip key={i} sx={{ m: 0.5 }} label={i} />
                  ))}
                </Box>
              }
              action={
                <Button
                  size="small"
                  color="yellow"
                  sx={{ mx: 0.5 }}
                  onClick={() => handleBack()}
                >
                  <EditIcon sx={{ fontSize: 16, m: 0.5 }} />
                  Edit
                </Button>
              }
            />
          </Card>
        </Stack>
      </Grid>
      <Box sx={{ width: 1, minHeight: 450 }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1.2
            },
            ".MuiDataGrid-columnHeaders": {
              bgcolor: "#e5e0df",
              textAlign: "center"
            },
            ".MuiDataGrid-columnSeparator": {
              color: "divider"
            }
          }}
          rows={rows}
          columns={getColumns(setIsDeleteOpen, setSelected)}
          autoHeight={!isEmpty}
          headerHeight={112}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          hideFooter={isEmpty}
          components={{ NoRowsOverlay, Footer, Toolbar }}
          componentsProps={{
            noRowsOverlay: { handleClick: () => setIsEditOpen(true) },
            footer: { handleClick: () => setIsEditOpen(true) }
          }}
          loading={isLoading}
          error={error || undefined}
          onCellEditCommit={params =>
            setRows(prev =>
              prev.map(row =>
                row.id === params.id
                  ? {
                      ...row,
                      ...{
                        id: params.id,
                        [params.field]: params.value
                      }
                    }
                  : row
              )
            )
          }
        />
      </Box>
      <Dialog
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <Box sx={{ p: 3.5 }}>
          <Typography variant="h5">Add SKU</Typography>
          <Box sx={{ my: 1, borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={handleChange}>
              <Tab label="Search in Products" />
              <Tab label="By SKU Number" disabled />
            </Tabs>
          </Box>
          <Box sx={{ py: 1, mt: 0.5 }}>
            {tab === 0 && (
              <AddSKU
                type="product"
                options={options}
                rows={rows}
                setRows={setRows}
                onClose={() => setIsEditOpen(false)}
              />
            )}
            {tab === 1 && (
              <AddSKU
                type="sku"
                options={options}
                rows={rows}
                setRows={setRows}
                onClose={() => setIsEditOpen(false)}
              />
            )}
          </Box>
        </Box>
      </Dialog>
      <Dialog open={isDeleteOpen}>
        <Box textAlign="center" sx={{ m: 1, pb: 1 }}>
          <DialogTitle>
            <DeleteIcon sx={{ fontSize: 16 }} color="orange" />
            <Typography variant="h6" fontSize={14} gutterBottom paragraph>
              Removing SKU
            </Typography>
            <Typography variant="subtitle2" gutterBottom component="span">
              {selected ? selected.row.name : ""}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" gutterBottom>
              Removing this sku will erase all inputs relating to this sku for
              the environments selected.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", mb: 1 }}>
            <Button
              variant="contained"
              disableElevation
              color="orange"
              onClick={() => setIsDeleteOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="orange"
              onClick={() => {
                setRows(
                  selected ? rows.filter(i => i.sku !== selected.row.sku) : []
                );
                setSelected(null);
                setIsDeleteOpen(false);
              }}
            >
              Remove SKU
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  );
};

Details.propTypes = {
  name: PropTypes.string,
  channel: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  handleBack: PropTypes.func,
  getOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  setRows: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

Details.defaultProps = {
  name: "",
  channel: "",
  environments: [],
  handleBack: () => {},
  getOptions: () => {},
  options: [],
  rows: [],
  setRows: () => {},
  isLoading: false,
  error: ""
};

const mapDispatchToProps = dispatch => ({
  getOptions: (channel, environments) =>
    dispatch(getProducts(channel, environments, "edit"))
});

export default connect(null, mapDispatchToProps)(Details);

import _ from "lodash";
import {
  LIST_PREFERENCES_URL,
  ADD_PREFERENCE_URL,
  REMOVE_PREFERENCE_URL,
  EDIT_PREFERENCE_URL,
  API_KEY,
  LOOKOUT_URL,
  CHANNELS
} from "../constants";
import {
  ADD_PREFERENCE_REQUEST,
  ADD_PREFERENCE_SUCCESS,
  ADD_PREFERENCE_ERROR,
  REMOVE_PREFERENCE_REQUEST,
  REMOVE_PREFERENCE_SUCCESS,
  REMOVE_PREFERENCE_ERROR,
  EDIT_PREFERENCE_REQUEST,
  EDIT_PREFERENCE_SUCCESS,
  EDIT_PREFERENCE_ERROR,
  GET_PREFERENCES_REQUEST,
  GET_PREFERENCES_SUCCESS,
  GET_PREFERENCES_ERROR,
  GET_LOOKOUT_REQUEST,
  GET_LOOKOUT_SUCCESS,
  GET_LOOKOUT_ERROR,
  CLEAR_LOOKOUT_MESSAGES,
  CLEAR_LOOKOUT_DATA
} from "../constants/actionConstants";
import { addNotification } from "./notification";
import { getLookoutPeriod } from "../utils";
import { getChannelConfig } from "../config";
import logger from "../logger";

function addPreferenceRequest() {
  return {
    type: ADD_PREFERENCE_REQUEST
  };
}

function addPreferenceSuccess(preference, channel, id) {
  return {
    type: ADD_PREFERENCE_SUCCESS,
    preference,
    channel,
    id
  };
}

function addPreferenceError(error) {
  return {
    type: ADD_PREFERENCE_ERROR,
    error
  };
}

export const addPreference =
  (preference, userId, client, channel) => dispatch => {
    dispatch(addPreferenceRequest());
    const { product, context, market } = preference;
    if (product === undefined && market === "no-value") {
      return dispatch(
        addPreferenceError("Please enter a product or market or both")
      );
    }
    if (product !== undefined && context.length === 0) {
      return dispatch(addPreferenceError("Please enter a context"));
    }
    return fetch(ADD_PREFERENCE_URL, {
      method: "post",
      body: JSON.stringify({
        user_id: userId,
        product,
        context,
        market,
        story_type: channel
      }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          const { status, id } = res;
          if (status === "SUCCESS") {
            logger.info({
              date: new Date().toISOString(),
              action: "ADD_PREFERENCE",
              preference,
              user_id: userId,
              client,
              product: "ida"
            });
            dispatch(addPreferenceSuccess(preference, channel, id));
            dispatch(
              addNotification("Preference added successfully", "success")
            );
          } else {
            dispatch(addPreferenceError(res));
          }
        },
        err => {
          dispatch(addPreferenceError(err.message));
        }
      );
  };

function removePreferenceRequest() {
  return {
    type: REMOVE_PREFERENCE_REQUEST
  };
}

function removePreferenceSuccess(id, channel) {
  return {
    type: REMOVE_PREFERENCE_SUCCESS,
    id,
    channel
  };
}

function removePreferenceError(error) {
  return {
    type: REMOVE_PREFERENCE_ERROR,
    error
  };
}

export const removePreference =
  (preference, userId, client, channel) => dispatch => {
    dispatch(removePreferenceRequest());
    const { id } = preference;
    return fetch(REMOVE_PREFERENCE_URL, {
      method: "post",
      body: JSON.stringify({
        user_id: userId,
        id,
        story_type: channel
      }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            logger.info({
              date: new Date().toISOString(),
              action: "REMOVE_PREFERENCE",
              preference,
              user_id: userId,
              client,
              product: "ida"
            });
            dispatch(removePreferenceSuccess(id, channel));
          } else {
            dispatch(removePreferenceError(res));
          }
        },
        err => {
          dispatch(removePreferenceError(err.message));
        }
      );
  };

function editPreferenceRequest() {
  return {
    type: EDIT_PREFERENCE_REQUEST
  };
}

function editPreferenceSuccess(preference, channel, removeId, addId) {
  return {
    type: EDIT_PREFERENCE_SUCCESS,
    preference,
    channel,
    removeId,
    addId
  };
}

function editPreferenceError(error) {
  return {
    type: EDIT_PREFERENCE_ERROR,
    error
  };
}

export const editPreference =
  (preference, userId, client, channel, removeId) => dispatch => {
    dispatch(editPreferenceRequest());
    const { product, context, market } = preference;
    return fetch(EDIT_PREFERENCE_URL, {
      method: "post",
      body: JSON.stringify({
        user_id: userId,
        product,
        context,
        market,
        story_type: channel,
        id: removeId
      }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          const { status, id } = res;
          if (status === "SUCCESS") {
            logger.info({
              date: new Date().toISOString(),
              action: "EDIT_PREFERENCE",
              preference,
              user_id: userId,
              client,
              product: "ida"
            });
            dispatch(editPreferenceSuccess(preference, channel, removeId, id));
          } else {
            dispatch(editPreferenceError(res));
          }
        },
        err => {
          dispatch(editPreferenceError(err.message));
        }
      );
  };

function getPreferencesRequest() {
  return {
    type: GET_PREFERENCES_REQUEST
  };
}

function getPreferencesSuccess(preferences) {
  return {
    type: GET_PREFERENCES_SUCCESS,
    preferences
  };
}

function getPreferencesError(error) {
  return {
    type: GET_PREFERENCES_ERROR,
    error
  };
}

export const getPreferences = userId => dispatch => {
  dispatch(getPreferencesRequest());
  return fetch(LIST_PREFERENCES_URL, {
    method: "post",
    body: JSON.stringify({
      user_id: userId
    }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (String(res).startsWith("ERROR")) {
          dispatch(getPreferencesError(res));
        } else {
          dispatch(getPreferencesSuccess(res));
        }
      },
      err => {
        dispatch(getPreferencesError(err.message));
      }
    );
};

function getLookoutRequest() {
  return {
    type: GET_LOOKOUT_REQUEST
  };
}

function getLookoutSuccess(lookout) {
  return {
    type: GET_LOOKOUT_SUCCESS,
    lookout
  };
}

function getLookoutError(error) {
  return {
    type: GET_LOOKOUT_ERROR,
    error
  };
}

export const getLookout = (userId, dataDate, client) => async dispatch => {
  dispatch(getLookoutRequest());
  try {
    // if trying to load lookout before we have dataDate from static data
    if (_.isEmpty(dataDate)) {
      throw new Error("Please try again later.");
    }
    const lookout = {};
    await Promise.all(
      CHANNELS.map(async i => {
        const config = getChannelConfig(i);
        const { dataSet } = config;
        await fetch(LOOKOUT_URL, {
          method: "post",
          body: JSON.stringify({
            user_id: userId,
            period: getLookoutPeriod(dataDate[dataSet], dataSet === "cga"),
            story_type: i,
            client
          }),
          headers: { "X-API-KEY": API_KEY }
        })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then(
            res => {
              if (String(res).startsWith("ERROR")) {
                lookout[i] = {};
              } else {
                lookout[i] = res;
              }
            },
            err => {
              throw new Error(err);
            }
          );
      })
    );
    // if no lookout values
    if (_.values(lookout).every(_.isEmpty)) {
      dispatch(getLookoutError("Error getting lookout results"));
    } else {
      dispatch(getLookoutSuccess(lookout));
    }
  } catch (err) {
    dispatch(getLookoutError(err.message));
  }
};

export function clearLookoutMessages() {
  return {
    type: CLEAR_LOOKOUT_MESSAGES
  };
}

export const clearLookoutData = () => ({
  type: CLEAR_LOOKOUT_DATA
});

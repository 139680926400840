import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Box,
  Button
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { ChannelEnvironment } from "../SharedComponents";
import Rankings from "./Rankings";
import { editSterling } from "../../actions";

const RankingCustomisation = props => {
  const { options, isLoading, edit } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [channel, setChannel] = useState("ih");
  const [environments, setEnvironments] = useState([]);
  const [rankList, setRankList] = useState([]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setRankList([]);
  };

  return (
    <Card sx={{ p: 2.5, width: 1 }}>
      <Grid item md={8}>
        <CardHeader
          title="Ranking Customisation"
          titleTypographyProps={{ variant: "h2", gutterBottom: true }}
          subheader={
            <Typography variant="body2" gutterBottom>
              This sheet allows you to edit the prioiritisation to be given to
              each category or sector, brand and sub brand. This will impact the
              overall ranking of skus, with changes here affecting all skus that
              match the attribute you edit. By default all options have equal
              weighting, so not editing them mean that the model will not be
              affected and only show inputs from other sources.
            </Typography>
          }
        />
      </Grid>
      <CardContent>
        <Grid container>
          {activeStep === 0 && (
            <Grid item md={8}>
              <Stack spacing={5}>
                <ChannelEnvironment
                  channel={channel}
                  setChannel={setChannel}
                  environments={environments}
                  setEnvironments={setEnvironments}
                />
              </Stack>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid item md={12} sx={{ mb: 2 }}>
              <Rankings
                channel={channel}
                environments={environments}
                handleBack={handleBack}
                options={options}
                rankList={rankList}
                setRankList={setRankList}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 5, px: 2 }}
      >
        <Grid item md={6}>
          <Box textAlign="left">
            {activeStep === 0 ? null : (
              <Button startIcon={<ArrowBack />} onClick={handleBack}>
                Back
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box textAlign="right">
            {activeStep === 0 ? (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleNext}
                disabled={environments.length === 0}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() =>
                  edit(
                    _.differenceWith(rankList, options, _.isEqual),
                    channel,
                    environments,
                    handleBack
                  )
                }
                disabled={
                  isLoading || options.length === 0 || rankList.length === 0
                }
              >
                Apply Changes
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

RankingCustomisation.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  edit: PropTypes.func
};

RankingCustomisation.defaultProps = {
  options: [],
  isLoading: false,
  edit: () => {}
};

const mapStateToProps = state => ({
  options: state.sterling.rankings,
  isLoading: state.sterling.isLoading
});

const mapDispatchToProps = dispatch => ({
  edit: (data, channel, environments, onSuccess) =>
    dispatch(
      editSterling("rankings", data, [], channel, environments, onSuccess)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingCustomisation);

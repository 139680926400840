import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Typography
} from "@mui/material";
import {
  ReportsReviewsIcon,
  PerformanceIcon,
  RangeReviewIcon,
  BrandBattlesIcon
} from "../Icons";

const LeftMenu = props => {
  const { type } = props;
  const [story, setStory] = useState("");
  useEffect(() => {
    if (type.endsWith("performance")) {
      setStory("ida");
    }
    if (type.endsWith("rangereview")) {
      setStory("prr");
    }
    if (type.endsWith("brandbattles")) {
      setStory("bb");
    }
  }, []);
  return (
    <Card>
      <ListItem sx={{ bgcolor: "#f9f9f9" }}>
        <ListItemIcon>
          <ReportsReviewsIcon color="secondaryDark" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="subtitle2"
              color="secondary.dark"
              fontSize={16}
            >
              Reports & Reviews
            </Typography>
          }
        />
      </ListItem>
      <List component="nav">
        <Link to="/reports-reviews/performance" onClick={() => setStory("ida")}>
          <ListItemButton>
            <ListItemIcon>
              <PerformanceIcon
                color={story === "ida" ? "primaryDark" : "auto"}
                fontSize="inherit"
              />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography
                  variant="body2"
                  color={story === "ida" ? "primary.dark" : "textPrimary"}
                >
                  Performance Reports
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
        <Link to="/reports-reviews/brandbattles" onClick={() => setStory("bb")}>
          <ListItemButton>
            <ListItemIcon>
              <BrandBattlesIcon
                color={story === "bb" ? "primaryDark" : "auto"}
                fontSize="inherit"
              />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography
                  variant="body2"
                  color={story === "bb" ? "primary.dark" : "textPrimary"}
                >
                  Brand Battles
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
        <Link to="/reports-reviews/rangereview" onClick={() => setStory("prr")}>
          <ListItemButton>
            <ListItemIcon>
              <RangeReviewIcon
                color={story === "prr" ? "primaryDark" : "auto"}
                fontSize="inherit"
              />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography
                  variant="body2"
                  color={story === "prr" ? "primary.dark" : "textPrimary"}
                >
                  Range Review Impact Assessment
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
      </List>
    </Card>
  );
};

LeftMenu.propTypes = {
  type: PropTypes.string
};

LeftMenu.defaultProps = {
  type: ""
};

export default LeftMenu;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  Typography,
  Button,
  IconButton
} from "@mui/material";
import { StarBorderRounded, StarRounded } from "@mui/icons-material";
import { BrandBattlesIcon } from "../Icons";
import { getMyRecentReports, getMyFavouriteReports } from "../../actions";
import { sortByDate } from "../../utils";
import { getStoryConfig } from "../../config";
import { ReportCard, Loader } from "../SharedComponents";

const BrandBattlesHistory = props => {
  const [isStarred, setIsStarred] = useState(false);
  const {
    myRecentReports,
    isLoadingMyReports,
    recentError,
    myFavouriteReports,
    isLoadingFavourites,
    favouritesError,
    getRecentReports,
    getFavourites,
    userId,
    story,
    dataDate,
    client
  } = props;

  useEffect(() => {
    if (myRecentReports.length < 1 || myFavouriteReports.length < 1) {
      getRecentReports(userId);
      getFavourites(userId);
    }
  }, []);

  const listedReports = isStarred ? myFavouriteReports : myRecentReports;

  return (
    <Card>
      <CardHeader
        avatar={<BrandBattlesIcon fontSize="small" color="secondaryDark" />}
        titleTypographyProps={{ variant: "h5" }}
        title="Brand Battles (Product Comparison)"
      />
      <Typography variant="body2" gutterBottom sx={{ px: 2 }}>
        Run a report to compare the performance of two products within a
        specific market or retailer. This report will allow you to identify what
        may have enabled one product to win in the chosen time frame and provide
        you with key insights into suggested actions.
      </Typography>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Link to="/reports-reviews/new-brandbattles">
            <Button variant="contained" color="primary" disableElevation>
              New Brand Battles Report
            </Button>
          </Link>
          <IconButton onClick={() => setIsStarred(!isStarred)} size="large">
            {isStarred ? (
              <StarRounded fontSize="large" color="yellow" />
            ) : (
              <StarBorderRounded fontSize="large" />
            )}
          </IconButton>
        </Grid>
        {((isLoadingFavourites || isLoadingMyReports) && <Loader />) ||
          ((favouritesError || recentError) && (
            <Typography variant="body2" color="red">
              There was an error loading your reports:
              <br />
              {favouritesError && favouritesError}
              {recentError && recentError}
            </Typography>
          )) ||
          ((myRecentReports || myFavouriteReports) && (
            <Grid container spacing={3}>
              {listedReports
                .map(i => {
                  const urlParams = new URLSearchParams(i.query);
                  const params = Object.fromEntries(urlParams);
                  return { ...i, ...params };
                })
                .filter(e => e.story && story.includes(e.story.toLowerCase()))
                .sort((a, b) => sortByDate(a, b))
                .map(report => {
                  const isFavourite =
                    myFavouriteReports.filter(e => e.query === report.query)
                      .length > 0;
                  const { dataSet } = getStoryConfig(report.story);
                  return (
                    <ReportCard
                      key={report.query}
                      report={report}
                      isFavourite={isFavourite}
                      userId={userId}
                      dataDate={dataDate[dataSet]}
                      client={client}
                    />
                  );
                })}
            </Grid>
          ))}
      </CardContent>
    </Card>
  );
};

BrandBattlesHistory.propTypes = {
  getRecentReports: PropTypes.func,
  userId: PropTypes.string,
  myRecentReports: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingMyReports: PropTypes.bool,
  recentError: PropTypes.string,
  getFavourites: PropTypes.func,
  myFavouriteReports: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingFavourites: PropTypes.bool,
  favouritesError: PropTypes.string,
  story: PropTypes.string,
  dataDate: PropTypes.shape(),
  client: PropTypes.string
};

BrandBattlesHistory.defaultProps = {
  getRecentReports: () => {},
  userId: "",
  myRecentReports: [],
  isLoadingMyReports: false,
  recentError: "",
  getFavourites: () => {},
  myFavouriteReports: [],
  isLoadingFavourites: false,
  favouritesError: "",
  story: "",
  dataDate: {},
  client: ""
};

const mapStateToProps = state => ({
  myRecentReports: state.report.myRecentReports,
  myFavouriteReports: state.report.myFavouriteReports,
  userId: state.user.user.id,
  client: state.user.user.client,
  isLoadingMyReports: state.report.isLoadingMyReports,
  recentError: state.report.myRecentReportsError,
  isLoadingFavourites: state.report.isLoadingFavourites,
  favouritesError: state.report.myFavouriteReportsError,
  dataDate: state.data.date
});

const mapDispatchToProps = dispatch => ({
  getRecentReports: userId => {
    dispatch(getMyRecentReports(userId));
  },
  getFavourites: userId => {
    dispatch(getMyFavouriteReports(userId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandBattlesHistory);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  List,
  ListItem,
  Popover,
  Drawer,
  Hidden,
  Box
} from "@mui/material";
import { Menu, AccountCircleOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  logout,
  clearStaticDataCache,
  clearAllSearchData,
  clearLookoutData,
  clearSterlingData
} from "../actions";
import { SettingsIcon, LogoutIcon } from "./Icons";
import { logo } from "../images";

const styles = {
  container: { width: "calc(90% - 80px)" },
  menuItem: { mx: 1, fontWeight: "normal", textTransform: "none" },
  subMenuItem: { mx: 0, px: 3, display: "flex", justifyContent: "left" },
  icon: { height: 16, width: 16 }
};

const MenuBar = props => {
  const { onLogout } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = isOpen => e => {
    if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    setIsDrawerOpen(isOpen);
  };

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="secondaryDark" position="static" sx={{ height: 100 }}>
        <Toolbar>
          {/* mobile menu */}
          <Hidden smUp>
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              size="large"
            >
              <Menu htmlColor="white" />
            </IconButton>
            <Drawer
              anchor="left"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
            >
              <List>
                <ListItem
                  button
                  component={Link}
                  to="/"
                  onClick={toggleDrawer(false)}
                  sx={{ color: "secondary.dark" }}
                >
                  Home
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/reports-reviews/performance"
                  onClick={toggleDrawer(false)}
                  sx={{ color: "secondary.dark" }}
                >
                  Reports & Reviews
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/lookout:0"
                  onClick={toggleDrawer(false)}
                  sx={{ color: "secondary.dark" }}
                >
                  Lookout
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/sterling-options"
                  onClick={toggleDrawer(false)}
                  sx={{ color: "secondary.dark" }}
                >
                  Sterling Portfolio Prioritisation
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/about"
                  onClick={toggleDrawer(false)}
                  sx={{ color: "secondary.dark" }}
                >
                  About
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/support"
                  onClick={toggleDrawer(false)}
                  sx={{ color: "secondary.dark" }}
                >
                  Support
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/settings"
                  onClick={toggleDrawer(false)}
                  sx={{ color: "secondary.dark" }}
                >
                  Settings
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/logout"
                  onClick={() => {
                    onLogout();
                    toggleDrawer(false);
                  }}
                  sx={{ color: "secondary.dark" }}
                >
                  Logout
                </ListItem>
              </List>
            </Drawer>
          </Hidden>
          <Link to="/">
            <img
              src={logo}
              height="100%"
              alt="Interrodata"
              style={{ height: 140 }}
            />
          </Link>
          {/* main menu */}
          <Hidden smDown>
            <Button
              sx={styles.menuItem}
              variant="contained"
              color="secondaryDark"
              disableElevation
              component={Link}
              to="/"
            >
              Home
            </Button>
            <Button
              sx={styles.menuItem}
              variant="contained"
              color="secondaryDark"
              disableElevation
              component={Link}
              to="/reports-reviews/performance"
            >
              Reports & Reviews
            </Button>
            <Button
              sx={styles.menuItem}
              variant="contained"
              color="secondaryDark"
              disableElevation
              component={Link}
              to="/lookout:0"
            >
              Lookout
            </Button>
            <Button
              sx={styles.menuItem}
              variant="contained"
              color="secondaryDark"
              disableElevation
              component={Link}
              to="/sterling-options"
            >
              Sterling Portfolio Prioritisation
            </Button>
            <Button
              sx={styles.menuItem}
              variant="contained"
              color="secondaryDark"
              disableElevation
              component={Link}
              to="/about"
            >
              About
            </Button>
            <Button
              sx={styles.menuItem}
              variant="contained"
              color="secondaryDark"
              disableElevation
              component={Link}
              to="/support"
            >
              Support
            </Button>
            <Box component="div" sx={{ flexGrow: 1 }} />
            <Button
              sx={styles.menuItem}
              variant="contained"
              color="secondaryDark"
              disableElevation
              onClick={openMenu}
            >
              <AccountCircleOutlined sx={{ height: 28, width: 28, mx: 1 }} />
            </Button>
            <Popover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <Button
                sx={{ ...styles.menuItem, ...styles.subMenuItem }}
                color="secondaryDark"
                component={Link}
                to="/settings"
                onClick={closeMenu}
                startIcon={<SettingsIcon sx={styles.icon} />}
                fullWidth
              >
                Settings
              </Button>
              <Button
                sx={{ ...styles.menuItem, ...styles.subMenuItem }}
                color="secondaryDark"
                component={Link}
                to="/logout"
                onClick={() => {
                  onLogout();
                  closeMenu();
                }}
                startIcon={<LogoutIcon sx={styles.icon} />}
                fullWidth
              >
                Logout
              </Button>
            </Popover>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

MenuBar.propTypes = {
  onLogout: PropTypes.func
};

MenuBar.defaultProps = {
  onLogout: () => {}
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(logout());
    // force data refresh on logout
    dispatch(clearStaticDataCache());
    dispatch(clearAllSearchData());
    dispatch(clearLookoutData());
    dispatch(clearSterlingData());
  }
});

export default connect(null, mapDispatchToProps)(MenuBar);

import config from "../config";

// ------ api constants

// main backend
export const BACKEND_HOST = config.serverURL.backend;
// report
export const REPORT_URL = `${BACKEND_HOST}/getReport`;

// static data
export const STATIC_DATA_HOST = config.serverURL.staticData;
// static data api
export const STATIC_DATA_URL = `${STATIC_DATA_HOST}/getStaticData`;
export const FILTER_STATIC_DATA_URL = `${STATIC_DATA_HOST}/getFilteredStaticData`;
export const CONTEXT_URL = `${STATIC_DATA_HOST}/getContext`;

// powerpoint
export const PPT_URL = config.serverURL.ppt;

// user
export const USER_HOST = config.serverURL.user;
// user api
export const CREATE_USER_URL = `${USER_HOST}/CreateUser`;
export const SIGN_IN_URL = `${USER_HOST}/SignIn`;
export const CHANGE_PASSWORD_URL = `${USER_HOST}/ChangePassword`;
export const UNBLOCK_USER_URL = `${USER_HOST}/UnblockUser`;
export const DISABLE_USER_URL = `${USER_HOST}/DisableUser`;
export const ENABLE_USER_URL = `${USER_HOST}/EnableUser`;
export const RESET_PASSWORD_URL = `${USER_HOST}/ResetPassword`;
export const FORGOT_PASSWORD_URL = `${USER_HOST}/ForgotPassword`;
export const FORGOT_PASSWORD_RESET_URL = `${USER_HOST}/ForgotPasswordReset`;
export const USER_TRACKING_URL = `${USER_HOST}/AddUserTracking`;
export const LIST_RECENT_REPORTS_URL = `${USER_HOST}/ListRecentReports`;
export const ADD_TO_FAVOURITES_URL = `${USER_HOST}/AddToFavourites`;
export const REMOVE_FROM_FAVOURITES_URL = `${USER_HOST}/RemoveFromFavourites`;
export const LIST_FAVOURITES_URL = `${USER_HOST}/ListFavourites`;
export const GET_CLIENTS_URL = `${USER_HOST}/GetClients`;
export const GET_DEPARTMENTS_URL = `${USER_HOST}/GetDepartments`;
export const ADD_DEPARTMENT_URL = `${USER_HOST}/AddDepartment`;

// data model
export const DATA_MODEL_HOST = config.serverURL.dataModel;
// lookout api
export const LIST_PREFERENCES_URL = `${DATA_MODEL_HOST}/ListPreferences`;
export const ADD_PREFERENCE_URL = `${DATA_MODEL_HOST}/AddPreference`;
export const REMOVE_PREFERENCE_URL = `${DATA_MODEL_HOST}/RemovePreference`;
export const EDIT_PREFERENCE_URL = `${DATA_MODEL_HOST}/EditPreference`;
export const LOOKOUT_URL = `${DATA_MODEL_HOST}/GetLookoutResults`;

// subscription
export const SUBSCRIPTION_HOST = config.serverURL.subscription;
// subscription api
export const ADD_CONTACT_URL = `${SUBSCRIPTION_HOST}/AddContact`;
export const REMOVE_CONTACT_URL = `${SUBSCRIPTION_HOST}/RemoveContact`;
export const SUBSCRIBE_URL = `${SUBSCRIPTION_HOST}/Subscribe`;
export const UNSUBSCRIBE_URL = `${SUBSCRIPTION_HOST}/Unsubscribe`;
export const CHECK_SUBSCRIPTION_URL = `${SUBSCRIPTION_HOST}/CheckSubscribed`;

// sterling
export const STERLING_HOST = config.serverURL.sterling;
// sterling api
export const GET_ENVIRONMENTS_URL = `${STERLING_HOST}/GetEnvironments`;
export const GET_PRODUCTS_URL = `${STERLING_HOST}/GetProducts`;
export const GET_RANKINGS_URL = `${STERLING_HOST}/GetRankings`;
export const GET_WEIGHTINGS_URL = `${STERLING_HOST}/GetWeightings`;
export const EDIT_STERLING_URL = `${STERLING_HOST}/EditSterling`;
export const GET_SHEETS_URL = `${STERLING_HOST}/GetSheets`;

// ------ app constants

// ------ aws config

const getAWSConfig = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "dev":
      return {
        accessKeyId: "AKIARJEYGV32PDEIO4OS",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    case "qa":
      return {
        accessKeyId: "AKIAXJWQC4GHNQINRIV2",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    case "stg":
      return {
        accessKeyId: "AKIAWMLRBVIPZVC6HGPX",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    case "prod":
      return {
        accessKeyId: "AKIASTBT7AYFQG2JSB7V",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
    default:
      return {
        accessKeyId: "AKIARJEYGV32PDEIO4OS",
        secretAccessKey: "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",
        region: "eu-west-2"
      };
  }
};

const getTableNames = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "dev":
      return {
        REPORT_TABLE: "AnalysisReports-dev",
        REPORT_TABLE_CGA: "cga-analysis-results-dev",
        REPORT_TABLE_BB: "bb-analysis-results-dev"
      };
    case "qa":
      return {
        REPORT_TABLE: "AnalysisReports-qa",
        REPORT_TABLE_CGA: "cga-analysis-results-qa",
        REPORT_TABLE_BB: "bb-analysis-results-qa"
      };
    case "stg":
      return {
        REPORT_TABLE: "AnalysisReports-stg",
        REPORT_TABLE_CGA: "cga-analysis-results-stg",
        REPORT_TABLE_BB: "bb-analysis-results-stg"
      };
    case "prod":
      return {
        REPORT_TABLE: "AnalysisReports-prod",
        REPORT_TABLE_CGA: "cga-analysis-results-prod",
        REPORT_TABLE_BB: "bb-analysis-results-prod"
      };
    default:
      return {
        REPORT_TABLE: "AnalysisReports-dev",
        REPORT_TABLE_CGA: "cga-analysis-results-dev",
        REPORT_TABLE_BB: "bb-analysis-results-dev"
      };
  }
};

const getAPIKey = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "dev":
      return "5vgs75Oos08OzeWcXX6R655dwzfjJNlc1gHCJV0v";
    case "qa":
      return "ZSzaaAYOtH72pHxtmo4x02ZrIjMwHKz769rDa7Gt";
    case "stg":
      return "Iv2gWYkH9W8UJZx1J94RdaQN5iEqi7JL481pTcQa";
    case "prod":
      return "inrfPOT2954nK10kRAUQG38c7fI48UmO7v5wQLpw";
    default:
      return "5vgs75Oos08OzeWcXX6R655dwzfjJNlc1gHCJV0v";
  }
};

export const AWS_CONFIG = {
  ...getAWSConfig()
};
export const REPORT_TABLE = {
  ida: `${getTableNames().REPORT_TABLE}`,
  prr: `${getTableNames().REPORT_TABLE}`,
  cga: `${getTableNames().REPORT_TABLE_CGA}`,
  bb: `${getTableNames().REPORT_TABLE_BB}`
};
export const API_KEY = getAPIKey();

// cookie timeout
export const TIMEOUT = config.app.timeout;

// polling constants
export const FIRST_POLL_TIMER = config.app.firstPollTimer;
export const GENERAL_POLL_TIMER = config.app.generalPollTimer;
export const TOTAL_POLLS = config.app.totalPolls;

// data constants
export const HIERARCHY_LIST = config.hierarchList;
export const GROUPINGS = config.groupings;
export const BB_PRODUCTS = config.bbProducts;
export const DATA_SETS = config.dataSets;
export const CHANNELS = config.channels;
export const STERLING_HIGHLIGHT_COLUMNS = config.sterlingHighlightColumns;

// number of search suggestions
export const NUMBER_SUGGESTIONS = config.app.numberSuggestions;

// number of preferences
export const NUMBER_PREFERENCES = config.app.numberPreferences;

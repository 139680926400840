import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardHeader, Typography, Button } from "@mui/material";
import { PerformanceIcon } from "../Icons";
import WhatSection from "./WhatSection";
import SearchInput from "./SearchInput";
import { WhereSection, WhenSection, MeasureSection } from "../SharedComponents";
import Review from "./Review";
import {
  fetchStaticData,
  clearStorySearchData,
  deleteTerm
} from "../../actions";

function loadWidget() {
  window.HubSpotConversations.widget.load();
}

function refreshWidget() {
  window.HubSpotConversations.widget.refresh();
}

class NewPerformanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdvancedSearch: false,
      activeStep: 0
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.clearContext = this.clearContext.bind(this);
  }

  componentDidMount() {
    const { isStaticData, getData, client } = this.props;
    if (!isStaticData) {
      getData(client);
    }
    if (window.HubSpotConversations) {
      loadWidget();
    } else {
      window.hsConversationsOnReady = [loadWidget];
    }
  }

  componentWillUnmount() {
    if (window.HubSpotConversations) {
      refreshWidget();
    } else {
      window.hsConversationsOnReady = [refreshWidget];
    }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleReset = () => {
    const { reset, story } = this.props;
    reset(story);
  };

  clearContext = () => {
    const { searchTerms, story, deleteTerms } = this.props;
    const contextTerms = searchTerms.filter(i => i.table === "context");
    deleteTerms(contextTerms, story);
  };

  render() {
    const {
      subsections,
      filteredSubsections,
      searchTerms,
      allTerms,
      hint,
      isFilteredData,
      filterTerms,
      isFilteringData,
      story,
      isStaticData,
      isLoadingContext,
      context,
      contextSearchEngine,
      suggestedContext,
      client,
      searchEngine,
      filteredSearchEngine,
      placeholder
    } = this.props;
    const { isAdvancedSearch, activeStep } = this.state;
    return (
      <Card
        className={
          !isStaticData || isFilteringData || isLoadingContext
            ? "wait"
            : "overflow"
        }
      >
        <CardHeader
          avatar={<PerformanceIcon fontSize="small" color="secondaryDark" />}
          titleTypographyProps={{ variant: "h5" }}
          title={`Performance Reports ${
            story === "ida"
              ? "(In-Home – Including Impulse)"
              : "(Out-of-Home – Excluding Impulse)"
          }`}
        />
        <Typography variant="body2" sx={{ px: 2 }}>
          Run a performance report to understand how a category or brand is
          performing over time, in a specific
          {story === "ida" ? " market " : " channel "}
          or retailer.
        </Typography>
        {(activeStep === 0 && (
          <Fragment>
            <SearchInput
              searchTerms={searchTerms}
              filterTerms={filterTerms}
              searchEngine={searchEngine}
              filteredSearchEngine={filteredSearchEngine}
              placeholder={placeholder}
              story={story}
              client={client}
              isFilteredData={isFilteredData}
              allTerms={allTerms}
              hint={hint}
              isFilteringData={isFilteringData}
              isStaticData={isStaticData}
              handleNext={this.handleNext}
            />
            {isAdvancedSearch ? (
              <Fragment>
                <WhatSection
                  searchTerms={searchTerms.filter(s => s.table === "what")}
                  options={
                    isFilteredData
                      ? filteredSubsections.filter(s => s.table === "what")
                      : subsections.filter(s => s.table === "what")
                  }
                  filterTerms={filterTerms}
                  hint={hint}
                  story={story}
                  isFilteringData={isFilteringData}
                  client={client}
                />
                <WhereSection
                  selected={searchTerms.find(s => s.table === "where")}
                  options={subsections
                    .filter(s => s.table === "where")
                    .filter(i => i.name !== "prr_retailer")}
                  hint={hint}
                  story={story}
                />
                <WhenSection
                  selected={searchTerms.find(s => s.table === "when")}
                  options={subsections.filter(s => s.table === "when")}
                  hint={hint}
                  story={story}
                />
                <MeasureSection
                  selected={searchTerms.find(s => s.table === "measure")}
                  options={subsections.filter(s => s.table === "measure")}
                  hint={hint}
                  story={story}
                />
              </Fragment>
            ) : (
              <Fragment>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ mx: 2, mt: 2 }}
                >
                  Can&apos;t find what you&apos;re looking for?
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ mx: 2, mb: 2 }}
                  onClick={() =>
                    this.setState({ isAdvancedSearch: !isAdvancedSearch })
                  }
                >
                  Advanced Search
                </Button>
              </Fragment>
            )}
          </Fragment>
        )) ||
          (activeStep === 1 && (
            <Review
              searchTerms={searchTerms}
              story={story}
              handleReset={this.handleReset}
              handleBack={this.handleBack}
              isLoadingContext={isLoadingContext}
              context={context}
              searchEngine={contextSearchEngine}
              clearContext={this.clearContext}
              suggestedContext={suggestedContext}
              client={client}
            />
          ))}
      </Card>
    );
  }
}

NewPerformanceReport.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  allTerms: PropTypes.bool,
  getData: PropTypes.func,
  isStaticData: PropTypes.bool,
  hint: PropTypes.string,
  isFilteredData: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  isFilteringData: PropTypes.bool,
  story: PropTypes.string,
  reset: PropTypes.func,
  isLoadingContext: PropTypes.bool,
  context: PropTypes.arrayOf(PropTypes.shape()),
  contextSearchEngine: PropTypes.shape(),
  deleteTerms: PropTypes.func,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  searchEngine: PropTypes.shape(),
  filteredSearchEngine: PropTypes.shape(),
  placeholder: PropTypes.string
};

NewPerformanceReport.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  searchTerms: [],
  allTerms: false,
  getData: () => {},
  isStaticData: false,
  hint: "",
  isFilteredData: false,
  filterTerms: [],
  isFilteringData: false,
  story: "",
  reset: () => {},
  isLoadingContext: false,
  context: [],
  contextSearchEngine: {},
  deleteTerms: () => {},
  suggestedContext: [],
  client: "",
  searchEngine: {},
  filteredSearchEngine: {},
  placeholder: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      searchTerms = { story: [] },
      allTerms = { story: false },
      hint = "",
      filteredSubsections = { story: [] },
      isFilteredData = { story: false },
      filterTerms = { story: [] },
      isFilteringData = false,
      isLoadingContext = false,
      context = { story: [] },
      contextSearchEngine = { story: {} },
      suggestedContext = { story: [] },
      filteredSearchEngine = { story: {} }
    },
    data: {
      subsections = { dataSet: [] },
      isStaticData = false,
      searchEngine = { dataSet: {} },
      placeholder = { dataSet: "" }
    }
  } = state;
  return {
    searchTerms: searchTerms[story],
    subsections: subsections[dataSet],
    allTerms: allTerms[story],
    isStaticData,
    hint,
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story],
    filterTerms: filterTerms[story],
    isFilteringData,
    isLoadingContext,
    context: context[story],
    contextSearchEngine: contextSearchEngine[story],
    suggestedContext: suggestedContext[story],
    client: state.user.user.client,
    searchEngine: searchEngine[dataSet],
    placeholder: placeholder[dataSet],
    filteredSearchEngine: filteredSearchEngine[story]
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => {
    dispatch(fetchStaticData(client));
  },
  reset: story => {
    dispatch(clearStorySearchData(story));
  },
  deleteTerms: (terms, story) => {
    terms.forEach(i => {
      dispatch(deleteTerm(i, story));
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPerformanceReport);

import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, Grid, Avatar } from "@mui/material";
import {
  SettingsIcon,
  SKUIcon,
  RankingIcon,
  WeightingsIcon,
  SKUEditIcon
} from "../Icons";

const styles = {
  option: {
    height: 120,
    color: "secondary.dark",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
      color: "secondary.main"
    }
  },
  avatar: {
    borderRadius: "50%",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.3)",
    bgcolor: "white",
    ml: 1
  }
};

const Sterling = () => (
  <Card sx={{ p: 2.5, pb: 10, width: 1 }}>
    <CardHeader
      titleTypographyProps={{ variant: "h2" }}
      avatar={<SettingsIcon color="secondary" />}
      title="Configuration"
    />
    <Grid container spacing={3} sx={{ p: 2 }}>
      <Grid item md={4} xs={6}>
        <Card sx={styles.option} component={Link} to="/weightings">
          <CardHeader
            titleTypographyProps={{ variant: "body1", fontWeight: "bold" }}
            avatar={
              <Avatar sx={styles.avatar}>
                <WeightingsIcon color="secondaryDark" fontSize="small" />
              </Avatar>
            }
            title="Input Weighting to Ranking Score"
          />
        </Card>
      </Grid>
      <Grid item md={4} xs={6}>
        <Card sx={styles.option} component={Link} to="/ranking-customisation">
          <CardHeader
            titleTypographyProps={{ variant: "body1", fontWeight: "bold" }}
            avatar={
              <Avatar sx={styles.avatar}>
                <RankingIcon color="secondaryDark" fontSize="small" />
              </Avatar>
            }
            title="Category, Brand, Liquid Prioritisation"
          />
        </Card>
      </Grid>
      <Grid item md={4} xs={6}>
        <Card sx={styles.option} component={Link} to="/sku-editing">
          <CardHeader
            titleTypographyProps={{ variant: "body1", fontWeight: "bold" }}
            avatar={
              <Avatar sx={styles.avatar}>
                <SKUEditIcon color="secondaryDark" fontSize="small" />
              </Avatar>
            }
            title="Individual SKU Prioritisation"
          />
        </Card>
      </Grid>
      <Grid item md={4} xs={6}>
        <Card sx={styles.option} component={Link} to="/sku-prioritisation">
          <CardHeader
            titleTypographyProps={{ variant: "body1", fontWeight: "bold" }}
            avatar={
              <Avatar sx={styles.avatar}>
                <SKUIcon color="secondaryDark" fontSize="small" />
              </Avatar>
            }
            title="SKU Prioritisation Output"
          />
        </Card>
      </Grid>
    </Grid>
  </Card>
);

export default Sterling;

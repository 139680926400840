import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Collapse,
  Box,
  FormHelperText
} from "@mui/material";
import ExamplesBox from "./ExamplesBox";
import { SearchBar, AdvancedSearch } from "../SharedComponents";
import { customFilter, customStyles } from "../../utils";
import {
  enterSelectedTerm,
  deleteTerm,
  getContext,
  addPreference,
  editPreference,
  fetchStaticData
} from "../../actions";

const AddPreference = props => {
  const {
    isStaticData,
    searchEngine,
    subsections,
    client,
    getProductContext,
    isLoadingContext,
    contextSubesctions,
    contextSearchEngine,
    onChange,
    searchTerms,
    onSelect,
    addToPreferences,
    onClose,
    preference,
    error,
    userId,
    story,
    getData,
    channel,
    edit
  } = props;
  const [product, setProduct] = useState(undefined);
  const [context, setContext] = useState([]);
  const [market, setMarket] = useState(
    preference.market ? preference.market : "no-value"
  );
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [isAdvancedContextSearch, setIsAdvancedContextSearch] = useState(false);
  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
    setProduct((preference && preference.product) || undefined);
    setContext((preference && preference.context) || null);
  }, []);
  useEffect(() => {
    setProduct(searchTerms.find(s => s.table === "what") || undefined);
    setContext(searchTerms.filter(s => s.table === "context") || null);
  }, [searchTerms]);
  const searchOptions = isStaticData
    ? searchEngine._docs.filter(i => i.table === "what")
    : [];
  const marketList = subsections
    .filter(e => e.name === "retailer")
    .map(option =>
      option.keywords.map(kw => ({ name: kw, subsection: option.name }))
    )
    .reduce((array, option) => array.concat(option), []);
  const marketOptions = marketList.map(option => ({
    value: `${option.subsection}--${option.name}`,
    label: option.name
  }));
  const errorRef = useRef(error);
  errorRef.current = error;
  return (
    <div className={!isStaticData || isLoadingContext ? "wait" : "overflow"}>
      <Typography variant="subtitle2">
        Describe what you would like to add as a preference
      </Typography>
      <ExamplesBox channel={channel} />
      <Typography variant="subtitle2" gutterBottom>
        Target Product
      </Typography>
      <Grid container spacing={3} alignItems="center" sx={{ mt: -5, mb: 2 }}>
        <Grid item md={6}>
          <SearchBar
            searchOptions={searchOptions}
            value={product}
            onChange={(e, val) => {
              setProduct(val);
              // add to search terms
              onChange(val, product, story);
              if (val) {
                // reset context
                context.forEach(i => {
                  // remove from search terms
                  onChange(null, i, story);
                });
                getProductContext([val], [], story, client, true);
              } else if (context.length > 0) {
                // remove context
                getProductContext([], [], story, client);
              }
            }}
            searchEngine={searchEngine}
            disabled={!isStaticData || isLoadingContext}
            size="small"
          />
        </Grid>
        <Grid item md={6}>
          <Collapse in={!isAdvancedSearch}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => setIsAdvancedSearch(true)}
              sx={{ mt: 1 }}
            >
              Advanced Search
            </Button>
          </Collapse>
        </Grid>
      </Grid>
      <Collapse in={isAdvancedSearch}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <AdvancedSearch
            searchTerms={searchTerms.filter(s => s.table === "what")}
            options={subsections.filter(s => s.table === "what")}
            story={story}
            isFilteringData={isLoadingContext}
            disableMultiple
            disableFiltering
          />
          <Box textAlign="center">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
        </Paper>
      </Collapse>
      <Typography variant="subtitle2" gutterBottom>
        Product Scope
      </Typography>
      <Grid container spacing={3} alignItems="center" sx={{ mt: -5, mb: 2 }}>
        <Grid item md={6}>
          <SearchBar
            searchOptions={contextSearchEngine._docs || []}
            value={context}
            onChange={(e, val, reason) => {
              if (_.isEqual(reason, "selectOption")) {
                const toAdd = val[val.length - 1];
                const term = toAdd.name.value
                  ? { ...toAdd, name: toAdd.name.value, story }
                  : { ...toAdd, story };
                const contextTerms = [...context, term];
                // add to search terms
                onChange(toAdd, null, story);
                // update context
                getProductContext([product], contextTerms, story, client);
              } else if (_.isEqual(reason, "removeOption")) {
                const toDelete = _.difference(context, val)[0];
                const contextTerms = context.filter(
                  t => !_.isEqual(t, toDelete)
                );
                // remove from search terms
                onChange(null, toDelete, story);
                // update context
                getProductContext([product], contextTerms, story, client);
              } else if (_.isEqual(reason, "clear")) {
                context.forEach(i => {
                  // remove from search terms
                  onChange(null, i, story);
                });
                // reset context
                getProductContext([product], [], story, client);
              }
            }}
            searchEngine={contextSearchEngine}
            disabled={!product || isLoadingContext}
            size="small"
            multiple
          />
        </Grid>
        <Grid item md={6}>
          <Collapse in={!isAdvancedContextSearch}>
            {!product || isLoadingContext ? (
              <Button
                variant="contained"
                disableElevation
                onClick={() => setIsAdvancedContextSearch(true)}
                sx={{ mt: 1 }}
                disabled
              >
                Advanced Search
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setIsAdvancedContextSearch(true)}
                sx={{ mt: 1 }}
              >
                Advanced Search
              </Button>
            )}
          </Collapse>
        </Grid>
      </Grid>
      <Collapse in={isAdvancedContextSearch}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <AdvancedSearch
            searchTerms={context}
            options={contextSubesctions}
            filterTerms={context}
            story={story}
            isFilteringData={isLoadingContext}
            whatTerms={searchTerms.filter(s => s.table === "what")} // required for updating context
            disableFiltering
            isContext
          />
          <Box textAlign="center">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedContextSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
        </Paper>
      </Collapse>
      <Typography variant="subtitle2" gutterBottom>
        Market
      </Typography>
      <Grid container spacing={3} alignItems="center" sx={{ mb: 2 }}>
        <Grid item md={6}>
          <Select
            styles={customStyles}
            onChange={(e, i) => {
              if (i.action === "clear") {
                // remove from search terms
                setMarket("no-value");
                onSelect("no-value", market, story);
              } else {
                setMarket(e);
                onSelect(e.value, market, story);
              }
            }}
            options={marketOptions}
            placeholder="Type or scroll to select a market"
            value={market}
            backspaceRemovesValue={false}
            filterOption={customFilter}
            isClearable
          />
        </Grid>
      </Grid>
      <FormHelperText error>{error}</FormHelperText>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        sx={{ my: 3 }}
        onClick={() => {
          const oldPreference = _.omit(preference, ["id"]);
          const newPeference = { product, context, market };
          if (
            !_.isEmpty(preference) &&
            !_.isEqual(oldPreference, newPeference)
          ) {
            edit(newPeference, userId, client, channel, preference.id);
          } else {
            addToPreferences(newPeference, userId, client, channel);
          }
          // if either product and context or market or both
          // TODO - a and b or a, b and c
          if (
            (product !== undefined && context.length > 0) ||
            market !== "no-value"
          ) {
            setTimeout(() => {
              if (errorRef.current.length === 0) {
                onClose();
              }
            }, 3000);
          }
        }}
      >
        Create Preference
      </Button>
    </div>
  );
};

AddPreference.propTypes = {
  isStaticData: PropTypes.bool,
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  searchEngine: PropTypes.shape(),
  client: PropTypes.string,
  getProductContext: PropTypes.func,
  isLoadingContext: PropTypes.bool,
  contextSubesctions: PropTypes.arrayOf(PropTypes.shape()),
  contextSearchEngine: PropTypes.shape(),
  onChange: PropTypes.func,
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  onSelect: PropTypes.func,
  addToPreferences: PropTypes.func,
  onClose: PropTypes.func,
  preference: PropTypes.shape(),
  error: PropTypes.string,
  userId: PropTypes.string,
  story: PropTypes.string,
  getData: PropTypes.func,
  channel: PropTypes.string,
  edit: PropTypes.func
};

AddPreference.defaultProps = {
  isStaticData: false,
  subsections: [],
  searchEngine: {},
  client: "",
  getProductContext: () => {},
  isLoadingContext: false,
  contextSubesctions: [],
  contextSearchEngine: {},
  onChange: () => {},
  searchTerms: [],
  onSelect: () => {},
  addToPreferences: () => {},
  onClose: () => {},
  preference: {},
  error: "",
  userId: "",
  story: "",
  getData: () => {},
  channel: "",
  edit: () => {}
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      isLoadingContext = false,
      context = { story: [] },
      contextSearchEngine = { story: {} },
      searchTerms = { story: [] }
    },
    data: {
      subsections = { dataSet: [] },
      isStaticData = false,
      searchEngine = { dataSet: {} }
    }
  } = state;
  return {
    subsections: subsections[dataSet],
    isStaticData,
    searchEngine: searchEngine[dataSet],
    isLoadingContext,
    contextSubesctions: context[story],
    contextSearchEngine: contextSearchEngine[story],
    searchTerms: searchTerms[story]
  };
};

const mapDispatchToProps = dispatch => ({
  getProductContext: (terms, context, story, client, isInitial) => {
    dispatch(getContext(terms, context, "product", story, client, isInitial));
  },
  onChange: (selectedTerm, oldValue, story) => {
    if (oldValue) {
      dispatch(deleteTerm(oldValue, story));
    }
    if (selectedTerm) {
      dispatch(enterSelectedTerm({ ...selectedTerm, story }, story));
    }
  },
  onSelect: (selectedValue, oldValue, story) => {
    const value = selectedValue.split("--");
    if (oldValue !== "no-value") {
      const old = oldValue.value.split("--");
      dispatch(
        deleteTerm(
          {
            name: old[1],
            subsection: old[0],
            table: "where",
            story
          },
          story
        )
      );
    }
    if (selectedValue !== "no-value") {
      dispatch(
        enterSelectedTerm(
          {
            name: value[1],
            subsection: value[0],
            table: "where",
            story
          },
          story
        )
      );
    }
  },
  addToPreferences: (preference, userId, client, channel) => {
    dispatch(addPreference(preference, userId, client, channel));
  },
  edit: (newPeference, userId, client, channel, deleteId) =>
    dispatch(editPreference(newPeference, userId, client, channel, deleteId)),
  getData: client => {
    dispatch(fetchStaticData(client));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPreference);

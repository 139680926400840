import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { CardHeader, Typography, Link } from "@mui/material";
import { DownArrowIcon, UpArrowIcon, NeutralIcon } from "../Icons";

const styles = {
  root: { bgcolor: "#f7f3f2", borderRadius: 1 },
  action: { alignSelf: "center" },
  avatar: { alignSelf: "flex-start" }
};

const Insights = props => {
  const { data, value } = props;
  return (
    <CardHeader
      sx={{
        ...styles.root,
        "& .MuiCardHeader-action": styles.action,
        "& .MuiCardHeader-avatar": styles.avatar
      }}
      avatar={
        (data.extended_analysis_title_arrow_positive && (
          <UpArrowIcon color="positive" fontSize="large" />
        )) ||
        (data.extended_analysis_title_arrow_negative && (
          <DownArrowIcon color="negative" fontSize="large" />
        )) ||
        (data.extended_analysis_title_arrow_neutral && (
          <NeutralIcon color="neutral" fontSize="large" />
        ))
      }
      titleTypographyProps={{
        variant: "subtitle2",
        gutterBottom: true,
        color: `${
          (data.extended_analysis_title_arrow_positive && "positive.main") ||
          (data.extended_analysis_title_arrow_negative && "negative.main") ||
          (data.extended_analysis_title_arrow_neutral && "neutral.main")
        }`
      }}
      title={data.extended_analysis_title}
      subheader={
        <Fragment>
          <Typography variant="subtitle3" fontWeight="normal" gutterBottom>
            {data.top_analysis}
          </Typography>
          <Typography variant="subtitle3" fontWeight="normal">
            {data.child_analysis_text}
          </Typography>
        </Fragment>
      }
      action={
        <Link
          component={RouterLink}
          to={{
            pathname: `/lookout:${value}`,
            state: { id: data.k.toString() }
          }}
          underline="hover"
          color="primary.dark"
          sx={{ p: 1, m: 1, fontSize: 12 }}
        >
          View Full Insight
        </Link>
      }
    />
  );
};

Insights.propTypes = {
  data: PropTypes.shape(),
  value: PropTypes.number
};

Insights.defaultProps = {
  data: {},
  value: 0
};

export default Insights;

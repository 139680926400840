import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import InsightsCard from "./InsightsCard";
import { getLookoutPeriod, getLookoutDate } from "../../utils";

const Insights = props => {
  const { dataDate, data, client, isTotalClient, dataSet } = props;
  const location = useLocation();
  const isMonth = dataSet === "cga";
  useEffect(() => {
    if (
      location.state &&
      location.state.id &&
      document.getElementById(location.state.id)
    ) {
      document.getElementById(location.state.id).scrollIntoView();
    }
  }, []);
  return (
    <Fragment>
      <Typography color="primary" fontWeight="bold">
        {getLookoutPeriod(dataDate, isMonth)}(
        {getLookoutDate(dataDate, isMonth)})
      </Typography>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        {data.map((i, k) => (
          <Grid item md={4} key={k.toString()} id={k.toString()}>
            <InsightsCard
              data={{ ...i, k }}
              dataDate={dataDate}
              client={client}
              isTotalClient={isTotalClient}
              dataSet={dataSet}
            />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

Insights.propTypes = {
  dataDate: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  isTotalClient: PropTypes.bool,
  dataSet: PropTypes.string
};

Insights.defaultProps = {
  dataDate: "",
  data: [],
  client: "",
  isTotalClient: false,
  dataSet: ""
};

export default Insights;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Typography,
  ListItem,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@mui/material";
import { enterSelectedTerm, deleteTerm } from "../../actions";

const Measure = props => {
  const { measureError, onSelect, selectedMeasure, story } = props;
  const [metric, setMetric] = useState(null);
  useEffect(() => setMetric(selectedMeasure ? selectedMeasure.name : null), []);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            Measure type
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
          What kind of metric do you want to be used?{" "}
          <span className="required">required</span>
        </Typography>
        <RadioGroup sx={{ px: 1 }}>
          <ListItem disablePadding>
            <FormControlLabel
              sx={{ width: 150 }}
              control={
                <Radio
                  value="Unit Sales"
                  checked={metric === "Unit Sales"}
                  onChange={e => {
                    setMetric(e.target.value);
                    onSelect(e.target.value, selectedMeasure, story);
                  }}
                />
              }
              label={<Typography variant="body2">Unit Sales</Typography>}
            />
            {!metric && <FormHelperText error>{measureError}</FormHelperText>}
          </ListItem>
          <ListItem disablePadding>
            <FormControlLabel
              sx={{ width: 150 }}
              control={
                <Radio
                  value="Value Sales"
                  checked={metric === "Value Sales"}
                  onChange={e => {
                    setMetric(e.target.value);
                    onSelect(e.target.value, selectedMeasure, story);
                  }}
                />
              }
              label={<Typography variant="body2">Value Sales</Typography>}
            />
            {!metric && <FormHelperText error>{measureError}</FormHelperText>}
          </ListItem>
        </RadioGroup>
      </FormControl>
    </ListItem>
  );
};

Measure.propTypes = {
  onSelect: PropTypes.func,
  measureError: PropTypes.string,
  selectedMeasure: PropTypes.objectOf(PropTypes.string),
  story: PropTypes.string
};

Measure.defaultProps = {
  onSelect: () => {},
  measureError: "",
  selectedMeasure: undefined,
  story: ""
};

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story) => {
    if (oldValue !== undefined) {
      dispatch(deleteTerm(oldValue, story));
    }
    dispatch(
      enterSelectedTerm(
        {
          name: selectedValue,
          subsection: "metric_name",
          story
        },
        story
      )
    );
  }
});

export default connect(null, mapDispatchToProps)(Measure);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  Collapse,
  Button,
  Paper,
  Link
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { InfoIcon } from "../Icons";

const styles = {
  card: {
    bgcolor: "#009cad0D",
    p: 2,
    border: 1,
    borderColor: "primary.dark",
    borderRadius: 1
  }
};

const ExamplesBox = props => {
  const { channel } = props;
  const [isHidden, setIsHidden] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Paper elevation={0} sx={{ mt: 2, mb: 4 }}>
      <Collapse in={isHidden}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => setIsHidden(false)}
        >
          Show examples
        </Button>
      </Collapse>
      <Collapse in={!isHidden}>
        <Card elevation={0} sx={styles.card}>
          <CardHeader
            titleTypographyProps={{
              variant: "subtitle2",
              color: "primary.dark"
            }}
            avatar={<InfoIcon color="primaryDark" />}
            action={
              <IconButton
                color="primaryDark"
                onClick={() => {
                  setIsHidden(true);
                  setIsExpanded(false);
                }}
                size="large"
              >
                <CloseRounded fontSize="small" />
              </IconButton>
            }
            title="Example 1"
            sx={{ p: 2, pb: 0 }}
          />
          <CardContent sx={{ pt: 0, ml: 5 }}>
            <Typography variant="body2" gutterBottom fontSize={12}>
              I would like to see the insights for CCEP{" "}
              {channel === "ooh" ? "Mixers" : "Flavours"} in{" "}
              {channel === "ooh" ? "On Premise" : "Total Tesco"}. What should I
              enter?
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              fontSize={12}
              sx={{ mb: 2 }}
            >
              1) Enter CCEP into the Target Product box.
              <br />
              2) Enter {channel === "ooh" ? "Mixers" : "Flavours"} into the
              Product Scope box.
              <br />
              3) Enter {channel === "ooh" ? "On Premise" : "Total Tesco"} into
              the Market box.
            </Typography>
            <Collapse in={isExpanded}>
              {/* example 2 */}
              <Typography variant="subtitle2" gutterBottom color="primary.dark">
                Example 2
              </Typography>
              <Typography variant="body2" gutterBottom fontSize={12}>
                I want to understand how Coca-Cola Zero{" "}
                {channel === "ooh" ? "is" : "Multipack cans are"} performing vs
                the competition (other Low Calorie Colas) in{" "}
                {channel === "ooh" ? "London" : "Multiple Grocers"}. How do I do
                this?
              </Typography>
              <Typography variant="body2" gutterBottom fontSize={12}>
                1) Enter Coca-Cola Zero (major brand) as your Target Product.
                <br />
                2) Enter{" "}
                {channel === "ooh" ? "" : "FC Can Multi (pack type), then"} Low
                Calorie (sugar content), then Colas (sector) into the Product
                Scope box.
                <br />
                3) Enter {channel === "ooh"
                  ? "London"
                  : "Multiple Grocers"}{" "}
                into the Market box.
              </Typography>
              {/* example 3 */}
              <Typography
                variant="subtitle2"
                gutterBottom
                color="primary.dark"
                sx={{ mt: 2 }}
              >
                Example 3
              </Typography>
              <Typography variant="body2" gutterBottom fontSize={12}>
                I want to receive insights about how Immediate Consumption
                Monster is performing vs the competition (other IC Energy
                brands) in{" "}
                {channel === "ooh"
                  ? "Education & Workplace"
                  : "Sainsbury's Local"}
                . How do I do this?
              </Typography>
              <Typography variant="body2" gutterBottom fontSize={12}>
                1) Enter Monster (major brand) as your Target Product.
                <br />
                2) Enter Total Energy (category), then Immediate Consumption
                (consumption type) into the Product Scope box.
                <br />
                3) Enter{" "}
                {channel === "ooh"
                  ? "Education & Workplace"
                  : "Sainsbury's Local"}{" "}
                into the Market box.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ my: 2 }}
                fontSize={12}
              >
                Please{" "}
                <Link component={RouterLink} to="/support" color="primary.dark">
                  contact us
                </Link>{" "}
                to let us know of any other examples you would like to be added!
              </Typography>
            </Collapse>
            <Typography
              variant="body2"
              component={Link}
              gutterBottom
              underline="always"
              color="primary.dark"
              onClick={() => setIsExpanded(!isExpanded)}
              fontSize={12}
              sx={{ cursor: "pointer" }}
            >
              Show {isExpanded ? "fewer" : "more"} examples
            </Typography>
          </CardContent>
        </Card>
      </Collapse>
    </Paper>
  );
};

ExamplesBox.propTypes = {
  channel: PropTypes.string
};

ExamplesBox.defaultProps = {
  channel: ""
};

export default ExamplesBox;

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { getUpdateDate } from "../../utils";

const styles = {
  update: {
    bgcolor: "#f7f3f2",
    px: 2,
    py: 1,
    borderRadius: 1.5,
    maxWidth: 400,
    textAlign: "right"
  }
};

const UpdateDate = props => {
  const { dataDate } = props;
  return (
    <Grid container justifyContent="center" sx={styles.update}>
      <Grid
        item
        xs={12}
        container
        sx={{ pb: 0.5, borderBottom: 2, borderColor: "white" }}
      >
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Next Nielsen data update expected
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            {getUpdateDate(dataDate.nielsen)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container sx={{ pt: 0.5 }}>
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Next CGA data update expected
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            {getUpdateDate(dataDate.cga, true)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

UpdateDate.propTypes = {
  dataDate: PropTypes.shape()
};

UpdateDate.defaultProps = {
  dataDate: {}
};

export default UpdateDate;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  FormControl,
  Switch,
  Button,
  FormHelperText,
  ListItem,
  Box
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { getSubscription, subscribe, unsubscribe } from "../../actions";

const styles = {
  action: { alignSelf: "center" }
};

const SubscriptionSettings = props => {
  const {
    error,
    message,
    isLoading,
    onClose,
    userId,
    getSubscribed,
    subscriptions,
    subscribeTopic,
    unsubscribeTopic
  } = props;
  const [settings, setSettings] = useState(false);
  const reset = () => {
    setSettings(subscriptions);
  };
  useEffect(() => {
    if (_.isEmpty(subscriptions)) {
      getSubscribed(userId);
    } else {
      setSettings(subscriptions);
    }
  }, []);
  useEffect(() => {
    if (!_.isEmpty(subscriptions)) {
      setSettings(subscriptions);
    }
  }, [subscriptions]);

  return (
    <Card sx={{ m: 2, p: 2 }}>
      <CardHeader
        titleTypographyProps={{
          variant: "h4"
        }}
        avatar={
          <IconButton
            color="secondary"
            onClick={() => {
              onClose();
              reset();
            }}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        title="Mail Settings"
      />
      <Grid item md={12} sx={{ m: 2 }}>
        <form>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <Card className="card">
                <CardHeader
                  sx={{ "& .MuiCardHeader-action": styles.action }}
                  titleTypographyProps={{
                    variant: "subtitle1",
                    color: "primary.dark",
                    gutterBottom: true
                  }}
                  title="Lookout (In-Home)"
                  subheaderTypographyProps={{
                    variant: "body2"
                  }}
                  subheader="Subscribe to IH Lookout emails if you are interested in In-Home updates. Please note that Impulse is included in the At Home updates as we take this from the Nielsen data."
                  action={
                    <Switch
                      checked={
                        settings["lookout-ih"] ? settings["lookout-ih"] : false
                      }
                      onChange={e => {
                        setSettings({
                          ...settings,
                          "lookout-ih": e.target.checked
                        });
                      }}
                      disabled={isLoading || !settings}
                    />
                  }
                />
              </Card>
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <Card className="card">
                <CardHeader
                  sx={{ "& .MuiCardHeader-action": styles.action }}
                  titleTypographyProps={{
                    variant: "subtitle1",
                    color: "primary.dark",
                    gutterBottom: true
                  }}
                  title="Lookout (Out-of-Home)"
                  subheaderTypographyProps={{
                    variant: "body2"
                  }}
                  subheader="Subscribe to OOH Lookout emails if you are interested in Out-of-Home updates. This covers all insights covered by CGA data."
                  action={
                    <Switch
                      checked={
                        settings["lookout-ooh"]
                          ? settings["lookout-ooh"]
                          : false
                      }
                      onChange={e => {
                        setSettings({
                          ...settings,
                          "lookout-ooh": e.target.checked
                        });
                      }}
                      disabled={isLoading || !settings}
                    />
                  }
                />
              </Card>
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <Grid item md={12} container>
                <Grid item md={6}>
                  <Box textAlign="left">
                    <Button
                      variant="contained"
                      color="primary"
                      type="sumbit"
                      disableElevation
                      onClick={e => {
                        e.preventDefault();
                        // find out which topics have changed
                        const changes = _.reduce(
                          settings,
                          (result, value, key) =>
                            _.isEqual(value, subscriptions[key])
                              ? result
                              : result.concat(key),
                          []
                        );
                        // subscribe or unsubscribe from changed topics as required
                        changes.map(i =>
                          settings[i]
                            ? subscribeTopic(userId, i)
                            : unsubscribeTopic(userId, i)
                        );
                        // // if success
                        // if (message.length > 0) {
                        //   reset();
                        // }
                      }}
                    >
                      Save changes
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box textAlign="right">
                    <Button
                      color="primary"
                      type="cancel"
                      disableElevation
                      onClick={e => {
                        e.preventDefault();
                        onClose();
                        reset();
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          {error.length > 0 ? (
            <FormHelperText error sx={{ textAlign: "center" }}>
              {error}
            </FormHelperText>
          ) : null}
          {message.length > 0 ? (
            <FormHelperText sx={{ textAlign: "center" }}>
              {message}
            </FormHelperText>
          ) : null}
        </form>
      </Grid>
    </Card>
  );
};

SubscriptionSettings.propTypes = {
  getSubscribed: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.string,
  subscriptions: PropTypes.shape(),
  subscribeTopic: PropTypes.func,
  unsubscribeTopic: PropTypes.func
};

SubscriptionSettings.defaultProps = {
  getSubscribed: () => {},
  error: "",
  message: "",
  isLoading: false,
  onClose: () => {},
  userId: "",
  subscriptions: {},
  subscribeTopic: () => {},
  unsubscribeTopic: () => {}
};

const mapDispatchToProps = dispatch => ({
  getSubscribed: userId => {
    dispatch(getSubscription(userId));
  },
  subscribeTopic: (userId, topic) => {
    dispatch(subscribe(userId, topic));
  },
  unsubscribeTopic: (userId, topic) => {
    dispatch(unsubscribe(userId, topic));
  }
});

export default connect(null, mapDispatchToProps)(SubscriptionSettings);

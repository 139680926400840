import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  Typography,
  Box,
  Button,
  Chip
} from "@mui/material";
import { EditIcon, ChannelIcon } from "../Icons";
import RankList from "./RankList";
import { Loader } from "../SharedComponents";
import { getRankings } from "../../actions";

const styles = { heading: { py: 2, bgcolor: "#e5e0df" } };

const Rankings = props => {
  const {
    channel,
    environments,
    handleBack,
    getOptions,
    options,
    rankList,
    setRankList
  } = props;
  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");

  useEffect(() => {
    getOptions(channel, environments);
  }, []);

  useEffect(() => {
    if (options.length > 0) {
      setRankList(options);
    }
  }, [options]);

  return (
    <Fragment>
      <Card sx={{ mb: 4 }}>
        <CardHeader
          title={
            <Grid container alignItems="center" sx={{ pb: 1 }}>
              <ChannelIcon color="primary" sx={{ fontSize: 14, mr: 0.5 }} />
              <Typography variant="subtitle2" component="span" color="primary">
                {channel === "ih" ? "In Home" : "Out of Home"}
              </Typography>
            </Grid>
          }
          subheader={
            <Box sx={{ mx: -0.5 }}>
              {environments.map(i => (
                <Chip key={i} sx={{ m: 0.5 }} label={i} />
              ))}
            </Box>
          }
          action={
            <Button
              size="small"
              color="yellow"
              sx={{ mx: 0.5 }}
              onClick={() => handleBack()}
            >
              <EditIcon sx={{ fontSize: 16, m: 0.5 }} />
              Edit
            </Button>
          }
        />
      </Card>
      {options.length > 0 ? (
        <Grid container spacing={6}>
          <Grid item md={4}>
            <Card elevation={0} sx={styles.heading}>
              <Typography variant="subtitle1" align="center">
                Category / Sector
              </Typography>
            </Card>
            <RankList
              data={rankList.filter(i => i.level === 1)}
              setNext={val => {
                setLevel1(val);
                // reset brand
                setLevel2("");
              }}
              value={level1}
              rank={rankList}
              setRank={setRankList}
            />
          </Grid>
          <Grid item md={4}>
            <Card elevation={0} sx={styles.heading}>
              <Typography variant="subtitle1" align="center">
                Brand
              </Typography>
            </Card>
            {level1.length > 0 && (
              <RankList
                data={rankList.filter(
                  i => i.level === 2 && i.parent === level1
                )}
                setNext={val => {
                  setLevel2(val);
                }}
                value={level2}
                rank={rankList}
                setRank={setRankList}
              />
            )}
          </Grid>
          <Grid item md={4}>
            <Card elevation={0} sx={styles.heading}>
              <Typography variant="subtitle1" align="center">
                Sub Brand / Flavour
              </Typography>
            </Card>
            {level2.length > 0 && (
              <RankList
                data={rankList.filter(
                  i =>
                    i.level === 3 &&
                    i.parent === level2 &&
                    i.grandparent === level1
                )}
                rank={rankList}
                setRank={setRankList}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};

Rankings.propTypes = {
  channel: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  handleBack: PropTypes.func,
  getOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  rankList: PropTypes.arrayOf(PropTypes.shape()),
  setRankList: PropTypes.func
};

Rankings.defaultProps = {
  channel: "",
  environments: [],
  handleBack: () => {},
  getOptions: () => {},
  options: [],
  rankList: [],
  setRankList: () => {}
};

const mapDispatchToProps = dispatch => ({
  getOptions: (channel, environments) =>
    dispatch(getRankings(channel, environments))
});

export default connect(null, mapDispatchToProps)(Rankings);

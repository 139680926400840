const defaultState = {
  search: {
    searchTerms: {},
    suggestions: [],
    autoCompleteTerm: { name: "" },
    allTerms: {},
    filteredSubsections: {},
    isFilteredData: {},
    filterTerms: {},
    context: {},
    suggestedContext: {}
  },
  data: {
    subsections: {},
    isStaticData: false,
    date: {},
    placeholder: {}
  },
  user: {
    isUser: false,
    user: {}
  },
  report: {
    myRecentReports: [],
    myFavouriteReports: []
  },
  presentation: {
    activeSlideIndex: 0
  },
  lookout: {
    preferences: {},
    lookout: {}
  }
};

export default defaultState;

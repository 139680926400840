import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Link,
  Button
} from "@mui/material";
import { DownArrowIcon, UpArrowIcon, NeutralIcon } from "../Icons";
import { getLookoutDate } from "../../utils";

const styles = {
  arrow: { mr: -1 },
  actionButton: {
    borderRadius: 0,
    textTransform: "none",
    p: 1.5,
    fontWeight: "normal"
  }
};

const InsightsCard = props => {
  const { data, dataDate, client, isTotalClient, dataSet } = props;
  const isMonth = dataSet === "cga";
  return (
    <Card raised>
      <CardHeader
        subheader={`${isMonth ? "L1M" : "L4W"} (${getLookoutDate(
          dataDate,
          isMonth
        )})`}
        subheaderTypographyProps={{
          variant: "subtitle2",
          gutterBottom: true,
          fontSize: 12
        }}
        sx={{ pb: 0 }}
      />
      <CardHeader
        avatar={
          (data.extended_analysis_title_arrow_positive && (
            <UpArrowIcon color="positive" sx={styles.arrow} />
          )) ||
          (data.extended_analysis_title_arrow_negative && (
            <DownArrowIcon color="negative" sx={styles.arrow} />
          )) ||
          (data.extended_analysis_title_arrow_neutral && (
            <NeutralIcon color="neutral" sx={styles.arrow} />
          ))
        }
        title={
          <a href={data.report_link} target="_blank" rel="noreferrer">
            {data.extended_analysis_title}
          </a>
        }
        titleTypographyProps={{
          variant: "subtitle1",
          color: `${
            (data.extended_analysis_title_arrow_positive && "positive.main") ||
            (data.extended_analysis_title_arrow_negative && "negative.main") ||
            (data.extended_analysis_title_arrow_neutral && "neutral.main")
          }`
        }}
        sx={{ py: 0 }}
      />
      <CardContent>
        <Typography variant="h7" gutterBottom>
          What happened?
        </Typography>
        <Typography variant="subtitle3" gutterBottom>
          {data.top_analysis}
        </Typography>
        <Typography variant="subtitle3" gutterBottom>
          {data.child_analysis_text}
          {data.child_analysis_link && (
            <Fragment>
              {" "}
              For more information{" "}
              <Link
                href={data.child_analysis_link}
                target="_blank"
                underline="hover"
              >
                click here
              </Link>
              .
            </Fragment>
          )}
        </Typography>
        <Typography variant="subtitle3" gutterBottom>
          {data.kantar_analysis}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h7" gutterBottom>
          Competitors
        </Typography>
        <Typography variant="subtitle3" gutterBottom>
          {data.competitor_analysis_text}
        </Typography>
        <Typography variant="subtitle3" gutterBottom>
          <Link
            href={data.competitor_analysis_link_performance}
            target="_blank"
            underline="hover"
          >
            Performance Report (Competitor)
          </Link>
        </Typography>
        {data.competitor_analysis_link_bb && (
          <Typography variant="subtitle3" gutterBottom>
            <Link
              href={data.competitor_analysis_link_bb}
              target="_blank"
              underline="hover"
            >
              Brand Battles (
              {isTotalClient ? client.toUpperCase() : data.product_key} vs
              Competitor)
            </Link>
          </Typography>
        )}
      </CardContent>
      <CardActions disableSpacing sx={{ p: 0 }}>
        <Button
          variant="contained"
          fullWidth
          disableElevation
          component={Link}
          href={data.report_link}
          target="_blank"
          size="large"
          sx={styles.actionButton}
        >
          View Performance Report
        </Button>
      </CardActions>
    </Card>
  );
};

InsightsCard.propTypes = {
  data: PropTypes.shape(),
  dataDate: PropTypes.string,
  client: PropTypes.string,
  isTotalClient: PropTypes.bool,
  dataSet: PropTypes.string
};

InsightsCard.defaultProps = {
  data: {},
  dataDate: "",
  client: "",
  isTotalClient: false,
  dataSet: ""
};

export default InsightsCard;

import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Tooltip,
  FormControlLabel,
  Switch
} from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";

const styles = {
  icon: val => ({
    color: val > 0 ? "primary.main" : "yellow.main",
    bgcolor: "#fff",
    borderRadius: "50%",
    mr: 1,
    display: "inline-block",
    width: 18,
    height: 18,
    textAlign: "center"
  })
};

const highlightCell = (isHighlight, value, rank) => (
  <Typography variant="body2" fontSize={12}>
    {isHighlight && rank && (
      <Typography variant="subtitle3" component="span" sx={styles.icon(rank)}>
        {rank}
      </Typography>
    )}
    {value}
  </Typography>
);

const renderDescriptionHeader = params => {
  const { colDef } = params;
  const { headerName, description } = colDef;
  return (
    <Tooltip title={description}>
      <Typography
        variant="subtitle1"
        fontSize={12}
        sx={{ whiteSpace: "normal", lineHeight: 1.2 }}
      >
        {headerName}
      </Typography>
    </Tooltip>
  );
};

export const getColumns = isHighlight => [
  {
    field: "rank",
    headerName: "Rank",
    type: "number",
    sortable: false,
    width: 65,
    headerAlign: "left"
  },
  {
    field: "product_name",
    headerName: "Product name",
    sortable: false,
    width: 250,
    renderCell: params => {
      const { value } = params;
      return (
        <Tooltip title={value || ""}>
          <Typography variant="body2" fontSize={12} noWrap>
            {value}
          </Typography>
        </Tooltip>
      );
    }
  },
  {
    field: "score",
    headerName: "Total score",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "We calculate a score based on all of the metrics you can see here."
  },
  { field: "category", headerName: "Category", sortable: false },
  { field: "sector", headerName: "Sector", sortable: false },
  { field: "brand", headerName: "Brand", sortable: false },
  { field: "pack_type", headerName: "Pack type", sortable: false },
  { field: "offer_type", headerName: "PMP?", sortable: false },
  {
    field: "consumption_type",
    headerName: "Consumption type",
    sortable: false
  },
  {
    field: "units_per_package",
    headerName: "Units per package",
    sortable: false
  },
  {
    field: "container_material",
    headerName: "Container material",
    sortable: false
  },
  { field: "pack_size_litres", headerName: "Volume per unit", sortable: false },
  {
    field: "value_sales",
    headerName: "Value sales (£)",
    sortable: false,
    disableExport: true,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.value_sales_rank);
    },
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "The average weekly value sales of the product over the past 12 weeks."
  },
  {
    field: "value_sales_export",
    headerName: "Value sales (£)",
    sortable: false
  },
  {
    field: "sku_growth",
    headerName: "SKU growth",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.sku_growth_rank);
    },
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "The % change of value sales of the product in the last 12 weeks compared to the same 12 weeks last year."
  },
  {
    field: "numeric_distribution",
    headerName: "Numeric distribution",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.numeric_distribution_rank);
    },
    renderHeader: params => renderDescriptionHeader(params),
    description: "The percentage of stores that the product is sold in."
  },
  {
    field: "number_shop",
    headerName: "Number of stores",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "The number of stores the that product is sold in within the environment."
  },
  {
    field: "ros",
    headerName: "Unit rate of sale",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.ros_rank);
    },
    renderHeader: params => renderDescriptionHeader(params),
    description: "Units sold per store per week."
  },
  {
    field: "financial_score",
    headerName: "Profitibility decile",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.financial_score_rank);
    },
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "The relative profitability of a sku compared to other CCEP skus, based on profitability per unit case. 10 is most profitable, 0 is least profitable."
  },
  {
    field: "retained_value",
    headerName: "Value retained within CCEP",
    sortable: false,
    renderCell: params => {
      const { value, row } = params;
      return highlightCell(isHighlight, value, row.retained_value_rank);
    },
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "An Interrodata model (Range Finder) that calculates the substitutability and incrementality of CCEP products. Within the ranging tool, it is predominantly used to identify the percentage of sales that would remain within CCEP if a product was removed from sale."
  },
  {
    field: "sector_projection",
    headerName: "Sector projection",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "The change in value sales of the category / sector that a product sits within between last 52 weeks compared to previous 52 weeks."
  },
  {
    field: "ic_fc_projection",
    headerName: "IC/FC projection",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "The change in value sales of the consumption type (IC vs FC) that a product is between last 52 weeks compared to previous 52 weeks."
  },
  {
    field: "sugar_projection",
    headerName: "Sugar content projection",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "The change in value sales of the sugar content (Low Calorie vs Regular) that a product is between last 52 weeks compared to previous 52 weeks."
  },
  {
    field: "category_ranking",
    headerName: "Category importance",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "User-input relative prioritisation of categories and sectors. Increased prioritisation of these will boost all products which have that respective attribute."
  },
  {
    field: "brand_ranking",
    headerName: "Brand importance",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "User-input relative prioritisation of brands. Increased prioritisation of these will boost all products that sit within the given brand."
  },
  {
    field: "sub_brand_ranking",
    headerName: "Sub brand importance",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "User-input relative prioritisation of sub-brands/flavour. Increased prioritisation of these will boost all products that sit within the given sub-brand or flavour."
  },
  {
    field: "importance",
    headerName: "Strategic importance",
    sortable: false,
    renderHeader: params => renderDescriptionHeader(params),
    description:
      "A user-input value between 0-3 giving a boost to individual skus that have strategic importance for CCEP."
  }
];

export const Toolbar = props => {
  const {
    search,
    setSearch,
    rankType,
    environments,
    consumptionType,
    isHighlight,
    setHighlight,
    dataDate
  } = props;
  return (
    <GridToolbarContainer
      sx={{
        bgcolor: "#e5e0df",
        px: 3,
        height: 80,
        borderBottom: 1,
        borderColor: "divider"
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: 1 }}
      >
        <Typography variant="subtitle1">
          Products&nbsp;
          <Typography variant="caption">
            (Latest 12 weeks: w/e {dataDate})
          </Typography>
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={4}
          sx={{ width: 0.75 }}
        >
          <TextField
            variant="standard"
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            sx={{ width: 0.25 }}
            size="small"
          />
          <GridToolbarExport
            csvOptions={{
              allColumns: true,
              fileName: `${consumptionType.toUpperCase()} ${rankType} ranking in ${environments.join(
                " "
              )} - ${
                search.length > 0 ? `filtered by ${search} - ` : ""
              }powered by Interrodata (Latest 12 weeks: w/e ${dataDate})`
            }}
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={isHighlight}
                onChange={e => setHighlight(e.target.checked)}
              />
            }
            label={
              <Typography variant="subtitle3">
                Highlight top / bottom products
              </Typography>
            }
          />
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
};

Toolbar.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  rankType: PropTypes.string,
  consumptionType: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.string),
  isHighlight: PropTypes.bool,
  setHighlight: PropTypes.func,
  dataDate: PropTypes.string
};

Toolbar.defaultProps = {
  search: "",
  setSearch: () => {},
  rankType: "",
  consumptionType: "",
  environments: [],
  isHighlight: false,
  setHighlight: () => {},
  dataDate: ""
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";
import { getEnvironments } from "../../actions";
import Loader from "./Loader";

const styles = { radio: { "& .MuiFormControlLabel-label": { fontSize: 14 } } };

const ChannelEnvironment = props => {
  const {
    channel,
    setChannel,
    environments,
    setEnvironments,
    listEnvironments,
    retailers,
    additionalOption
  } = props;
  useEffect(() => {
    if (retailers.length === 0) {
      listEnvironments();
    }
  }, []);

  const changeChannel = (e, val) => {
    setChannel(val);
    setEnvironments([]);
  };

  const changeEnvironment = e => {
    setEnvironments([e.target.value]);
  };

  return (
    <Stack spacing={5}>
      <Grid container alignItems="center">
        <Grid item md={6}>
          <Stack>
            <Typography variant="subtitle2" gutterBottom>
              Channel
            </Typography>
            <ToggleButtonGroup
              value={channel}
              exclusive
              color="primary"
              onChange={changeChannel}
            >
              <ToggleButton value="ih" sx={{ width: 200 }}>
                In Home
              </ToggleButton>
              <ToggleButton value="ooh" sx={{ width: 200 }}>
                Out of Home
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid item md={6} sx={{ px: 2 }}>
          {additionalOption}
        </Grid>
      </Grid>
      <Stack>
        <Typography variant="subtitle2" gutterBottom>
          Select environments
        </Typography>
        <Grid container>
          <RadioGroup
            row
            value={environments[0] || ""}
            onChange={changeEnvironment}
          >
            {retailers.length > 0 ? (
              retailers.map(i => (
                <Grid item md={4} key={i.name}>
                  <FormControlLabel
                    control={<Radio size="small" value={i.name} />}
                    label={i.name}
                    disabled={i.channel !== channel}
                    sx={styles.radio}
                  />
                </Grid>
              ))
            ) : (
              <Loader />
            )}
          </RadioGroup>
        </Grid>
      </Stack>
    </Stack>
  );
};

ChannelEnvironment.propTypes = {
  channel: PropTypes.string,
  setChannel: PropTypes.func,
  environments: PropTypes.arrayOf(PropTypes.string),
  setEnvironments: PropTypes.func,
  listEnvironments: PropTypes.func,
  retailers: PropTypes.arrayOf(PropTypes.shape()),
  additionalOption: PropTypes.element
};

ChannelEnvironment.defaultProps = {
  channel: "",
  setChannel: () => {},
  environments: [],
  setEnvironments: () => {},
  listEnvironments: () => {},
  retailers: [],
  additionalOption: null
};

const mapStateToProps = state => ({
  retailers: state.sterling.retailers
});

const mapDispatchToProps = dispatch => ({
  listEnvironments: () => dispatch(getEnvironments())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelEnvironment);

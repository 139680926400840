import React from "react";
import PropTypes from "prop-types";

const ReportImage = props => {
  const { data, layout } = props;
  return (
    <div className="Image ReportImage">
      <img
        src={data
          .replace("interrodata/insightautomation", "")
          .replace("//", "/")}
        alt={data}
        style={{
          position: "absolute",
          top: `${layout.top}%`,
          left: `${layout.left}%`,
          height: `${layout.height}%`,
          width: `${layout.width}%`
        }}
      />
    </div>
  );
};

ReportImage.propTypes = {
  data: PropTypes.string,
  layout: PropTypes.shape()
};

ReportImage.defaultProps = {
  data: "",
  layout: {}
};

export default ReportImage;

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  Paper,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Box,
  Tabs,
  Tab
} from "@mui/material";
import { connect } from "react-redux";
import { getHours } from "date-fns";
import {
  PerformanceIcon,
  BrandBattlesIcon,
  RangeReviewIcon,
  IHIcon,
  OOHIcon
} from "../Icons";
import { getPreferences, getLookout } from "../../actions";
import { getLookoutDate } from "../../utils";
import { getChannelConfig } from "../../config";
import DataUpdate from "./DataUpdate";
import ReportType from "./ReportType";
import { reports, lookout } from "../../images";
import Lookout from "./Lookout";

const Home = props => {
  const {
    date,
    userId,
    getPreferenceList,
    preferences,
    getLookoutData,
    lookoutData,
    isLookoutData,
    client
  } = props;
  const [tab, setTab] = useState(0);
  const [channel, setChannel] = useState("ih");
  const channelData = getChannelConfig(channel);
  const isPreference = preferences[channel] && preferences[channel].length > 0;
  useEffect(() => {
    if (!isPreference) {
      getPreferenceList(userId);
    }
    if (!isLookoutData) {
      getLookoutData(userId, date, client);
    }
  }, []);
  useEffect(() => {
    if (!isLookoutData) {
      getLookoutData(userId, date, client);
    }
  }, [date]);
  const getGreeting = () => {
    const hour = getHours(new Date());
    if (hour >= 2 && hour < 12) {
      return "Good Morning";
    }
    if (hour >= 12 && hour < 18) {
      return "Good Afternoon";
    }
    return "Good Evening";
  };
  return (
    <Card sx={{ p: 2.5, width: 1 }}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item md={5}>
          <CardHeader
            titleTypographyProps={{ variant: "h2", gutterBottom: true }}
            title={getGreeting()}
            subheader={
              <Fragment>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="primary.dark"
                >
                  We are here to simplify the understanding of business
                  performance.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Interrodata intelligently analyses standard industry datasets
                  and generates meaningful stories about what happened, and why.
                </Typography>
              </Fragment>
            }
          />
        </Grid>
        <Grid item md={4} sx={{ p: 2 }}>
          <DataUpdate date={date} />
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={3} container direction="column">
            <Paper sx={{ p: 2 }}>
              <CardHeader
                title={
                  <Link to="/reports-reviews/performance">
                    <img
                      alt="reports"
                      src={reports}
                      style={{
                        transform: "scale(0.9)",
                        transformOrigin: "left"
                      }}
                    />
                  </Link>
                }
              />
              <ReportType
                type="performance"
                icon={<PerformanceIcon sx={{ fontSize: 15 }} color="yellow" />}
                title="Performance Reports"
                intro="Understand how a category or brand is performing over time, in a specific market, channel or retailer."
                buttons={[
                  {
                    text: "New In-Home Performance Report (Including Impulse)",
                    link: "/reports-reviews/new-ih-performance"
                  },
                  {
                    text: "New Out-of-Home Performance Report (Excluding Impulse)",
                    link: "/reports-reviews/new-ooh-performance"
                  }
                ]}
                buttonColor="yellow"
              />
              <Divider />
              <ReportType
                type="brandbattles"
                icon={<BrandBattlesIcon sx={{ fontSize: 15 }} color="orange" />}
                title="Brand Battles Report"
                intro="Compare the performance of two products within a specific market or retailer."
                buttons={[
                  {
                    text: "New Brand Battles Report",
                    link: "/reports-reviews/new-brandbattles"
                  }
                ]}
                buttonColor="orange"
              />
              <Divider />
              <ReportType
                type="rangereview"
                icon={<RangeReviewIcon sx={{ fontSize: 15 }} color="primary" />}
                title="Range Review Impact Assessment"
                intro="Explore the context and the impact of the range review, as well as the key drivers of performance change."
                buttons={[
                  {
                    text: "New Post Range Review",
                    link: "/reports-reviews/new-rangereview"
                  }
                ]}
                buttonColor="primary"
              />
            </Paper>
          </Grid>
          <Grid item md={9} container direction="column">
            <Paper sx={{ p: 2 }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: 1,
                  mb: 1
                }}
              >
                <Tabs
                  value={tab}
                  onChange={(e, val) => {
                    setTab(val);
                    setChannel(e.target.name);
                  }}
                >
                  <Tab
                    label="In Home"
                    name="ih"
                    icon={<IHIcon fontSize="small" />}
                    iconPosition="start"
                  />
                  <Tab
                    label="Out of Home"
                    name="ooh"
                    icon={<OOHIcon fontSize="small" />}
                    iconPosition="start"
                  />
                </Tabs>
              </Box>
              <CardHeader
                title={
                  <Fragment>
                    <Link to="/lookout:0">
                      <img
                        alt="lookout"
                        src={lookout}
                        style={{
                          transform: "scale(0.9)",
                          transformOrigin: "left"
                        }}
                      />
                    </Link>
                    <Typography
                      variant="subtitle2"
                      sx={{ float: "right" }}
                      fontSize={12}
                      gutterBottom
                    >
                      Insights based on {channel === "ih" ? "L4W" : "L1M"} (
                      {getLookoutDate(
                        date[channelData.dataSet],
                        channelData.isMonth
                      )}
                      )
                    </Typography>
                  </Fragment>
                }
              />
              <Lookout
                lookoutData={lookoutData[channel]}
                date={date}
                preferences={preferences[channel]}
                isLookoutData={isLookoutData}
                channel={channel}
              />
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Home.propTypes = {
  date: PropTypes.shape(),
  userId: PropTypes.string,
  getPreferenceList: PropTypes.func,
  preferences: PropTypes.shape(),
  getLookoutData: PropTypes.func,
  lookoutData: PropTypes.shape(),
  isLookoutData: PropTypes.bool,
  client: PropTypes.string
};

Home.defaultProps = {
  date: {},
  userId: "",
  getPreferenceList: () => {},
  preferences: {},
  getLookoutData: () => {},
  lookoutData: {},
  isLookoutData: false,
  client: ""
};

const mapStateToProps = state => ({
  date: state.data.date,
  userId: state.user.user.id,
  preferences: state.lookout.preferences,
  lookoutData: state.lookout.lookout,
  isLookoutData: state.lookout.isLookout,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  getPreferenceList: userId => {
    dispatch(getPreferences(userId));
  },
  getLookoutData: (userId, dataDate, client) =>
    dispatch(getLookout(userId, dataDate, client))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Typography,
  ListItem,
  FormControl,
  FormLabel,
  FormHelperText,
  Collapse
} from "@mui/material";
import {
  enterSelectedTerm,
  deleteTerm,
  filterStaticData,
  removeFilterTerm
} from "../../actions";
import { customFilter, customStyles } from "../../utils";

const getOptionsFromList = (optionList, subsection) =>
  Object.assign(
    optionList.map(i => ({ value: `${subsection}--${i}`, label: i }))
  );
const getSelectedValue = selected =>
  selected
    ? {
        value: `${selected.subsection}--${selected.name}`,
        label: selected.name
      }
    : "no-value";

const ProductRetailer = props => {
  const {
    categoryList,
    retailerList,
    sectorList,
    isStaticData,
    onSelect,
    selectedCategory,
    selectedSector,
    selectedRetailer,
    selectedRefRetailer,
    filterTerms,
    retailerError,
    isFilteringData,
    story,
    client
  } = props;
  const [refRetailerError, setRefRetailerError] = useState(null);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            The Product and Retailers
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" sx={{ mx: 1 }}>
          Category
        </Typography>
        <ListItem sx={{ px: 1, pt: 0, mb: 2 }}>
          <Select
            styles={customStyles}
            isDisabled={isFilteringData}
            className="smallSelect"
            placeholder="Select a category"
            options={[
              { value: "no-value", label: "All Categories" },
              ...getOptionsFromList(categoryList, "category")
            ]}
            defaultValue={{ label: "All Categories", value: "no-value" }}
            value={
              getSelectedValue(selectedCategory) === "no-value"
                ? { label: "All Categories", value: "no-value" }
                : getSelectedValue(selectedCategory)
            }
            onChange={(e, i) => {
              if (i.action === "clear") {
                // remove from search terms
                onSelect(
                  "no-value",
                  getSelectedValue(selectedCategory),
                  story,
                  filterTerms,
                  client
                );
              } else {
                onSelect(
                  e.value,
                  getSelectedValue(selectedCategory),
                  story,
                  filterTerms,
                  client
                );
                // if category changes, remove sector from search terms
                if (selectedSector) {
                  onSelect(
                    "no-value",
                    getSelectedValue(selectedSector),
                    story,
                    client
                  );
                }
              }
            }}
            isClearable
            filterOption={customFilter}
          />
        </ListItem>
        <Collapse in={getSelectedValue(selectedCategory) !== "no-value"}>
          <Typography variant="subtitle2" sx={{ mx: 1 }}>
            Sector
          </Typography>
          <ListItem sx={{ px: 1, pt: 0, mb: 2 }}>
            <Select
              styles={customStyles}
              isDisabled={!isStaticData || isFilteringData}
              className="smallSelect"
              options={[
                {
                  value: "no-value",
                  label: "All sectors within the selected category"
                },
                ...getOptionsFromList(sectorList, "sector")
              ]}
              placeholder="Select a sector"
              defaultValue={{
                label: "All sectors within the selected category",
                value: "no-value"
              }}
              value={
                getSelectedValue(selectedSector) === "no-value"
                  ? {
                      label: "All sectors within the selected category",
                      value: "no-value"
                    }
                  : getSelectedValue(selectedSector)
              }
              onChange={e => {
                onSelect(
                  e.value,
                  getSelectedValue(selectedSector),
                  story,
                  client
                );
              }}
              backspaceRemovesValue={false}
              filterOption={customFilter}
            />
          </ListItem>
        </Collapse>
        <Typography variant="subtitle2" sx={{ mx: 1 }}>
          Retailer <span className="required">required</span>
        </Typography>
        <ListItem sx={{ px: 1, pt: 0 }}>
          <Select
            styles={customStyles}
            className="smallSelect"
            options={getOptionsFromList(retailerList, "retailer").sort(
              (a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
              }
            )}
            placeholder="Select a retailer"
            value={getSelectedValue(selectedRetailer)}
            onChange={e => {
              if (
                selectedRefRetailer &&
                e.value.split("--")[1] === selectedRefRetailer.name
              ) {
                setRefRetailerError(
                  "Reference retailer cannot be the same as retailer"
                );
              } else {
                onSelect(
                  e.value,
                  getSelectedValue(selectedRetailer),
                  story,
                  client
                );
              }
            }}
            backspaceRemovesValue={false}
            filterOption={customFilter}
          />
          {!selectedRetailer && (
            <FormHelperText error>{retailerError}</FormHelperText>
          )}
        </ListItem>
        {/* <Typography variant="subtitle2" sx={{ mx: 1 }}>
          Reference Retailer
        </Typography> */}
        {/* <ListItem sx={{ px: 1, pt: 0 }}>
          <Select
            className="smallSelect"
            options={getOptionsFromList(
              selectedRetailer
                ? retailerList.filter(i => i !== selectedRetailer.name)
                : retailerList,
              "ref_market"
            )}
            placeholder="Select a reference retailer"
            value={getSelectedValue(selectedRefRetailer)}
            onChange={(e, i) => {
              if (i.action === "clear") {
                // remove from search terms
                onSelect("no-value", getSelectedValue(selectedRefRetailer), story, client);
              } else {
                onSelect(e.value, getSelectedValue(selectedRefRetailer), story, client);
              }
            }}
            isClearable
            filterOption={customFilter}
          />
        </ListItem> */}
        {!selectedRefRetailer && (
          <FormHelperText error>{refRetailerError}</FormHelperText>
        )}
      </FormControl>
    </ListItem>
  );
};

ProductRetailer.propTypes = {
  categoryList: PropTypes.arrayOf(PropTypes.string),
  sectorList: PropTypes.arrayOf(PropTypes.string),
  retailerList: PropTypes.arrayOf(PropTypes.string),
  selectedCategory: PropTypes.objectOf(PropTypes.string),
  selectedSector: PropTypes.objectOf(PropTypes.string),
  selectedRetailer: PropTypes.objectOf(PropTypes.string),
  selectedRefRetailer: PropTypes.objectOf(PropTypes.string),
  isStaticData: PropTypes.bool,
  onSelect: PropTypes.func,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  retailerError: PropTypes.string,
  isFilteringData: PropTypes.bool,
  story: PropTypes.string,
  client: PropTypes.string
};

ProductRetailer.defaultProps = {
  categoryList: [],
  sectorList: [],
  retailerList: [],
  selectedCategory: undefined,
  selectedSector: undefined,
  selectedRetailer: undefined,
  selectedRefRetailer: undefined,
  isStaticData: false,
  onSelect: () => {},
  filterTerms: [],
  retailerError: "",
  isFilteringData: false,
  story: "",
  client: ""
};

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story, filterTerms, client) => {
    const value = selectedValue.split("--");
    if (oldValue !== "no-value") {
      const old = oldValue.value.split("--");
      dispatch(
        deleteTerm(
          {
            name: old[1],
            subsection: old[0],
            story
          },
          story
        )
      );
      if (old[0] === "category") {
        dispatch(
          removeFilterTerm(
            {
              name: old[1],
              subsection: old[0],
              table: "what",
              story
            },
            filterTerms,
            story,
            client
          )
        );
      }
    }
    if (selectedValue !== "no-value") {
      dispatch(
        enterSelectedTerm(
          {
            name: value[1],
            subsection: value[0],
            story
          },
          story
        )
      );
      if (value[0] === "category") {
        dispatch(
          filterStaticData(
            {
              name: value[1],
              subsection: value[0],
              table: "what",
              story
            },
            filterTerms,
            story,
            client
          )
        );
      }
    }
  }
});

export default connect(null, mapDispatchToProps)(ProductRetailer);

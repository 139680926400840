import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Stack, CardHeader, Avatar, Button, ButtonGroup } from "@mui/material";
import {
  KeyboardArrowUpRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowRightRounded
} from "@mui/icons-material";

const RankList = props => {
  const { data, setNext, value, rank, setRank } = props;
  return _.orderBy(data, ["rank"], ["desc"]).map(i => {
    const isSelected = i.name === value;
    return (
      <Stack
        key={i.name}
        direction="row"
        alignItems="center"
        spacing={0.5}
        sx={{ my: 1 }}
      >
        <ButtonGroup
          size="small"
          orientation="vertical"
          variant="text"
          color="disabled"
          sx={{
            height: 45,
            border: 1,
            color: "#e5e0df",
            borderColor: isSelected && "primary.main"
          }}
        >
          <Button
            onClick={() => {
              if (i.rank < 10) {
                setRank([..._.without(rank, i), { ...i, rank: i.rank + 1 }]);
              }
            }}
            disabled={i.rank === 10}
          >
            <KeyboardArrowUpRounded fontSize="inherit" />
          </Button>
          <Button
            onClick={() => {
              if (i.rank > -10) {
                setRank([..._.without(rank, i), { ...i, rank: i.rank - 1 }]);
              }
            }}
            disabled={i.rank === -10}
          >
            <KeyboardArrowDownRounded fontSize="inherit" />
          </Button>
        </ButtonGroup>
        <CardHeader
          sx={{
            width: 1,
            height: 45,
            p: 1,
            cursor: "pointer",
            bgcolor: isSelected ? "primary.main" : "#f7f3f2",
            borderRadius: 1,
            ".MuiCardHeader-action": { alignSelf: "center" }
          }}
          title={i.name}
          titleTypographyProps={{
            variant: "body2",
            color: isSelected && "white"
          }}
          action={
            <Stack direction="row" alignItems="center">
              <Avatar
                sx={{
                  mx: 1,
                  width: 24,
                  height: 24,
                  bgcolor: "white",
                  border: 1,
                  color:
                    (i.rank > 0 && "positive.main") ||
                    (i.rank < 0 && "negative.main") ||
                    "neutral.main",
                  fontSize: 12
                }}
              >
                {i.rank}
              </Avatar>
              <KeyboardArrowRightRounded
                sx={{ mr: 1, color: isSelected ? "white" : "disabled.main" }}
              />
            </Stack>
          }
          onClick={() => setNext(i.name)}
        />
      </Stack>
    );
  });
};

RankList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  setNext: PropTypes.func,
  value: PropTypes.string,
  rank: PropTypes.arrayOf(PropTypes.shape()),
  setRank: PropTypes.func
};

RankList.defaultProps = {
  data: [],
  setNext: () => {},
  value: "",
  rank: [],
  setRank: () => {}
};

export default RankList;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  Box,
  Grid
} from "@mui/material";
import { BrandBattlesIcon } from "../Icons";
import { fetchStaticData, clearStorySearchData } from "../../actions";
import { buildQueryString } from "../../utils";
import Products from "./Products";
import Context from "./Context";
import Constraints from "./Constraints";
import Review from "./Review";

function loadWidget() {
  window.HubSpotConversations.widget.load();
}

function refreshWidget() {
  window.HubSpotConversations.widget.refresh();
}

class NewBrandBattlesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      productAError: null,
      productBError: null,
      contextError: null,
      whereError: null,
      whenError: null,
      measureError: null
    };
    this.showProductErrors = this.showProductErrors.bind(this);
    this.showContextErrors = this.showContextErrors.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const { isStaticData, getData, client } = this.props;
    if (!isStaticData) {
      getData(client);
    }
    if (window.HubSpotConversations) {
      loadWidget();
    } else {
      window.hsConversationsOnReady = [loadWidget];
    }
  }

  componentWillUnmount() {
    if (window.HubSpotConversations) {
      refreshWidget();
    } else {
      window.hsConversationsOnReady = [refreshWidget];
    }
  }

  showProductErrors = () => {
    const { searchTerms } = this.props;
    if (!searchTerms.find(t => t.subsection.startsWith("product_a"))) {
      this.setState({ productAError: "Please pick a primary product" });
    } else {
      this.setState({ productAError: null });
    }
    if (!searchTerms.find(t => t.subsection.startsWith("product_b"))) {
      this.setState({ productBError: "Please pick a comparison product" });
    } else {
      this.setState({ productBError: null });
    }
  };

  showContextErrors = () => {
    const { searchTerms } = this.props;
    if (
      searchTerms.filter(
        t => t.table === "what" && !t.subsection.startsWith("product_")
      ).length === 0
    ) {
      this.setState({ contextError: "Please select a context" });
    } else {
      this.setState({ contextError: null });
    }
    if (searchTerms.filter(t => t.table === "where").length === 0) {
      this.setState({ whereError: "Please select a retailer or channel" });
    } else {
      this.setState({ whereError: null });
    }
    if (searchTerms.filter(t => t.table === "when").length === 0) {
      this.setState({ whenError: "Please select a time period" });
    } else {
      this.setState({ whenError: null });
    }
    if (searchTerms.filter(t => t.table === "measure").length === 0) {
      this.setState({ measureError: "Please select a measure" });
    } else {
      this.setState({ measureError: null });
    }
  };

  clearErrors = () => {
    this.setState({
      productAError: null,
      productBError: null,
      contextError: null,
      whereError: null,
      whenError: null,
      measureError: null
    });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleReset = () => {
    const { reset, story } = this.props;
    reset(story);
  };

  render() {
    const {
      subsections,
      filteredSubsections,
      searchTerms,
      allTerms,
      isFilteredData,
      filterTerms,
      isFilteringData,
      story,
      isStaticData,
      searchEngine,
      filteredSearchEngine,
      client,
      placeholder
    } = this.props;
    const {
      activeStep,
      productAError,
      productBError,
      contextError,
      whereError,
      whenError,
      measureError
    } = this.state;
    const steps = [
      "Select Products",
      "Define Context & Metrics",
      "Review & Report"
    ];
    const isContext =
      searchTerms.filter(
        i => i.table === "what" && !i.subsection.startsWith("product_")
      ).length !== 0;
    return (
      <Card className={!isStaticData || isFilteringData ? "wait" : "overflow"}>
        <CardHeader
          avatar={<BrandBattlesIcon fontSize="small" color="secondaryDark" />}
          titleTypographyProps={{ variant: "h5" }}
          title="Brand Battles Report"
        />
        <Typography variant="body2" sx={{ px: 2 }}>
          Run a report to compare the performance of two products within a
          specific market or retailer. This report will allow you to identify
          what may have enabled one product to win in the chosen time frame and
          provide you with key insights into suggested actions.
        </Typography>
        <Stepper alternativeLabel activeStep={activeStep} sx={{ py: 4 }}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {(activeStep === 0 && (
          <Fragment>
            <Products
              subsections={subsections}
              filteredSubsections={filteredSubsections}
              filterTerms={filterTerms}
              story={story}
              isStaticData={isStaticData}
              productA={searchTerms.find(s =>
                s.subsection.startsWith("product_a")
              )}
              productB={searchTerms.find(s =>
                s.subsection.startsWith("product_b")
              )}
              searchEngine={searchEngine}
              filteredSearchEngine={filteredSearchEngine}
              isFilteredData={isFilteredData}
              productAError={productAError}
              productBError={productBError}
              clearErrors={this.clearErrors}
              client={client}
            />
            <Box textAlign="right" sx={{ p: 2 }}>
              {!!searchTerms.find(s => s.subsection.startsWith("product_a")) &&
              !!searchTerms.find(s => s.subsection.startsWith("product_b")) ? (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={this.handleNext}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={this.showProductErrors}
                  color="disabled"
                >
                  Next
                </Button>
              )}
            </Box>
          </Fragment>
        )) ||
          (activeStep === 1 && (
            <Fragment>
              <Context
                filteredSubsections={filteredSubsections}
                searchTerms={searchTerms}
                allTerms={allTerms}
                filterTerms={filterTerms}
                story={story}
                searchEngine={filteredSearchEngine}
                isFilteredData={isFilteredData}
                contextError={contextError}
                clearErrors={this.clearErrors}
                client={client}
                placeholder={placeholder}
              />
              <Constraints
                subsections={subsections}
                searchTerms={searchTerms}
                story={story}
                whereError={whereError}
                whenError={whenError}
                measureError={measureError}
                clearErrors={this.clearErrors}
              />
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item md={6}>
                  <Box textAlign="left" sx={{ p: 2 }}>
                    {isContext ? (
                      <Tooltip title="Resetting will allow you to change the products selected on the previous page">
                        <Button
                          onClick={() => {
                            this.handleReset();
                            this.handleBack();
                          }}
                        >
                          Reset
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button onClick={this.handleBack}>Back</Button>
                    )}
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box textAlign="right" sx={{ p: 2 }}>
                    {allTerms ? (
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={this.handleNext}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={this.showContextErrors}
                        color="disabled"
                      >
                        Next
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Fragment>
          )) ||
          (activeStep === 2 && (
            <Fragment>
              <Review searchTerms={searchTerms} />
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item md={6}>
                  <Box textAlign="left" sx={{ p: 2 }}>
                    <Button onClick={this.handleBack}>Back</Button>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box textAlign="right" sx={{ p: 2 }}>
                    <Link
                      to={`/reports?story=${story.toUpperCase()}&${buildQueryString(
                        searchTerms
                      )}`}
                      onClick={this.handleReset}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                      >
                        Generate Report
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Fragment>
          ))}
      </Card>
    );
  }
}

NewBrandBattlesReport.propTypes = {
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  filteredSubsections: PropTypes.arrayOf(PropTypes.shape()),
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  allTerms: PropTypes.bool,
  getData: PropTypes.func,
  isStaticData: PropTypes.bool,
  isFilteredData: PropTypes.bool,
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  isFilteringData: PropTypes.bool,
  story: PropTypes.string,
  searchEngine: PropTypes.shape(),
  filteredSearchEngine: PropTypes.shape(),
  reset: PropTypes.func,
  client: PropTypes.string,
  placeholder: PropTypes.string
};

NewBrandBattlesReport.defaultProps = {
  subsections: [],
  filteredSubsections: [],
  searchTerms: [],
  allTerms: false,
  getData: () => {},
  isStaticData: false,
  isFilteredData: false,
  filterTerms: [],
  isFilteringData: false,
  story: "",
  searchEngine: {},
  filteredSearchEngine: {},
  reset: () => {},
  client: "",
  placeholder: ""
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      searchTerms = { story: [] },
      allTerms = { story: false },
      filteredSubsections = { story: [] },
      filteredSearchEngine = { story: {} },
      isFilteredData = { story: false },
      filterTerms = { story: [] },
      isFilteringData = false
    },
    data: {
      subsections = { dataSet: [] },
      isStaticData = false,
      searchEngine = { dataSet: {} },
      placeholder = { dataSet: "" }
    }
  } = state;
  return {
    searchTerms: searchTerms[story],
    subsections: subsections[dataSet],
    allTerms: allTerms[story],
    isStaticData,
    filteredSubsections: filteredSubsections[story],
    isFilteredData: isFilteredData[story],
    filterTerms: filterTerms[story],
    isFilteringData,
    searchEngine: searchEngine[dataSet],
    filteredSearchEngine: filteredSearchEngine[story],
    client: state.user.user.client,
    placeholder: placeholder[dataSet]
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => {
    dispatch(fetchStaticData(client));
  },
  reset: story => {
    dispatch(clearStorySearchData(story));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBrandBattlesReport);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  Typography,
  Collapse,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CardContent,
  Stack,
  Tabs,
  Tab
} from "@mui/material";
import { ArrowBack, AddCircleOutline } from "@mui/icons-material";
import { EditIcon, DeleteIcon, IHIcon, OOHIcon } from "../Icons";
import AddPreference from "./AddPreference";
import {
  enterSelectedTerm,
  getContext,
  clearStorySearchData,
  getPreferences,
  removePreference,
  clearLookoutMessages
} from "../../actions";
import { getChannelConfig } from "../../config";
import { NUMBER_PREFERENCES } from "../../constants";

const styles = {
  preference: {
    bgcolor: "#f7f3f2",
    px: 2,
    py: 1.5,
    border: 1,
    borderColor: "#e5e0df",
    borderRadius: 1
  }
};

const LookoutPreferences = props => {
  const {
    totalPreferences,
    client,
    addSelectedToSearchTerms,
    remove,
    userId,
    getPreferenceList,
    error,
    reset,
    clearErrors
  } = props;
  const { channelValue } = useParams();
  const history = useHistory();
  const channelData = getChannelConfig(channelValue);
  const [channel, setChannel] = useState(channelValue);
  const [dataSet, setDataSet] = useState(channelData.dataSet);
  const [story, setStory] = useState(`lookout-${channelValue}`);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({});
  const preferences = totalPreferences[channel] || [];
  const isPreference = preferences.length !== 0;
  useEffect(() => {
    if (!isPreference) {
      getPreferenceList(userId);
    }
  }, []);
  return (
    <Card sx={{ p: 2.5, width: 1 }} className="overflow">
      <CardHeader
        titleTypographyProps={{
          variant: "h2"
        }}
        avatar={
          <IconButton
            component={Link}
            color="secondary"
            to={
              isEditOpen && preferences.length > 0
                ? `/lookout-preferences:${channel}`
                : "/lookout:0"
            }
            onClick={() => {
              if (isEditOpen && preferences.length > 0) {
                setIsEditOpen(false);
              }
              reset(story);
              clearErrors();
            }}
          >
            <ArrowBack />
          </IconButton>
        }
        title="Lookout Preferences"
      />
      <Typography variant="body2" sx={{ px: 7, mx: 2, mt: -2 }}>
        Here are your Lookout Preferences! Here you can add up to 3 preferences
        from each channel to be informed of the top insights as soon as new data
        is released.
      </Typography>
      <Collapse in={!isEditOpen} sx={{ px: 9, mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", width: 1 }}>
          <Tabs
            value={channelData.tab}
            onChange={e => {
              const val = e.target.name;
              setChannel(val);
              setDataSet(getChannelConfig(val).dataSet);
              setStory(`lookout-${val}`);
              history.push(`/lookout-preferences:${val}`);
            }}
          >
            <Tab
              label="In Home"
              name="ih"
              icon={<IHIcon fontSize="small" />}
              iconPosition="start"
            />
            <Tab
              label="Out of Home"
              name="ooh"
              icon={<OOHIcon fontSize="small" />}
              iconPosition="start"
            />
          </Tabs>
        </Box>
      </Collapse>
      <CardContent sx={{ p: 7, mx: 2, mt: -4 }}>
        {isEditOpen ? (
          <AddPreference
            onClose={() => {
              setIsEditOpen(error.length > 0);
              reset(story);
            }}
            preference={selectedPreference}
            userId={userId}
            client={client}
            error={error}
            story={story}
            dataSet={dataSet}
            channel={channel}
          />
        ) : (
          <Stack alignItems="stretch" spacing={3}>
            {preferences.map((i, k) => (
              <Grid
                key={k.toString()}
                container
                alignItems="center"
                sx={styles.preference}
              >
                <Grid item md={11}>
                  <Typography variant="subtitle2" fontSize={16}>
                    {i.product &&
                      (i.product.name.value
                        ? i.product.name.value
                        : i.product.name)}
                    &nbsp;|&nbsp;
                    {i.context
                      .map(c => (c.name.value ? c.name.value : c.name))
                      .join(", ")}
                    &nbsp;|&nbsp;{i.market && i.market.label}
                  </Typography>
                </Grid>
                <Grid item md={1} container justifyContent="flex-end">
                  <IconButton
                    size="small"
                    color="yellow"
                    onClick={() => {
                      setIsEditOpen(true);
                      setSelectedPreference(i);
                      addSelectedToSearchTerms(i, story, client);
                      clearErrors();
                    }}
                  >
                    <EditIcon sx={{ fontSize: 16, m: 0.5 }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="orange"
                    onClick={() => {
                      setIsDeleteOpen(true);
                      setSelectedPreference(i);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 16, m: 0.5 }} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Collapse in={preferences.length < NUMBER_PREFERENCES}>
              <Button
                startIcon={<AddCircleOutline />}
                onClick={() => {
                  setIsEditOpen(true);
                  setSelectedPreference({});
                  clearErrors();
                }}
              >
                Add more preferences
              </Button>
            </Collapse>
          </Stack>
        )}
        <Dialog open={isDeleteOpen}>
          <Box textAlign="center" sx={{ m: 1, pb: 1 }}>
            <DialogTitle>
              <DeleteIcon sx={{ fontSize: 16 }} color="orange" />
              <Typography variant="h6" fontSize={14} gutterBottom paragraph>
                Removing Lookout™ Preference
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="span">
                {selectedPreference.product &&
                  (selectedPreference.product.name.value
                    ? selectedPreference.product.name.value
                    : selectedPreference.product.name)}
                &nbsp;|&nbsp;
                {selectedPreference.context &&
                  selectedPreference.context
                    .map(c => (c.name.value ? c.name.value : c.name))
                    .join(", ")}
                &nbsp;|&nbsp;
                {selectedPreference.market && selectedPreference.market.label}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body2" gutterBottom>
                Are you sure you want to remove this preference?
              </Typography>
              <Typography variant="h6" fontSize={14} gutterBottom>
                This action can not be undone.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mb: 1 }}>
              <Button
                variant="contained"
                disableElevation
                color="orange"
                onClick={() => setIsDeleteOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="orange"
                onClick={() => {
                  remove(selectedPreference, userId, story, client, channel);
                  setIsDeleteOpen(false);
                  setSelectedPreference({});
                }}
              >
                Remove preference
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </CardContent>
    </Card>
  );
};

LookoutPreferences.propTypes = {
  totalPreferences: PropTypes.shape(),
  client: PropTypes.string,
  addSelectedToSearchTerms: PropTypes.func,
  remove: PropTypes.func,
  userId: PropTypes.string,
  getPreferenceList: PropTypes.func,
  error: PropTypes.string,
  reset: PropTypes.func,
  clearErrors: PropTypes.func
};

LookoutPreferences.defaultProps = {
  totalPreferences: {},
  client: "",
  addSelectedToSearchTerms: () => {},
  remove: () => {},
  userId: "",
  getPreferenceList: () => {},
  error: "",
  reset: () => {},
  clearErrors: () => {}
};

const mapStateToProps = state => ({
  totalPreferences: state.lookout.preferences,
  error: state.lookout.error,
  userId: state.user.user.id,
  client: state.user.user.client
});

const mapDispatchToProps = dispatch => ({
  addSelectedToSearchTerms: (selected, story, client) => {
    if (selected.product) {
      dispatch(enterSelectedTerm(selected.product, story));
      // set context
      const context = selected.context || [];
      context.forEach(i => dispatch(enterSelectedTerm(i, story)));
      dispatch(
        getContext([selected.product], context, "product", story, client)
      );
    }
    if (selected.market) {
      dispatch(
        enterSelectedTerm(
          {
            name: selected.market,
            subsection: "retailer",
            table: "where",
            story
          },
          story
        )
      );
    }
  },
  remove: (preference, userId, story, client, channel) => {
    dispatch(removePreference(preference, userId, client, channel));
    dispatch(clearStorySearchData(story));
  },
  getPreferenceList: userId => {
    dispatch(getPreferences(userId));
  },
  reset: story => {
    dispatch(clearStorySearchData(story));
  },
  clearErrors: () => {
    dispatch(clearLookoutMessages());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LookoutPreferences);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton, Typography, Stack } from "@mui/material";
import { goToSlide } from "../../actions";
import { NextIcon } from "../Icons";

const PresentationNavRight = props => {
  const { activeSlideIndex, toSlide, report } = props;
  const slideCount = report.length;
  const isLastSlide = activeSlideIndex + 1 >= slideCount;
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      className="PresentationNavRight"
    >
      <IconButton
        id="presentation-next"
        sx={{ "&:hover": { color: "primary.dark" } }}
        onClick={() =>
          !isLastSlide && toSlide(activeSlideIndex + 1, report, slideCount)
        }
        disabled={
          isLastSlide ||
          (activeSlideIndex + 2 === slideCount &&
            report[activeSlideIndex + 1].hidden)
        }
        size="large"
      >
        <NextIcon />
      </IconButton>
      <Typography variant="caption" color="text.secondary">
        Next Page
      </Typography>
    </Stack>
  );
};

PresentationNavRight.propTypes = {
  activeSlideIndex: PropTypes.number,
  toSlide: PropTypes.func,
  report: PropTypes.arrayOf(PropTypes.shape())
};

PresentationNavRight.defaultProps = {
  activeSlideIndex: 0,
  toSlide: () => {},
  report: []
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toSlide: (slideIndex, report, slideCount) => {
    const { reportId, userId, story, client } = ownProps;
    dispatch(
      goToSlide(
        slideIndex,
        report,
        "next",
        slideCount,
        reportId,
        userId,
        story,
        client
      )
    );
  }
});

export { PresentationNavRight as PresentationNavRightComponent };
export default connect(null, mapDispatchToProps)(PresentationNavRight);
